import React from 'react';
import SectionTitle from '../common/SectionTitle';

const FetaureImgTwo = ({data}) => {
  return (
    <>
      <section className='feature-section two-bg-dark-light ptb-120'>
        <div className='container'>
          <div className='row align-items-center justify-content-between'>
            <div className='col-lg-6 col-md-6'>
              <div
                className='image-wrap mb-5 mb-md-0 mb-lg-0 mb-xl-0'
                data-aos='fade-right'
              >
                <img
                  src={`assets/img/${data.image}`}
                  alt='feature img'
                  className='img-fluid shadow rounded-custom'
                />
              </div>
            </div>
            <div className='col-lg-5 col-md-6'>
              <div
                className='feature-content-wrap'
                data-aos='fade-up'
                data-aos-delay='50'
              >
                <SectionTitle
                  subtitle={data.sectionTitle}
                  title={data.sectionSubTitle}
                  description={data.sectionDescription}
                  leftAlign
                />
                <ul className='list-unstyled mt-5'>
                  <li className='d-flex align-items-start mb-4'>
                    <div className='icon-box bg-primary rounded me-4'>
                      <i className={data.iconOne}></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'>{data.headerOne}</h3>
                      <p>
                      {data.headerOneParagraph}
                      </p>
                    </div>
                  </li>
                  <li className='d-flex align-items-start mb-4'>
                    <div className='icon-box bg-danger rounded me-4'>
                      <i className={data.iconTwo}></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'>{data.headerTwo}</h3>
                      <p>
                      {data.headerTwoParagraph}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FetaureImgTwo;
