import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import PageMeta from '../../components/common/PageMeta';

import RequestForDemo from '../../components/others/RequestForDemo';
import PromoThree from '../../components/promo/PromoThree';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import FeatureImgContentTwo from '../../components/feature-img-content/FeatureImgContentTwo';
import UseCaseFeatureImgEight from './UseCaseFeatureImgEight';
import UseCaseFeatureImgContentThree from './UseCaseFeatureImgContentThree';




let FeatureImgContentTwoData = {
  sectionTitle:"Supporting the Full Speech Lifecycle",
  sectionDescription: "AI revolutionizes the process of converting spoken words to text, enabling organizations to unlock valuable insights and navigate vast audio sources with unprecedented efficiency and accuracy.",
  iconMain: "fal fa-file-text  fa-2x text-white",
  useViedo: false,
  image: "BorrowedImages/VoiceToText.png",
  
  items: [
    {
      icon:"fal fa fa-building fa-2x  text-primary mb-4",
      bullet: "Customer Service Records",
      bulletParagraph: "By converting customer calls and inquiries into text format, Certo's Voice to Text feature helps financial institutions maintain detailed records of client interactions, supporting compliance requirements and enhancing customer service quality through better documentation and analysis."
    },
    {
      icon:"fal fa fa-code fa-2x  text-primary mb-4",
      bullet: "Meeting Transcription",
      bulletParagraph: "Voice to Text technology on Certo enables financial professionals to accurately transcribe important meetings, including discussions on investment strategies, regulatory compliance, and client interactions, facilitating comprehensive documentation and reference."
    },
    {
      icon:"fal fa fa-thermometer-quarter fa-2x  text-primary mb-4",
      bullet: "Compliance Documentation",
      bulletParagraph: "Utilizing Certo's Voice to Text functionality, compliance officers can efficiently document regulatory discussions and updates, ensuring thorough records of compliance efforts and facilitating audits and regulatory reporting."
    }
  ]
}


let data = {
    header: "TEST HEADER",
    image: "emerging-llm-stack.webp",
    headerVerbose: "Conveniently drive stand-alone total linkage for process-centric content. Enthusiastically administrate robust initiatives quickly unleash collaborative with client-focused.",
    iconLeft: "fal fa-comments-alt fa-2x text-primary mb-4",
    iconRight: "fal fa-headset fa-2x text-primary mb-4",
    leftSubHeader:"Left Sub-Header",
    leftSubText:"luyjw5eaery Objectively exceptional via customized intellectual.",
    rightSubHeader:"Right Sub-Header",
    rightSubText:"luyjw5eaery Objectively exceptional via customized intellectual."
}


let dataEight3 = {
  header: "Voice to Text",
  tagLine:"Voice to Text",
  image1: "video/voice2text demo video.mp4",
  image: "video/voice2text demo video.mp4",
  image2: "video/voice2text demo video.mp4",
  headerText:  "Drive interpretable, real-time analysis of loan-level characteristics.  Deployed at scale on billions of underlying loan data points.",
  iconLeft: "fal fa-user fa-2x text-primary",
  iconRight: "fal fa-watch fa-2x text-primary",
  leftSubHeader:"What-if",
  leftSubText:"luyjw5eaery Objectively exceptional via customized intellectual.",
  rightSubHeader:"Real time updates",
  rightSubText:"Real-time transcription",

  majorIngredients: "Generative, NER, NLP",
  minorIngredients: "Causal Analysis, Conformal Prediction",
  linkText: "AI's Transformative Role in Voice Recognition",
  linkDescription: "Voice-to-text technology powered by AI has revolutionized communication and accessibility, enabling seamless conversion of spoken words into written text. Through sophisticated algorithms, AI systems can accurately transcribe diverse accents, languages, and speech patterns in real-time."
}




let PromoThreeData = {
  title: "This is the Title",
  description: "This is the Description",
  amountOne: "150",
  headerOne:"Completed Deployments",
  textOne: "This is a great accomplishment that needs to be fully appreciated",
  amountTwo: "2k",
  headerTwo:"Happy Clients",
  textTwo: "Energistically impact synergistic convergence for leadership..",
  amountThree: "90%",
  headerThree:"Models Running",
  textThree: "This is a great accomplishment that needs to be fully appreciated"
}


const VoiceToTextUseCase = () => {
  return (
    <Layout>
      <PageMeta title="Voice To Text Use Case" />
      <Navbar navDark />
      <PageHeader
        title="Voice To Text"
        desc="Leveraging AI for Voice-to-Text involves the application of sophisticated speech recognition algorithms to convert spoken language into written text. Through the use of machine learning and deep neural networks, these systems can accurately transcribe audio content, enabling the extraction of meaningful information from spoken words. This technology streamlines the process of converting verbal communication, such as meetings, interviews, or customer interactions, into text format, offering enhanced accessibility, documentation, and analysis capabilities."
      />

      <UseCaseFeatureImgEight data={dataEight3} paddingTop={"paddingTop"} />
      <FeatureImgContentTwo data= {FeatureImgContentTwoData}/>
      

      <RequestForDemo />
      <FooterOne footerLight />
    </Layout>
  );
};

export default VoiceToTextUseCase;
