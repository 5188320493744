import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import PageMeta from '../../components/common/PageMeta';

import RequestForDemo from '../../components/others/RequestForDemo';
import PromoThree from '../../components/promo/PromoThree';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import FeatureImgEight from '../../components/features/FeatureImgEight';
import FeatureImgContentThree from '../../components/feature-img-content/FeatureImgContentThree';
import FeatureImgContentTwo from '../../components/feature-img-content/FeatureImgContentTwo';


let PromoThreeData = {
  title: "This is the Title",
  description: "This is the Description",
  amountOne: "150",
  headerOne:"Completed Deployments",
  textOne: "This is a great accomplishment that needs to be fully appreciated",
  amountTwo: "2k",
  headerTwo:"Happy Clients",
  textTwo: "Energistically impact synergistic convergence for leadership..",
  amountThree: "90%",
  headerThree:"Models Running",
  textThree: "This is a great accomplishment that needs to be fully appreciated"
}

let FeatureImgContentThreeData = {
  header: "Inclusive Design Thinking",
  headerParagraph: "Innovating successfully with Human-Centric AI involves a holistic approach that places human needs, values, and experiences at the core of AI development, fostering ethical, inclusive, and user-friendly solutions.",
  iconOne:"fal fa-comments-alt fa-2x text-primary mb-4",
  bulletOne:"Human-First UX Design",
  bulletOneParagraph:"    Prioritize user experience (UX) design principles, ensuring that AI applications are intuitive, user-friendly, and aligned with human cognitive patterns. Strive for a seamless integration of AI technologies into existing workflows, minimizing disruptions and maximizing user acceptance.",
  iconTwo: "fal fa-headset fa-2x text-primary mb-4",
  bulletTwo: "Education and Empowerment",
  bulletTwoParagraph: "We invest in user education to enhance awareness and understanding of AI capabilities and limitations. Certo empowers users to leverage AI tools effectively, fostering a sense of control and confidence in integrating AI into their daily lives or workflows",
  
image: "BorrowedImages/DesignMetaphors.png"
  //image: "ai-generated/RAG 1 768 363.png"
  
}

let FeatureImgContentTwoData = {
  sectionTitle:"Success Through Experience",
  sectionDescription: "Solving complex AI/ML problems is difficult without in the trenches experience. Our team has years of real-world experience solving enterprise level problems using the Certo Infrastructure. With end-to-end workflows covering intelligent document processing, data engineering, data extraction, model training, testing and orchestration capabilities all the way to production API endpoint deployment, we provide teams with:",
  iconMain: "fal fa-shield-check fa-2x text-white",
  iconOne:"fad fa-bezier-curve fa-2x text-primary",
  bulletOne:"Real-time Feedback to other stuff",
  bulletOneParagraph:"An explainable and accurate way to flexibly combine large language models (LLMs), symbolic, and machine learning AI approaches that solve real-world problems with the highest degree of accuracy.",
  iconTwo: "fad fa-code fa-2x text-primary",
  bulletTwo: "Live Changes Stuff",
  bulletTwoParagraph: "Interactively integrate extensible directed communities.",
  image: "dashboard-img-4.png",
  items: [
    {
      icon:"fal fa fa-building fa-2x  text-primary mb-4",
      bullet: "Purpose Built",
      bulletParagraph: "Meticulously designed and tailored to address the specific needs of your domain."
    },
    {
      icon:"fal fa fa-code fa-2x  text-primary mb-4",
      bullet: "Optimized Performance",
      bulletParagraph: "Integrating high-performance computing, efficient data storage, and streamlined workflows, ensuring peak performance and scalability for your AI and machine learning tasks."
    },
    {
      icon:"fal fa fa-thermometer-quarter fa-2x  text-primary mb-4",
      bullet: "Measurable Results",
      bulletParagraph: "Through improved accuracy, faster processing times, enhanced scalability, and ultimately, the ability to derive actionable insights that contribute to business success."
    }
  ]
}


let FeatureImgEightData = {
  sectionTitle:"Successfully Innovating with Human-Centric AI",
  sectionDescription: "Solving complex AI/ML problems begins with a deep understanding of user needs and pain points, using this insight as the foundation for Human-centric AI innovation.  Certo fosters collaboration between AI experts and end-users to co-create solutions that genuinely address real-world challenges.",
  iconOne:"fal fa-user fa-2x text-primary",
  bulletOne:"Augmenting Human Intelligence",
  bulletOneParagraph:"    Focus on AI applications that enhance human capabilities rather than replace them, fostering a collaborative relationship between humans and technology. Develop AI solutions that provide valuable insights, support decision-making, and automate routine tasks, allowing humans to focus on higher-order thinking.",
  iconTwo: "fal fa-watch fa-2x text-primary",
  bulletTwo: "Scalable and Sustainable Solutions",
  bulletTwoParagraph: "    Develop AI solutions that are scalable and adaptable to accommodate evolving user needs and technological advancements. Consider the long-term sustainability of AI applications, including factors such as data privacy, security, and the environmental impact of AI infrastructure.",
  image: "BorrowedImages/HumanCentricAI2.png"
  // image: "BorrowedImages/Human-Centered AI (3).svg"
  // /image: "BorrowedImages/HumanCentricAI.png"
  // /image: "BorrowedImages/HCAI-Overview.png"
  // image: "ai-generated/RAG 1.png"
  
}


const Augmentation = () => {
  return (
    <Layout>
      <PageMeta title="MLOps" />
      <Navbar navDark />
      <PageHeader
        title="Human Centric AI"
        desc="Humans and AI complement each other's strengths, combining the efficiency and pattern recognition abilities of machines with the nuanced understanding and adaptability of human cognition.

        The Human-centric approach involves scenarios where AI algorithms perform tasks up to a certain point, and human expertise is integrated to validate, correct, or enhance the results. This iterative process not only refines AI models but also ensures a higher level of accuracy and ethical oversight, especially in complex or ambiguous situations."
      />
      <FeatureImgEight paddingTop data={FeatureImgEightData}/>
      <FeatureImgContentThree data= {FeatureImgContentThreeData}/>
      {/*<FeatureImgContentTwo data= {FeatureImgContentTwoData}/> 
      <PromoThree hasBg data={PromoThreeData}/>
    
      <RequestForDemo />  */}
      <FooterOne footerLight />
    </Layout>
  );
};

export default Augmentation ;
