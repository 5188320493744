import React from 'react';
import SectionTitle from '../common/SectionTitle';


const FeatureImgEight = ({ paddingTop, data }) => {
  return (
    <>
      <section
        className={`feature-section ${
          paddingTop ? 'ptb-120' : 'pt-60 pb-120'
        } `}
      >
        <div className='container'>
          <div className='row align-items-lg-center justify-content-between'>
            <div className='col-lg-6 order-lg-2 mb-7 mb-lg-0'>
              <div className='mb-4' data-aos='fade-up'>
                <SectionTitle
                  title={data.sectionTitle}
                  description={data.sectionDescription}
                />
              </div>
              <ul
                className='list-unstyled d-flex flex-wrap list-two-col mt-5'
                data-aos='fade-up'
                data-aos-delay='50'
              >
                <li>
                  <span className='d-block mb-4'>
                    <i className={data.iconOne}></i>
                  </span>
                  <h3 className='h5'>{data.bulletOne}</h3>
                  <p>
                  {data.bulletOneParagraph}
                  </p>
                </li>
                <li>
                  <span className='d-block mb-4'>
                    <i className={data.iconTwo}></i>
                  </span>
                  <h3 className='h5'>{data.bulletTwo}</h3>
                  <p>{data.bulletTwoParagraph} </p>
                </li>
              </ul>
            </div>
            <div className='col-lg-6 order-lg-1'>

                  <img
                    src={`assets/img/${data.image}`}
                    alt=''
                    className='img-fluid'
                  />
                 
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgEight;
