import React from 'react';
import SectionTitle from '../common/SectionTitle';



const renderListOfItems = (items) => {
  return items.map(item =>               
  <div className='single-feature d-flex mb-4'>
  <span className={item.icon}></span>
  <div className='ms-4 mt-2'>
    <h5>{item.bullet}</h5>
    <p>
    {item.bulletParagraph}
    </p>
  </div>
</div>)
}


const FeatureImgContentTwo = ({data}) => {
  return (
    <>
      {/* <section className='feature-section pt-60 pb-120'> */}
      <section className='feature-section pt-60 pb-60'>
        <div className='container'>
          <div className='row align-content-center justify-content-between'>
            <div className='col-lg-5 col-md-12'>
              <div className='icon-box rounded-custom bg-danger shadow-sm d-inline-block'>
                <i className={data.iconMain}></i>
              </div>
              <SectionTitle
                title={data.sectionTitle}
                description={data.sectionDescription} 
              />

               {renderListOfItems(data.items)}

            </div>
            <div className='col-lg-6 col-md-8 row align-content-center'>
              <div className='feature-shape-img position-relative mt-5 mt-lg-0'>
                {/*'<div className='img-bg-shape position-absolute'>
                  <img
                    src='assets/img/integations/shape.svg'
                    alt='integations'
                  />
                </div>
                assets/img/dashkit.png' */}
                {data.useViedo ? <video autoPlay muted loop src={`assets/img/${data.image}`}type="video/mp4" width="720" height="540" /> : <img src={`assets/img/${data.image}`} className='img-fluid  rounded-custom shadow-lg' alt='softtent' height={700} width={750}/>}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgContentTwo;
