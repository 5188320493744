import React from 'react';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import PageMeta from '../../components/common/PageMeta';
import FooterOne from '../../layout/Footer/FooterOne';
import CtaTwo from '../../components/cta/CtaTwo';
import OpenJobs from '../../components/career/OpenJobs';
import CareerPromo from '../../components/career/CareerPromo';
import PageHeader from '../../components/common/PageHeader';

const Career = () => {
  return (
    <Layout>
      <PageMeta title="Career " />
      <Navbar navDark />
      <PageHeader
        title="Careers"
        desc="Crafting Careers, Shaping Futures: Ignite Your Passion in the Heart of AI Innovation. Join Our Team and Pioneer the Next Wave of Intelligent Solutions!"
      />
      <OpenJobs />
      <CareerPromo />
      <CtaTwo />
      <FooterOne footerLight />
    </Layout>
  );
};

export default Career;
