import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import ContactFormThree from '../../components/contact/ContactFormThree';
import FaqOne from '../../components/faqs/FaqOne';
import FeatureImgContent from '../../components/feature-img-content/FeatureImgContent';
import FeatureImgContentTwo from '../../components/feature-img-content/FeatureImgContentTwo';
import FeatureFour from '../../components/features/FeatureFour';
import WorkProcess from '../../components/work-process/WorkProcess'
import HeroSeven from './HeroSeven';
import Promo from '../../components/promo/Promo';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';


let FeatureImgContentTwoData = {
  sectionTitle:"Purpose Built for Generative AI",
  sectionDescription: "Navigate the world of AI effortlessly with a dedicated team of AI experts who are passionate about collaborating closely with you. We are committed to understanding your unique challenges and delivering tailored solutions using our technologies and expertise. ",
  header: "Easily Change and Manipulate stuff",
  headerParagraph: "Conveniently drive stand-alone total linkage for process-centric content. Enthusiastically administrate robust initiatives quickly unleash collaborative with client-focused",
  iconOne:"fal fa fa-thermometer-quarter fa-2x  text-primary mb-4",
  bulletOne:"Measurable Results",
  bulletOneParagraph:"Our goal is to empower your business to achieve measurable results through AI innovation. Let Certo/ai be your strategic partner in harnessing the true potential of AI. Join hands with us to lead the way in the AI-powered future.",
  iconTwo: "fal fa-headset fa-2x text-primary mb-4",
  bulletTwo: "Purpose built",
  bulletTwoParagraph: "Interactively integrate extensible directed communities.",
  //image: "BorrowedImages/SambaNova-Suite-image-09-18-23-2.png",
  image: "BorrowedImages/HomeOverview.png",
  
  items: [
    {
      icon:"fal fa fa-building fa-2x  text-primary mb-4",
      bullet: "Purpose Built",
      bulletParagraph: "Certo is designed from the ground up to allow us to rapidly assemble AI solutions that address the specific needs of your domain."
    },
    {
      icon:"fal fa fa-code fa-2x  text-primary mb-4",
      bullet: "Optimized Performance",
      bulletParagraph: "Integrating high-performance computing, efficient data storage, and streamlined workflows, ensuring peak performance and scalability for your AI and machine learning tasks."
    },
    {
      icon:"fal fa fa-thermometer-quarter fa-2x  text-primary mb-4",
      bullet: "Measurable Results",
      bulletParagraph: "Through improved accuracy, faster processing times, enhanced scalability, and ultimately, the ability to derive actionable insights that contribute to business success."
    }
  ]
}
/*
<div className='single-feature d-flex mb-4'>
<span className={data.iconOne}></span>
<div className='ms-4 mt-2'>
  <h5>{data.bulletOne}</h5>
  <p>
  {data.bulletOneParagraph}.{' '}
  </p>
</div>
</div>
<div className='single-feature d-flex mb-4'>
<span className={data.iconTwo}></span>
<div className='ms-4 mt-2'>
  <h5>{data.bulletTwo}</h5>
  <p>
  {data.bulletTwoParagraph}
  </p>
</div>
</div>
<div className='single-feature d-flex mb-4'>
<span className={data.iconTwo}></span>
<div className='ms-4 mt-2'>
  <h5>{data.bulletTwo}</h5>
  <p>
  {data.bulletTwoParagraph}
  </p>
</div>
</div>
*/
const HomeDataAnalysis = () => {
  return (
    <Layout>
      <PageMeta title='Certo/ai Platform' />
      <Navbar navDark />
      <HeroSeven />
      <FeatureImgContentTwo data={FeatureImgContentTwoData}/>
      <FeatureImgContent />
      <FeatureFour />
      
     
      {/*<FeatureWithBg /> 
      <WorkProcess/>
    
      <Promo/>
        */}
      {/*<PriceOne paddingTop='ptb-120' haspriceTitleWithHeader /> */}
      <FaqOne />
      <ContactFormThree />
      <FooterOne footerLight />
    </Layout>
  );
};

export default HomeDataAnalysis;
