import React from "react";
import PageMeta from "../components/common/PageMeta";

import FooterOne from "../layout/Footer/FooterOne";
import Navbar from "../layout/Header/Navbar";
import Layout from "../layout/Layout";


import WorkProcessOne from '../components/work-process/WorkProcess';
import WorkProcessTwo from '../components/work-process/WorkProcessTwo';
import WorkProcessThree from '../components/work-process/WorkProcessThree';
import WorkProcessFour from '../components/work-process/WorkProcessFour';
import WorkProcessFive from '../components/work-process/WorkProcessFive';
import DigiWorkProcess from '../components/work-process/DigiWorkProcess';

const WorkProcessDemo = () => {
  return (
    <Layout>
      <PageMeta title="WorkProcessDemo" />
      <Navbar />
        <WorkProcessOne/>
        <WorkProcessTwo/>
        <WorkProcessThree/>
        <WorkProcessFour/>
        <WorkProcessFive/>
        <DigiWorkProcess/>
      <FooterOne/>
    </Layout>
  );
};

export default WorkProcessDemo;
