import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import PageMeta from '../../components/common/PageMeta';

import RequestForDemo from '../../components/others/RequestForDemo';
import PromoThree from '../../components/promo/PromoThree';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

import FeatureImgContentTwo from '../../components/feature-img-content/FeatureImgContentTwo';
import UseCaseFeatureImgEight from './UseCaseFeatureImgEight';


let PromoThreeData = {
  title: "This is the Title",
  description: "This is the Description",
  amountOne: "150",
  headerOne:"Completed Deployments",
  textOne: "This is a great accomplishment that needs to be fully appreciated",
  amountTwo: "2k",
  headerTwo:"Happy Clients",
  textTwo: "Energistically impact synergistic convergence for leadership..",
  amountThree: "90%",
  headerThree:"Models Running",
  textThree: "This is a great accomplishment that needs to be fully appreciated"
}

let dataEight = {
  header: "Customer Intelligence",
  tagLine:"Churn Analysis",
  image1: "BorrowedImages/CausalEditing.mp4",
  image: "BorrowedImages/CausalEditing.mp4",
  image2: "BorrowedImages/CausalEditing.mp4",
  headerText:  "Drive interpretable, real-time analysis of customer-level characteristics.  Deployed at scale on billions of underlying customer data points.",
  iconLeft: "fal fa-user fa-2x text-primary",
  iconRight: "fal fa-watch fa-2x text-primary",
  leftSubHeader:"What-if",
  leftSubText:"luyjw5eaery Objectively exceptional via customized intellectual.",
  rightSubHeader:"Real time updates",
  rightSubText:"Real-time transcription",
  linkText:"Customers need more than simple next-best options",
  linkDescription: "They want to know their opinions are valued and thier options validated. Certo's models are capable of teasing signal from noise in ways that elevate your cutomer intelligence enabling new modes of engagement and opportunities for revenue capture.",

}



let FeatureImgContentTwoData = {
  sectionTitle:"Elevating Customer Intelligence",
  sectionDescription: "Certo's AI platform is redefining how organizations understand, engage, and cater to their customer base. From comprehensive data analysis to predictive insights, Certo's AI platform empowers businesses to make informed decisions that resonate with their audience",
  iconMain: "fal fa-users fa-2x text-white",

  //image: "BorrowedImages/customer-centricity.jpg",
  image: "BorrowedImages/CustomerIntelligence.png",
  items: [
    {
      icon:"fal fa fa-building fa-2x  text-primary mb-4",
      bullet: "Personalized Engagement Strategies",
      bulletParagraph: "Certo's AI platform enables the creation of personalized engagement strategies by identifying individual customer preferences and tailoring interactions accordingly. This personalized touch enhances customer satisfaction, loyalty, and overall brand affinity."
    },
    {
      icon:"fal fa fa-code fa-2x  text-primary mb-4",
      bullet: "Holistic Customer Data Analysis",
      bulletParagraph: "Certo's AI platform dives deep into customer data, analyzing it comprehensively to uncover valuable patterns, preferences, and behaviors. This holistic approach ensures a nuanced understanding of customers beyond surface-level interactions."
    },
    {
      icon:"fal fa fa-thermometer-quarter fa-2x  text-primary mb-4",
      bullet: "Predictive Insights for Strategic Decision-Making",
      bulletParagraph: "Leveraging advanced machine learning algorithms, Certo's platform provides predictive insights into customer behaviors and trends. This foresight equips businesses with the ability to make strategic decisions, anticipate customer needs, and stay ahead in a dynamic market landscape."
    }
  ]
}




const CustomerUseCase  = () => {
  return (
    <Layout>
      <PageMeta title="Information Extraction Use Case" />
      <Navbar navDark />
      <PageHeader
        title="Customer Intelligence"
        desc="Certo can play a pivotal role in customer intelligence by leveraging advanced analytics and machine learning algorithms to derive valuable insights from customer data. By harnessing the power of AI in customer intelligence, businesses can gain a deeper understanding of their customer base, anticipate needs, and deliver personalized and seamless experiences, ultimately fostering customer loyalty and driving business growth"
      />
      <FeatureImgContentTwo data= {FeatureImgContentTwoData}/>
      {/*
      <UseCaseFeatureImgEight data={dataEight} paddingTop={"paddingTop"} />
      <PromoThree hasBg data={PromoThreeData}/>
      <RequestForDemo />
  */}
      <FooterOne footerLight />
    </Layout>
  );
};

export default CustomerUseCase ;
