import React from 'react';
import SectionTitle from '../../components/common/SectionTitle';
import { Link } from 'react-router-dom';

const UseCaseFeatureImgEight = ({ data, paddingTop }) => {
  return (
    <>
      <section
        className={`feature-section ${
          paddingTop ? 'ptb-120' : 'pt-60 pb-120'
        } `}
      >
        <div className='container'>
          <div className='row align-items-lg-center justify-content-between'>
            <div className='col-lg-12 order-lg-1'>
              <div className='single-article feature-article rounded-custom my-3'>
                  {/*<Link to='/blog-single' className='article-content'>
                    { data.useImage ?  <img
                    src={`assets/img/BorrowedImages/${data.image}`}
                    alt=''
                    className='img-fluid'
                  />: <video autoPlay  loop src={`assets/img/${data.image2}`}type="video/mp4" width="520" height="330" /> } 
                  </Link>
                    */}
                  <div className='article-content p-4'>
                    <div className='article-category mb-4 d-block'>
                      <Link
                        to='#'
                        className='d-inline-block text-dark badge bg-primary-soft'
                      >
                        {data.tagLine}
                      </Link>
                    </div>
                    {/* <Link to='/blog-single'> */}
                      <h2 className='h5 article-title limit-2-line-text'>
                      {data.linkText}
                      </h2>
                    {/* </Link> */}
                    <p className='limit-2-line-text'>
                    {data.linkDescription}
                    </p>

                    {/* <Link to='#'> */}
                      <div className='d-flex align-items-center pt-4'>
                        <div className='avatar'>
                        <i className="fa fa-list img-fluid rounded-circle me-3"></i>
                        {/*<img
                            src='assets/img/testimonial/1.jpg'
                            alt='avatar'
                            width='40'
                            className='img-fluid rounded-circle me-3'
                          />
                  */}
                        </div>
                        <div className='avatar-info'>
                          <h6 className='mb-0 avatar-name'>{data.majorIngredients}</h6>
                          <span className='small fw-medium text-muted'>
                          {data.minorIngredients}
                          </span>
                        </div>
                      </div>
                    {/* </Link> */}
                  </div>
              </div>
              <div className='position-relative text-decoration-none connected-app-single bg-white border border-2 bg-white mt-0 mt-lg-0 mt-md-0 transition-base rounded-custom d-block overflow-hidden p-5'>
                <div
                  className='bg-light text-center rounded-custom overflow-hidden p-5 mx-lg-auto'
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  { data.useImage ?  <img
                    src={`assets/img/BorrowedImages/${data.image}`}
                    alt=''
                    className='img-fluid'
                  />: <video autoPlay  loop src={`assets/img/${data.image2}`}type="video/mp4" width="920" height="640"  /> } 
                  {/*<video autoPlay muted loop src={`assets/img/${data.image}`}type="video/mp4" width="920" height="640" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UseCaseFeatureImgEight;
