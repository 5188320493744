import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import PageMeta from '../../components/common/PageMeta';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import FeatureImgEight from '../../components/features/FeatureImgEight';
import FeatureImgContentThree from '../../components/feature-img-content/FeatureImgContentThree';
import FeatureImgContentTwo from '../../components/feature-img-content/FeatureImgContentTwo';



let FeatureImgContentThreeData = {
  header: "Synergizing Teams, Enhancing Models",
  headerParagraph: "Maximize the potential of machine learning with Certo's MLOps, a sophisticated suite of practices and tools engineered to harmonize teams and refine models throughout the machine learning lifecycle. This holistic approach ensures streamlined workflows, collaborative efforts, and optimal model performance from development to deployment",
  iconOne:"fal fa-comments-alt fa-2x text-primary mb-4",
  bulletOne:"Collaborative Workflow Optimization",
  bulletOneParagraph:"Certo's MLOps fosters collaboration among diverse teams—data scientists, engineers, and operations—creating an optimized workflow that seamlessly integrates machine learning models into production environments.",
  iconTwo: "fal fa-headset fa-2x text-primary mb-4",
  bulletTwo: "Real-Time Adaptation",
  bulletTwoParagraph: "Embracing an agile methodology, Certo's MLOps enables real-time adaptation of machine learning models, ensuring responsiveness to evolving data patterns and maintaining peak performance in dynamic scenarios",
  image: "BorrowedImages/technology-icon-768x636.png"
}

let FeatureImgContentTwoData = {
  sectionTitle:"Technical Excellence in MLOp",
  sectionDescription: "Leveraging automated CI/CD pipelines, containerization, orchestration technologies, and robust monitoring frameworks, Certo's MLOps not only streamlines deployment processes but also enhances scalability, resource utilization, and the overall reliability of machine learning systems. This technical prowess ensures a harmonized and optimized environment, allowing teams to collaborate effectively and models to perform at their peak in dynamic real-world scenarios.",
  iconMain: "fal fa-shield-check fa-2x text-white",

  image: "dashboard-img-4.png",
  
  items: [
    {
      icon:"fal fa fa-code-branch fa-2x  text-primary mb-4",
      bullet: "Automated Continuous Integration/Continuous Deployment (CI/CD)",
      bulletParagraph: "Certo's MLOps leverages automated CI/CD pipelines, streamlining the integration and deployment of machine learning models. This technical efficiency ensures rapid and consistent delivery of models into production environments."
    },
    {
      icon:"fal fa fa-window-restore fa-2x  text-primary mb-4",
      bullet: "Containerization and Orchestration",
      bulletParagraph: "MLOps on the Certo platform embraces containerization and orchestration technologies, allowing models to be encapsulated in containers for easy deployment and managed efficiently with orchestration tools like Kubernetes. This technical approach enhances scalability and resource utilization."
    },
    {
      icon:"fal fa fa-eye fa-2x  text-primary mb-4",
      bullet: "Monitoring and Logging Frameworks",
      bulletParagraph: "Certo incorporates robust monitoring and logging frameworks into its MLOps, providing comprehensive insights into model performance and system behavior. This technical infrastructure enables proactive issue detection, troubleshooting, and continuous optimization of machine learning systems."
    }
  ]
}


let FeatureImgEightData = {
  sectionTitle:"MLOps on the Certo AI Platform",
  sectionDescription: "From development and training to deployment, monitoring, and maintenance, MLOps on the Certo platform enhances collaboration, agility, and performance, ensuring machine learning models seamlessly integrate into real-world production environments.",
  iconOne:"fal fa-user fa-2x text-primary",
  bulletOne:"End-to-End Automation",
  bulletOneParagraph:"Certo's MLOps orchestrates the entire machine learning lifecycle, automating processes from model development through deployment and ongoing maintenance, fostering efficiency and consistency.",
  iconTwo: "fal fa-watch fa-2x text-primary",
  bulletTwo: "Iterative Deployment",
  bulletTwoParagraph: "The iterative and agile approach embedded in Certo's MLOps enables swift and adaptive model deployment, continuous monitoring, and real-time adaptation. This ensures optimal performance and reliability of machine learning systems.",
 // image: "BorrowedImages/mlops-stack.jpg"
 image: "BorrowedImages/MLOps.png"

}


const MLOps  = () => {
  return (
    <Layout>
      <PageMeta title="MLOps" />
      <Navbar navDark />
      <PageHeader
        title="MLOps"
        desc="Certo employs a comprehensive set of practices and tools that streamline and automate the end-to-end machine learning lifecycle within an organization. It encompasses the entire workflow, from model development and training to deployment, monitoring, and maintenance. MLOps aims to enhance collaboration between data scientists, engineers, and operations teams, ensuring the seamless integration of machine learning models into production environments. This iterative and agile approach facilitates efficient model deployment, continuous monitoring, and adaptation, ultimately optimizing the performance and reliability of machine learning systems in real-world scenarios."
      />
      <FeatureImgEight paddingTop data={FeatureImgEightData}/>
      <FeatureImgContentThree data= {FeatureImgContentThreeData}/>
      <FeatureImgContentTwo data= {FeatureImgContentTwoData}/>
      {/*<PromoThree hasBg data={PromoThreeData}/> 
      <RequestForDemo />*/}
      <FooterOne footerLight />
    </Layout>
  );
};

export default MLOps ;
