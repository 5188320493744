import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import CtaTwo from '../../components/cta/CtaTwo';
import FeatureTwo from '../../components/features/FeatureTwo';
import TestimonialTwo from '../../components/testimonial/TestimonialTwo';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

const Services = () => {
  return (
    <Layout>
      <Navbar navDark />
      <PageHeader
        title="Experience Matters"
        desc="Our technology teams have successfully implemented enterprise solutions that enable human-like comprehension of language, data and insights at scale. A “one size fits all” approach does not make sense when solving complex business problems. Certo.ai solutions are built on the expert.ai Platform and tailored to the unique challenges and requirements of each customer. With production deployments of AI solutions across Fortune 2000 customers in Insurance, Life Sciences, Financial Services and Media, the Certo.ai team with our global partners have likely delivered a similar solution before."
      />

      
      <FooterOne footerLight />
    </Layout>
  );
};

export default Services;
