import React from 'react';
import SectionTitle from '../common/SectionTitle';
import Lottie from 'react-lottie';

const renderListOfItems = (items) => {
  return items.map(item =>  <li className='d-flex align-items-start mb-4'>
  <div className='process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2'>
    <i className={item.icon}></i>
  </div>
  <div className='icon-content'>
    <span className='text-primary h6'>{item.step}</span>
    <h3 className='h5 mb-2'>{item.bullet}</h3>
    <p>
    {item.bulletText}
    </p>
  </div>
</li>)
}




const WorkProcess = ({data}) => {
  return (
    <>
      <section className='work-process ptb-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-10'>
              <SectionTitle
                  title={data.sectionSubTitle}
                  subtitle={data.sectionTitle}
                  description={data.sectionDescription}
                centerAlign
              />
            </div>
          </div>
          <div className='row  justify-content-between'>
            <div className='col-lg-4 col-md-12 order-1 order-lg-0'>
              <div className='img-wrap'>
                {
                  data.useImage ?  <img src={`assets/img/${data.image}`} alt='feature' className='img-fluid rounded-custom'/>  : <Lottie options={data.defaultOptions} height={500} width={500}/> 
                }
              </div>
            </div>
            <div className='col-lg-6 col-md-12 order-0 order-lg-1'>
              <ul className='work-process-list list-unstyled'>
                {renderListOfItems(data.items)}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WorkProcess;
