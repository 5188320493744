import React from 'react';
import { Link } from 'react-router-dom';
import BlogPagination from './BlogPagination';

const BlogGrid = () => {
  return (
    <>
      <section className='masonary-blog-section ptb-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-12'>
              <div className='single-article feature-article rounded-custom my-3'>
                <Link to='/#' className='article-img'>
                  <img
                    src='assets/img/blog/HandbookofDataAugmentation.png'
                    alt='article'
                    className='img-fluid'
                  />
                </Link>
                <div className='article-content p-4'>
                  <div className='article-category mb-4 d-block'>
                    <Link
                      to='#'
                      className='d-inline-block text-dark badge bg-primary-soft'
                    >
                      Data Programing
                    </Link>
                  </div>
                  <Link to='https://arxiv.org/pdf/2401.09444.pdf'>
                    <h2 className='h5 article-title limit-2-line-text'>
                      Online Handbook of Argumentation for AI
                    </h2>
                  </Link>
                  <p className='limit-2-line-text'>
                  This volume contains revised versions of the papers selected for the fourth volume of the Online
                  Handbook of Argumentation for AI (OHAAI). Previously, formal theories of argument and argu-
                  ment interaction have been proposed and studied, and this has led to the more recent study of
                  computational models of argument. Argumentation, as a field within artificial intelligence (AI), is
                  highly relevant for researchers interested in symbolic representations of knowledge and defeasible
                  reasoning
                  </p>

                  <Link to='#'>
                    <div className='d-flex align-items-center pt-4'>
                      <div className='avatar'>
                        <img
                          src='assets/img/testimonial/1.jpg'
                          alt='avatar'
                          width='40'
                          className='img-fluid rounded-circle me-3'
                        />
                      </div>
                      <div className='avatar-info'>
                        <h6 className='mb-0 avatar-name'>Elfia Bezou-Vrakatseli, et al</h6>
                        <span className='small fw-medium text-muted'>
                          December, 2023
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-12'>
              <div className='single-article feature-article rounded-custom my-3'>
                <Link to='/#' className='article-img'>
                  <img
                    src='assets/img/blog/SelfRewardingLanguageModels.png'
                    alt='article'
                    className='img-fluid'
                  />
                </Link>
                <div className='article-content p-4'>
                  <div className='article-category mb-4 d-block'>
                    <Link
                      to='#'
                      className='d-inline-block text-dark badge bg-danger-soft'
                    >
                      Data Curation
                    </Link>
                  </div>
                  <Link to='https://arxiv.org/pdf/2401.10020.pdf'>
                    <h2 className='h5 article-title limit-2-line-text'>
                      Self-Rewarding Language Models
                    </h2>
                  </Link>
                  <p className='limit-2-line-text'>
                  We posit that to achieve superhuman agents, future models require super-
                  human feedback in order to provide an adequate training signal. Current
                  approaches commonly train reward models from human preferences, which
                  may then be bottlenecked by human performance level, and secondly these
                  separate frozen reward models cannot then learn to improve during LLM
                  training. In this work, we study Self-Rewarding Language Models, where the
                  language model itself is used via LLM-as-a-Judge prompting to provide its
                  own rewards during training
                  </p>

                  <Link to='#'>
                    <div className='d-flex align-items-center pt-4'>
                      <div className='avatar'>
                        <img
                          src='assets/img/testimonial/4.jpg'
                          alt='avatar'
                          width='40'
                          className='img-fluid rounded-circle me-3'
                        />
                      </div>
                      <div className='avatar-info'>
                        <h6 className='mb-0 avatar-name'>Meta</h6>
                        <span className='small fw-medium text-muted'>
                          Feb 8, 2024
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='single-article rounded-custom my-3'>
                <Link to= {{ pathname: "https://arxiv.org/pdf/2403.09629.pdf" }} target="_blank"  className='article-img'>
                  <img
                    src='assets/img/blog/QuietStar.png'
                    alt='article'
                    className='img-fluid'
                  />
                </Link>
                <div className='article-content p-4'>
                  <div className='article-category mb-4 d-block'>
                    <Link
                      to='#'
                      className='d-inline-block text-dark badge bg-warning-soft'
                    >
                      Planning
                    </Link>
                  </div>
                  <Link to='/#'>
                    <h2 className='h5 article-title limit-2-line-text'>
                    Much of the meaning of text is hidden between the line
                    </h2>
                  </Link>
                  <p className='limit-2-line-text'>
                    When writing and talking, people sometimes pause to think. Although
                    reasoning-focused works have often framed reasoning as a method of
                    answering questions or completing agentic tasks, reasoning is implicit
                    in almost all written text. 
                  </p>

                  <Link to='#'>
                    <div className='d-flex align-items-center pt-4'>
                      <div className='avatar'>
                        <img
                          src='assets/img/testimonial/6.jpg'
                          alt='avatar'
                          width='40'
                          className='img-fluid rounded-circle me-3'
                        />
                      </div>
                      <div className='avatar-info'>
                        <h6 className='mb-0 avatar-name'>Stanford University</h6>
                        <span className='small fw-medium text-muted'>
                          March 18, 2024
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-article rounded-custom my-3'>
                <Link to='https://arxiv.org/pdf/2306.10193.pdf' className='article-img'>
                  <img
                    src='assets/img/blog/ConformalLanguageModeling.png'
                    alt='article'
                    className='img-fluid'
                  />
                </Link>
                <div className='article-content p-4'>
                  <div className='article-category mb-4 d-block'>
                    <Link
                      to='#'
                      className='d-inline-block text-dark badge bg-primary-soft'
                    >
                      Conformal
                    </Link>
                  </div>
                  <Link to='/#'>
                    <h2 className='h5 article-title limit-2-line-text'>
                    Standard conformal prediction produces prediction
                      sets—in place of single predictions—that have rigorous, statistical performance
                      guarantees
                    </h2>
                  </Link>
                  <p className='limit-2-line-text'>
                  In this paper, we propose a novel approach to conformal prediction for genera-
                    tive language models (LMs).
                  </p>

                  <Link to='#'>
                    <div className='d-flex align-items-center pt-4'>
                      <div className='avatar'>
                        <img
                          src='assets/img/testimonial/1.jpg'
                          alt='avatar'
                          width='40'
                          className='img-fluid rounded-circle me-3'
                        />
                      </div>
                      <div className='avatar-info'>
                        <h6 className='mb-0 avatar-name'>Stanford</h6>
                        <span className='small fw-medium text-muted'>
                          March 18, 2024
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-article rounded-custom my-3'>
                <Link to='/#' className='article-img'>
                  <img
                    src='assets/img/blog/ArtifactsOrAbducxtion.png'
                    alt='article'
                    className='img-fluid'
                  />
                </Link>
                <div className='article-content p-4'>
                  <div className='article-category mb-4 d-block'>
                    <Link
                      to='#'
                      className='d-inline-block text-dark badge bg-danger-soft'
                    >
                      Abduction
                    </Link>
                  </div>
                  <Link to='/#'>
                    <h2 className='h5 article-title limit-2-line-text'>
                      Multiple-choice question answering (MCQA)
                      is often used to evaluate large language mod-
                      els (LLMs)
                    </h2>
                  </Link>
                  <p className='limit-2-line-text'>
                    MCQA assesses LLMs
                    as intended, we probe if LLMs can perform
                    MCQA with choices-only prompts, where mod-
                    els must select the correct answer only from
                    the choices.
                  </p>

                  <Link to='#'>
                    <div className='d-flex align-items-center pt-4'>
                      <div className='avatar'>
                        <img
                          src='assets/img/testimonial/3.jpg'
                          alt='avatar'
                          width='40'
                          className='img-fluid rounded-circle me-3'
                        />
                      </div>
                      <div className='avatar-info'>
                        <h6 className='mb-0 avatar-name'>Allen Institute</h6>
                        <span className='small fw-medium text-muted'>
                          Feb 24, 2024
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            {/*
            <div className='col-lg-4 col-md-6'>
              <div className='single-article rounded-custom my-3'>
                <Link to='/#' className='article-img'>
                  <img
                    src='assets/img/blog/blog-4.jpg'
                    alt='article'
                    className='img-fluid'
                  />
                </Link>
                <div className='article-content p-4'>
                  <div className='article-category mb-4 d-block'>
                    <Link
                      to='#'
                      className='d-inline-block text-dark badge bg-primary-soft'
                    >
                      Marketing
                    </Link>
                  </div>
                  <Link to='/#'>
                    <h2 className='h5 article-title limit-2-line-text'>
                      The role of product ops in successful distributed teams
                    </h2>
                  </Link>
                  <p className='limit-2-line-text'>
                    Society is fragmenting into two parallel realities. In one
                    reality, you have infinite upside and opportunity. In the
                    other reality, you’ll continue to see the gap between your
                    standard of living and those at the top grow more and more.
                  </p>

                  <Link to='#'>
                    <div className='d-flex align-items-center pt-4'>
                      <div className='avatar'>
                        <img
                          src='assets/img/testimonial/4.jpg'
                          alt='avatar'
                          width='40'
                          className='img-fluid rounded-circle me-3'
                        />
                      </div>
                      <div className='avatar-info'>
                        <h6 className='mb-0 avatar-name'>Raymond H. Martin</h6>
                        <span className='small fw-medium text-muted'>
                          May 4, 2021
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-article rounded-custom my-3'>
                <Link to='/#' className='article-img'>
                  <img
                    src='assets/img/blog/blog-5.jpg'
                    alt='article'
                    className='img-fluid'
                  />
                </Link>
                <div className='article-content p-4'>
                  <div className='article-category mb-4 d-block'>
                    <Link
                      to='#'
                      className='d-inline-block text-dark badge bg-warning-soft'
                    >
                      UI/UX
                    </Link>
                  </div>
                  <Link to='/#'>
                    <h2 className='h5 article-title limit-2-line-text'>
                      The modern product manager’s tech stack
                    </h2>
                  </Link>
                  <p className='limit-2-line-text'>
                    Society is fragmenting into two parallel realities. In one
                    reality, you have infinite upside and opportunity. In the
                    other reality, you’ll continue to see the gap between your
                    standard of living and those at the top grow more and more.
                  </p>

                  <Link to='#'>
                    <div className='d-flex align-items-center pt-4'>
                      <div className='avatar'>
                        <img
                          src='assets/img/testimonial/5.jpg'
                          alt='avatar'
                          width='40'
                          className='img-fluid rounded-circle me-3'
                        />
                      </div>
                      <div className='avatar-info'>
                        <h6 className='mb-0 avatar-name'>Luthar Martin</h6>
                        <span className='small fw-medium text-muted'>
                          Jan 24, 2021
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-article rounded-custom my-3'>
                <Link to='/#' className='article-img'>
                  <img
                    src='assets/img/blog/blog-6.jpg'
                    alt='article'
                    className='img-fluid'
                  />
                </Link>
                <div className='article-content p-4'>
                  <div className='article-category mb-4 d-block'>
                    <Link
                      to='#'
                      className='d-inline-block text-dark badge bg-danger-soft'
                    >
                      Management
                    </Link>
                  </div>
                  <Link to='/#'>
                    <h2 className='h5 article-title limit-2-line-text'>
                      30 product management thought leaders to follow
                    </h2>
                  </Link>
                  <p className='limit-2-line-text'>
                    Society is fragmenting into two parallel realities. In one
                    reality, you have infinite upside and opportunity. In the
                    other reality, you’ll continue to see the gap between your
                    standard of living and those at the top grow more and more.
                  </p>

                  <Link to='#'>
                    <div className='d-flex align-items-center pt-4'>
                      <div className='avatar'>
                        <img
                          src='assets/img/testimonial/6.jpg'
                          alt='avatar'
                          width='40'
                          className='img-fluid rounded-circle me-3'
                        />
                      </div>
                      <div className='avatar-info'>
                        <h6 className='mb-0 avatar-name'>Donna Martin</h6>
                        <span className='small fw-medium text-muted'>
                          April 24, 2022
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-article rounded-custom my-3'>
                <Link to='/#' className='article-img'>
                  <img
                    src='assets/img/blog/blog-7.jpg'
                    alt='article'
                    className='img-fluid'
                  />
                </Link>
                <div className='article-content p-4'>
                  <div className='article-category mb-4 d-block'>
                    <Link
                      to='#'
                      className='d-inline-block text-dark badge bg-danger-soft'
                    >
                      Design
                    </Link>
                  </div>
                  <Link to='/#'>
                    <h2 className='h5 article-title limit-2-line-text'>
                      New analyst report: Digital product management tools and
                      tech
                    </h2>
                  </Link>
                  <p className='limit-2-line-text'>
                    Society is fragmenting into two parallel realities. In one
                    reality, you have infinite upside and opportunity. In the
                    other reality, you’ll continue to see the gap between your
                    standard of living and those at the top grow more and more.
                  </p>

                  <Link to='#'>
                    <div className='d-flex align-items-center pt-4'>
                      <div className='avatar'>
                        <img
                          src='assets/img/testimonial/1.jpg'
                          alt='avatar'
                          width='40'
                          className='img-fluid rounded-circle me-3'
                        />
                      </div>
                      <div className='avatar-info'>
                        <h6 className='mb-0 avatar-name'>Donna R. Book</h6>
                        <span className='small fw-medium text-muted'>
                          April 24, 2021
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-article rounded-custom my-3'>
                <Link to='/#' className='article-img'>
                  <img
                    src='assets/img/blog/blog-8.jpg'
                    alt='article'
                    className='img-fluid'
                  />
                </Link>
                <div className='article-content p-4'>
                  <div className='article-category mb-4 d-block'>
                    <Link
                      to='#'
                      className='d-inline-block text-dark badge bg-primary-soft'
                    >
                      Development
                    </Link>
                  </div>
                  <Link to='/#'>
                    <h2 className='h5 article-title limit-2-line-text'>
                      A frank discussion about diversity, inclusion, and
                      allyship
                    </h2>
                  </Link>
                  <p className='limit-2-line-text'>
                    Society is fragmenting into two parallel realities. In one
                    reality, you have infinite upside and opportunity. In the
                    other reality, you’ll continue to see the gap between your
                    standard of living and those at the top grow more and more.
                  </p>

                  <Link to='#'>
                    <div className='d-flex align-items-center pt-4'>
                      <div className='avatar'>
                        <img
                          src='assets/img/testimonial/3.jpg'
                          alt='avatar'
                          width='40'
                          className='img-fluid rounded-circle me-3'
                        />
                      </div>
                      <div className='avatar-info'>
                        <h6 className='mb-0 avatar-name'>Donna R. Martin</h6>
                        <span className='small fw-medium text-muted'>
                          April 24, 2021
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-article rounded-custom my-3'>
                <Link to='/#' className='article-img'>
                  <img
                    src='assets/img/blog/blog-9.jpg'
                    alt='article'
                    className='img-fluid'
                  />
                </Link>
                <div className='article-content p-4'>
                  <div className='article-category mb-4 d-block'>
                    <Link
                      to='#'
                      className='d-inline-block text-dark badge bg-warning-soft'
                    >
                      Design
                    </Link>
                  </div>
                  <Link to='/#'>
                    <h2 className='h5 article-title limit-2-line-text'>
                      4 steps for measuring the impact of product discovery
                    </h2>
                  </Link>
                  <p className='limit-2-line-text'>
                    Society is fragmenting into two parallel realities. In one
                    reality, you have infinite upside and opportunity. In the
                    other reality, you’ll continue to see the gap between your
                    standard of living and those at the top grow more and more.
                  </p>

                  <Link to='#'>
                    <div className='d-flex align-items-center pt-4'>
                      <div className='avatar'>
                        <img
                          src='assets/img/testimonial/2.jpg'
                          alt='avatar'
                          width='40'
                          className='img-fluid rounded-circle me-3'
                        />
                      </div>
                      <div className='avatar-info'>
                        <h6 className='mb-0 avatar-name'>Martin Luthar</h6>
                        <span className='small fw-medium text-muted'>
                          April 24, 2021
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
  */}
          </div>
          <BlogPagination />
        </div>
      </section>
    </>
  );
};

export default BlogGrid;
