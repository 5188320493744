import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import HomeSassTwo from '../themes/index2/HomeSassTwo';
import Services from '../pages/services/Services';
import UseCases from '../pages/usecases/UseCases';
import LoanAnalyticsUseCase  from '../pages/usecases/LoanAnalyticsUseCase';
import VisualUnderstandingUseCase  from '../pages/usecases/VisualUnderstandingUseCase';
import PredictiveAnalyticsUseCase  from '../pages/usecases/PredictiveAnalyticsUseCase';
import CounterfactualUseCase  from '../pages/usecases/CounterfactualUseCase';
import VoiceToTextUseCase  from '../pages/usecases/VoiceToTextUseCase';
import DocumentsUseCase   from '../pages/usecases/DocumentsUseCase';
import CustomerUseCase   from '../pages/usecases/CustomerUseCase';
import DecisionSupportUseCase   from '../pages/usecases/DecisionSupportUseCase';
import ProcessAutomationUseCase    from '../pages/usecases/ProcessAutomationUseCase';
import AlternativesUseCase    from '../pages/usecases/AlternativesUseCase';

import MLOPs   from '../pages/infrastructure/MLOPs';
import DataPrograming   from '../pages/infrastructure/DataPrograming';
import Curation   from '../pages/infrastructure/Curation';
import Augmentation   from '../pages/infrastructure/Augmentation';
import Privacy  from '../pages/infrastructure/Privacy';


import FinancialServices  from '../pages/industries/FinancialServices';


import SingleService from '../pages/services/SingleService';
import Login from '../pages/onboard/Login';
import NotFoundScreen from '../components/others/NotFoundScreen';
import HomeDesktopApp from '../themes/index3/HomeDesktopApp';
import PasswordReset from '../pages/onboard/PasswordReset';
import Pricing from '../pages/Pricing';
import ScrollToTop from '../components/common/ScrollToTop';

import HomeSoftApplication from '../themes/index5/HomeSoftApplication';

import ToolkitStartup from '../themes/toolkit/ToolkitStartup';
import GenerativeStartup from '../themes/generative/GenerativeStartup';
import FoundationalStartup from '../themes/foundational/FoundationalStartup';
import PlanningStartup from '../themes/planning/PlanningStartup';
import CausalStartup from '../themes/causal/CausalStartup';
import PredictiveStartup from '../themes/predictive/PredictiveStartup';


import HomeDataAnalysis from '../themes/index7/HomeDataAnalysis';
import About from '../pages/About';
import Contact from '../pages/Contact';
import ComingSoon from '../pages/ComingSoon';
import Integrations from '../pages/integration/Integrations';
import IntegrationSingle from '../pages/integration/IntegrationSingle';
import Blogs from '../pages/blog/Blogs';
import SingleBlog from '../pages/blog/SingleBlog';
import Career from '../pages/career/Career';
import CareerSingle from '../pages/career/CareerSingle';
import HelpCenter from '../pages/help-center/HelpCenter';
import SingleHelpCenter from '../pages/help-center/SingleHelpCenter';
import Signup from '../pages/onboard/Signup';
import RequestDemo from '../pages/RequestDemo';
import HomeAppTwo from '../themes/index8/HomeAppTwo';
import HomeNine from '../themes/index9/HomeNine';
import HomeTen from '../themes/index10/HomeTen';
import SingleProduct from '../pages/product/SingleProduct';
import SinglePortfolio from '../pages/SinglePortfolio';
import HomeEleven from '../themes/index11/HomeEleven';
import HomeTwelve from '../themes/index12/HomeTwelve';
import HomeThirteen from '../themes/index13/HomeThirteen';
import HomeFourteen from '../themes/index14/HomeFourteen';
import HomeFifteen from '../themes/index15/HomeFifteen';

import FeaturesDemo from '../pages/FeaturesDemo';
import WorkProcessDemo from '../pages/WorkProcessDemo';
import ModelsStartup from '../themes/models/ModelsStartup';
import InfrastructureStartup from '../themes/infrastructure/InfrastructureStartup';


function index() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<HomeDataAnalysis />} />
        
        <Route exact path="/models" element={<ModelsStartup/>} />
        <Route exact path="/infrastructure" element={<InfrastructureStartup/>} />
        <Route exact path="/foundational" element={<FoundationalStartup/>} />
        <Route exact path="/home-sass-two" element={<HomeSassTwo />} />
        <Route exact path="/home-desktop-app" element={<HomeDesktopApp />} />
        <Route exact path="/planning" element={<PlanningStartup />} />
        <Route exact path="/home-soft-app" element={<HomeSoftApplication />} />

        <Route exact path="/toolkit" element={<ToolkitStartup />} />
        <Route exact path="/generative" element={<GenerativeStartup />} />
        <Route exact path="/causal" element={<CausalStartup />} />
        <Route exact path="/predictive" element={<PredictiveStartup />} />

        <Route exact path="/financial-services" element={<FinancialServices />} />
        

        


        <Route
          exact
          path="/home-data-analysis"
          element={<HomeDataAnalysis />}
        />
        <Route exact path="/home-nine" element={<HomeNine />} />
        <Route exact path="/home-ten" element={<HomeTen />} />
        <Route exact path="/home-eleven" element={<HomeEleven />} />
        <Route exact path="/home-thirteen" element={<HomeThirteen />} />
        <Route exact path="/home-fourteen" element={<HomeFourteen />} />
        <Route exact path="/home-fifteen" element={<HomeFifteen />} />
        <Route exact path="/home-twelve" element={<HomeTwelve />} />
        <Route exact path="/home-app-two" element={<HomeAppTwo />} />
        <Route exact path="/home-nine" element={<HomeNine />} />
        <Route exact path="/home-ten" element={<HomeTen />} />

        <Route exact path="/features-demo" element={<FeaturesDemo />} />
        <Route exact path="/work-process-demo" element={<WorkProcessDemo />} />
        

        <Route exact path="/single-product" element={<SingleProduct />} />
        <Route exact path="/portfolio-single" element={<SinglePortfolio />} />
        <Route exact path="/about-us" element={<About />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/usecases" element={<UseCases />} />
        <Route exact path="/loan-analytics" element={<LoanAnalyticsUseCase />} />
        <Route exact path="/visual-understanding" element={<VisualUnderstandingUseCase />} />
        
        <Route exact path="/predictive-analytics" element={<PredictiveAnalyticsUseCase />} />
        <Route exact path="/counterfactual" element={<CounterfactualUseCase />} />
        <Route exact path="/voice-to-text" element={<VoiceToTextUseCase />} />
        <Route exact path="/documents" element={<DocumentsUseCase />} />
        <Route exact path="/customer" element={<CustomerUseCase />} />
        
        <Route exact path="/decision-support" element={<DecisionSupportUseCase/>} />
        <Route exact path="/process-automation" element={<ProcessAutomationUseCase />} />
        <Route exact path="/alternatives" element={<AlternativesUseCase />} />

        <Route exact path="/mlops" element={<MLOPs />} />
        <Route exact path="/data-programing" element={<DataPrograming />} />
        <Route exact path="/curation" element={<Curation />} />
        <Route exact path="/augmentation" element={<Augmentation />} />
        <Route exact path="/privacy" element={<Privacy />} />

        
        
        <Route exact path="/single-service" element={<SingleService />} />
        <Route exact path="/contact-us" element={<Contact />} />
        <Route exact path="/pricing" element={<Pricing />} />
        <Route exact path="/blogs" element={<Blogs />} />
        <Route exact path="/blog-single" element={<SingleBlog />} />
        <Route exact path="/career" element={<Career />} />
        <Route exact path="/career-single" element={<CareerSingle />} />
        <Route exact path="/help-center" element={<HelpCenter />} />
        <Route
          exact
          path="/help-center-single"
          element={<SingleHelpCenter />}
        />
        <Route exact path="/password-reset" element={<PasswordReset />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/request-for-demo" element={<RequestDemo />} />
        <Route exact path="/coming-soon" element={<ComingSoon />} />
        <Route exact path="/integrations" element={<Integrations />} />
        <Route
          exact
          path="/integration-single"
          element={<IntegrationSingle />}
        />
        <Route element={<NotFoundScreen />} />
      </Routes>
    </BrowserRouter>
  );
}

export default index;
