import React from 'react';
import SectionTitle from '../common/SectionTitle';

const FeatureFour = () => {
  return (
    <>
      <section className='feature-section ptb-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-12'>
              <SectionTitle
                subtitle='AI Powered Knowledge Applications'
                title='How Certo Achieves Results'
                description="Certo is a Knowledge Platform, powered by Artificial Intelligence.  It enables your enterprise to leverage your data, securely and transparently, to unlock the value of information. Certo's pre-built models and easy-to-configure workflows quickly automate any process."
                centerAlign
              />
            </div>
          </div>
          <div className='row align-items-center'>
            <div className='col-lg-4 col-md-6'>
              <div
                className='position-relative'
                data-aos='fade-up'
                data-aos-delay='50'
              >
                <div className='cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2'>
                  <div className='feature-icon d-inline-block bg-primary-soft rounded-circle mb-32'>
                    <i className='fad fa-shield-alt text-primary fa-2x'></i>
                  </div>
                  <h3 className='h5'>Collaborate</h3>
                  <p className='mb-0'>
                    Unlock the vast amounts of information latent in your people, processes and enterprise data
                  </p>
                </div>

                <div className='dot-shape-bg position-absolute z--1 left--40 top--40'>
                  <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div
                className='position-relative'
                data-aos='fade-up'
                data-aos-delay='100'
              >
                <div className='cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0'>
                  <div className='feature-icon d-inline-block bg-danger-soft rounded-circle mb-32'>
                    <i className='fad fa-comment-alt-smile text-danger fa-2x'></i>
                  </div>
                  <h3 className='h5'>Innovate</h3>
                  <p className='mb-0'>
                    Applications tailored to your specific processes.  Bespoke models, data and infrastructure deployable on any cloud, your metal or ours.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div
                className='position-relative'
                data-aos='fade-up'
                data-aos-delay='150'
              >
                <div className='cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0'>
                  <div className='feature-icon d-inline-block bg-success-soft rounded-circle mb-32'>
                    <i className='fad fa-grin-stars text-success fa-2x'></i>
                  </div>
                  <h3 className='h5'>Activate</h3>
                  <p className='mb-0'>
                    Enterprise grade reliability across all segments: accuracy, security, safety, and service-levels.
                  </p>
                </div>

                <div className='dot-shape-bg position-absolute z--1 right--40 bottom--40'>
                  <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureFour;
