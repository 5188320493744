import React from "react";
import { Link } from "react-router-dom";

const FooterOne = ({ footerLight, style, footerGradient }) => {
  return (
    <>
      <footer className="footer-section">
        <div
          className={`footer-top ${footerLight ? "footer-light" : "bg-dark"} ${
            footerGradient ? "bg-gradient" : ""
          }  text-white ptb-120`}
          style={style}
        >
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-8 col-lg-4 mb-md-4 mb-lg-0">
                <div className="footer-single-col ">
                  <div className="footer-single-col mb-4 ">
                    <Link
                      to="/"
                      className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none"
                    >
                      <img
                        src="assets/img/BorrowedImages/CertoAILogo.png"
                        alt="logo"
                        className="certo-logo "
                        width={25}
                        height={25}
                      />
                      <span className="test-dark" width={113} height={36}>
                        Certo<span className="ai">/ai </span>
                      </span>
                    </Link>
                  </div>
                  {/* <p>
                    Our latest news, articles, and resources, we will sent to
                    your inbox weekly.
                  </p>

                  <form className='newsletter-form position-relative d-block d-lg-flex d-md-flex'>
                    <input
                      type='text'
                      className='input-newsletter form-control me-2'
                      placeholder='Enter your email'
                      name='email'
                      required=''
                      autoComplete='off'
                    />
                    <input
                      type='submit'
                      value='Subscribe'
                      data-wait='Please wait...'
                      className='btn btn-primary mt-3 mt-lg-0 mt-md-0'
                    />
                  </form> */}
                </div>
              </div>
              <div className="col-md-12 col-lg-7 mt-4 mt-md-0 mt-lg-0">
                <div className="row">
                  <div className="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
                    <div className="footer-single-col">
                      <h3>Foundations</h3>
                      <ul className="list-unstyled footer-nav-list mb-lg-0">
                        <li>
                          <Link to="/models" className="text-decoration-none">
                            Models
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/infrastructure"
                            className="text-decoration-none"
                          >
                            Infrastructure
                          </Link>
                        </li>
                        <li>
                          <Link to="/planning" className="text-decoration-none">
                            Process
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/augmentation"
                            className="text-decoration-none"
                          >
                            Human Centric AI
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
                    <div className="footer-single-col">
                      <h3>Data/Infrastructure</h3>
                      <ul className="list-unstyled footer-nav-list mb-lg-0">
                        <li>
                          <Link to="/curation" className="text-decoration-none">
                            Dataset Curation
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/data-programing"
                            className="text-decoration-none"
                          >
                            Data Engineering
                          </Link>
                        </li>
                        <li>
                          <Link to="/privacy" className="text-decoration-none">
                            Data Privacy and Security
                          </Link>
                        </li>
                        <li>
                          <Link to="/mlops" className="text-decoration-none">
                            MLOps
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 mt-4 mt-md-0 mt-lg-0">
                    <div className="footer-single-col">
                      <h3>Company</h3>
                      <ul className="list-unstyled footer-nav-list mb-lg-0">
                        <li>
                          <Link to="/" className="text-decoration-none">
                            Home
                          </Link>
                        </li>
                        <li>
                          <Link to="/about-us" className="text-decoration-none">
                            About Us
                          </Link>
                        </li>
                        {/* <li>
                          <Link to="/services" className="text-decoration-none">
                            Services
                          </Link>
                        </li> */}
                        {/* <li>
                          <Link to="/career" className="text-decoration-none">
                            Career
                          </Link>
                        </li> */}
                        <li>
                          <Link
                            to="/integrations"
                            className="text-decoration-none"
                          >
                            Integrations
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/contact-us"
                            className="text-decoration-none"
                          >
                            Contact Us
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`footer-bottom ${
            footerLight ? "footer-light" : "bg-dark"
          } ${footerGradient ? "bg-gradient" : ""} text-white py-4`}
        >
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-md-7 col-lg-7">
                <div className="copyright-text">
                  <p className="mb-lg-0 mb-md-0">
                    {/* &copy; 2024 Certo.ai, an L1 Company */}
                    &copy; 2024
                    <Link
                      to="/https://Certo.ai/"
                      className="text-decoration-none ms-2"
                    >
                      Certo.ai
                    </Link>
                    , an L1 Company
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-lg-4">
                <div className="footer-single-col text-start text-lg-end text-md-end">
                  <ul className="list-unstyled list-inline footer-social-list mb-0">
                    {/* <li className="list-inline-item">
                      <Link to="/#">
                        <i className="fab fa-twitter"></i>
                      </Link>
                    </li> */}
                    <li className="list-inline-item">
                      <a
                        href="https://www.linkedin.com/company/certo-ai/?viewAsMember=true"
                        target="_blank"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>

                    {/* <li className="list-inline-item">
                      <Link to="/#">
                        <i className="fab fa-github"></i>
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterOne;
