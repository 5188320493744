import React from 'react';
import SectionTitle from '../common/SectionTitle';

const FeatureImgThree = ({paddingTop, data}) => {
  return (
    <>
      <section className={`feature-section-two ${paddingTop?'ptb-120':'pt-60 pb-120'} `}>
        <div className='container'>
          <div className='row align-items-center justify-content-between'>
            <div className='col-lg-5 col-md-12'>
              <SectionTitle
                subtitle={data.sectionSubTitle}
                title={data.sectionTitle}
                description={data.sectionDescription}
              />
              <div>
                <ul className='list-unstyled mt-5'>
                  <li className='d-flex align-items-start mb-4'>
                    <div className='icon-box bg-primary rounded me-4'>
                      <i className={data.iconOne}></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'>{data.headerOne}</h3>
                      <p>
                        {data.headerOneParagraph}
                      </p>
                    </div>
                  </li>
                  <li className='d-flex align-items-start mb-4'>
                    <div className='icon-box bg-danger rounded me-4'>
                      <i className={data.iconTwo}></i>
                    </div>
                    <div className='icon-content'>
                      <h3 className='h5'>{data.headerTwo}</h3>
                      <p>
                      {data.headerTwoParagraph}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-6 col-md-7'>
              <div className='feature-img-wrap position-relative d-flex flex-column align-items-end'>
                <ul className='img-overlay-list list-unstyled position-absolute'>
                  <li
                    className='d-flex align-items-center bg-white rounded shadow-sm p-3'
                    data-aos='fade-right'
                    data-aos-delay='50'
                  >
                    <i className={data.listIconeOne}></i>
                    <h6 className='mb-0'>{data.listIconOneText}</h6>
                  </li>
                  <li
                    className='d-flex align-items-center bg-white rounded shadow-sm p-3'
                    data-aos='fade-right'
                    data-aos-delay='100'
                  >
                    <i className={data.listIconeTwo}></i>
                    <h6 className='mb-0'>{data.listIconTwoText}</h6>
                  </li>
                  <li
                    className='d-flex align-items-center bg-white rounded shadow-sm p-3'
                    data-aos='fade-right'
                    data-aos-delay='150'
                  >
                    <i className={data.listIconeThree}></i>
                    <h6 className='mb-0'>{data.listIconThreeText}</h6>
                  </li>
                </ul>
                <img
                  src={`assets/img/BorrowedImages/${data.image}`}
                  alt='feature'
                  className='img-fluid rounded-custom'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgThree;
