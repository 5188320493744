const IconBoxData = [
  {
    id: 1,
    classOption: 'bg-primary',
    icon: 'fal fa-layer-group fa-2x text-white',
    title: 'Good Performance',
    description:
      'Appropriately grow competitive leadership rather than strategic technically sound processes without state .',
  },
  {
    id: 2,
    classOption: 'bg-danger',
    icon: 'fal fa-shield-check fa-2x text-white',
    title: 'Highly Secure',
    description:
      'Appropriately grow competitive leadership rather than strategic technically sound processes without state.',
  },
  {
    id: 3,
    classOption: 'bg-dark',
    icon: 'fal fa-code fa-2x text-white',
    title: 'Fast Development',
    description:
      'Appropriately grow competitive leadership rather than strategic technically sound processes without state.',
  },
];

const FaqOneHeadline = [
  {
    id: 0,
    faqTitle: 'Introducing Certo',
    faqDesc:
      'Frequently Asked Questions'
    } 
    ];

const FaqOneData = [
{
  id: 1,
  faqTitle: ' What is Certo?',
  faqDesc:
    'The Certo platform is a comprehensive system that incorporates artificial intelligence (AI) technologies to perform various tasks, simulate human intelligence, and make decisions. It includes a combination of machine learning, natural language processing, and other AI components to provide diverse functionalities.',
},
  {
    id: 2,
    faqTitle: 'How does Certo work?',
    faqDesc:
      'Certo leverages algorithms and models to process data, recognize patterns, and make predictions or decisions. Certo learns from data inputs and continuously improves its performance over time. The specific workings can vary based on the platform\'s design, but generally, it involves data input, processing, and output through trained models.',
  },
  {
    id: 3,
    faqTitle: 'What is machine learning, and how does it relate to the Certo platform?',
    faqDesc:
      'Machine learning is a subset of AI that focuses on building systems that can learn and improve from experience. In AI platforms, machine learning is often a core component. Models are trained on large datasets to recognize patterns and make predictions, enabling the platform to perform tasks without explicit programming.',
  },
  {
    id: 4,
    faqTitle: 'What types of tasks can the Certo platform perform?',
    faqDesc:
      'Certo can perform a wide range of tasks, including: image and speech recognition, natural language processing, decision-making, problem-solving, and automation of various processes. They are versatile and can be applied in diverse domains such as finance, customer service, healthcare, and more.',
  },
  {
    id: 5,
    faqTitle: 'How is data handled in Certo?',
    faqDesc:
      'Data is a crucial aspect of Certo. All aspects of the platform require varying amounts of data for training and fine-tuning models. The data is carefully curated, and privacy and security measures are implemented to protect sensitive information. Additionally, it allows users to customize and input their own data for specific applications.',
  },
  {
    id: 6,
    faqTitle: 'Is the Certo platform autonomous?',
    faqDesc:
      'Some aspects of the Certo platform can exhibit a level of autonomy, depending on their design and purpose. Some platforms operate with minimal human intervention once trained, while others may require ongoing supervision and input. The degree of autonomy varies based on the complexity of the tasks the platform is designed to perform.',
  },
  {
    id: 7,
    faqTitle: ' How does natural language processing (NLP) contribute to the Certo platform?',
    faqDesc:
      'NLP enables Certo to understand and process human language, both written and spoken. This capability allows the platform to interact with users in a more natural way, facilitating applications such as chatbots, language translation, sentiment analysis, and more.',
  },
  {
    id: 8,
    faqTitle: 'Can Certo learn in real-time?',
    faqDesc:
      'Certo is designed to adapt and learn in real-time, continuously improving its performance as it encounters new data. This feature is particularly valuable in dynamic environments where the system needs to stay updated and responsive to changes.',
  },

];

const TestimonialData = [
  {
    id: 1,
    authorImg: 'assets/img/testimonial/1.jpg',
    authorName: 'Mr.Rupan Oberoi',
    authorTitle: 'Founder and CEO at Amaara Herbs',
    quoteTitle: 'The Best Template You Got to Have it!',
    authorQuote:
      'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media than ethical',
  },
  {
    id: 2,
    authorImg: 'assets/img/testimonial/2.jpg',
    authorName: 'Joan Dho',
    authorTitle: 'Founder and CTO',
    quoteTitle: 'Best Template for SAAS Company!',
    authorQuote:
      'Dynamically create innovative core competencies with effective best practices promote innovative infrastructures.',
  },
  {
    id: 3,
    authorImg: 'assets/img/testimonial/3.jpg',
    authorName: 'Ranu Mondal',
    authorTitle: 'Lead Developer',
    quoteTitle: 'It is undeniably good!',
    authorQuote:
      'Rapidiously supply client-centric e-markets and maintainable processes progressively engineer',
  },
  {
    id: 4,
    authorImg: 'assets/img/testimonial/4.jpg',
    authorName: 'Mr.Rupan Oberoi',
    authorTitle: 'Founder and CEO at Amaara Herbs',
    quoteTitle: 'The Best Template You Got to Have it!',
    authorQuote:
      'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media than ethical',
  },
];

const TestimonialOneData = [
  {
    id: '#testimonial-tab-1',
    activeClass: 'active',
    title: 'The Best Template You Got to Have it!',
    desc: 'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.',
    authorName: 'Joe Richard',
    authorDesn: 'Visual Designer',
    authorThumb: 'assets/img/testimonial/t-1.jpg',
    authorAvatar: 'assets/img/testimonial/1.jpg',
  },
  {
    id: '#testimonial-tab-2',
    title: 'Embarrassed by the First Version.',
    desc: 'Energistically streamline robust architectures whereas distributed mindshare. Intrinsicly leveraother backend growth strategies through 24/365 products. Conveniently pursue revolutionary communities for compelling process improvements.',
    authorName: 'Rupan Oberoi',
    authorDesn: 'Web Designer',
    authorThumb: 'assets/img/testimonial/t-2.jpg',
    authorAvatar: 'assets/img/testimonial/2.jpg',
  },
  {
    id: '#testimonial-tab-3',
    title: 'The Best Template You Got to Have it!',
    desc: 'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.',
    authorName: 'Joe Richard',
    authorDesn: 'Visual Designer',
    authorThumb: 'assets/img/testimonial/t-3.jpg',
    authorAvatar: 'assets/img/testimonial/3.jpg',
  },
  {
    id: '#testimonial-tab-4',
    title: 'The Best Template You Got to Have it!',
    desc: 'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.',
    authorName: 'Joe Richard',
    authorDesn: 'Visual Designer',
    authorThumb: 'assets/img/testimonial/t-4.jpg',
    authorAvatar: 'assets/img/testimonial/4.jpg',
  },
  {
    id: '#testimonial-tab-5',
    title: 'The Best Template You Got to Have it!',
    desc: 'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.',
    authorName: 'Joe Richard',
    authorDesn: 'Visual Designer',
    authorThumb: 'assets/img/testimonial/t-5.jpg',
    authorAvatar: 'assets/img/testimonial/5.jpg',
  },
];

export { IconBoxData, FaqOneHeadline, FaqOneData, TestimonialData, TestimonialOneData };
