import React from 'react';
import { Link } from 'react-router-dom';


const IntegrationThree = () => {
  return (
    <>
      <section className='our-integration ptb-120 bg-light'>
        <div className='container'>
          <div className='position-relative w-100'>
            <div className='row'>
              <div className='col-lg-4 col-md-6'>
                <Link
                  to='/integration-single'
                  className='position-relative text-decoration-none connected-app-single bg-white border border-2 bg-white mt-0 mt-lg-0 mt-md-0 transition-base rounded-custom d-block overflow-hidden p-5'
                >
                  <div className='position-relative connected-app-content'>
                    <div className='integration-logo bg-white rounded-circle p-2 d-inline-block'>
                      <img
                        src='assets/img/azure.svg'
                        width='40'
                        alt='integration'
                        className='img-fluid'
                      />
                    </div>
                    <h5>Azure</h5>
                    <p className='mb-0 text-muted'>
                    Leveraging Azure's powerful compute resources, scalable infrastructure, and cutting-edge AI capabilities, our platform offers unparalleled performance and flexibility. With seamless integration, users can effortlessly harness Azure's vast array of tools and services to enhance their AI projects, from data storage and processing to advanced machine learning and analytics. Accelerate your AI initiatives with confidence, backed by the reliability and innovation of Azure's ecosystem.
                    </p>
                  </div>
                  <span className='badge position-absolute integration-badge bg-danger-soft px-3 py-2 text-danger'>
                    Partner
                  </span>
                </Link>
              </div>
              <div className='col-lg-4 col-md-6'>
                <Link
                  to='/integration-single'
                  className='position-relative text-decoration-none connected-app-single bg-white border border-2 bg-white mt-4 mt-lg-0 mt-md-0 transition-base rounded-custom d-block overflow-hidden p-5'
                >
                  <div className='position-relative connected-app-content'>
                    <div className='integration-logo bg-white rounded-circle p-2 d-inline-block'>
                      <img
                        src='assets/img/aws-logo.png'
                        width='40'
                        alt='integration'
                        className='img-fluid'
                      />
                    </div>
                    <h5>AWS</h5>
                    <p className='mb-0 text-muted'>
                    By harnessing AWS's unparalleled cloud infrastructure, scalable compute resources, and comprehensive AI services, our platform empowers users to accelerate their AI projects with ease. From data storage and processing to advanced machine learning algorithms, our integration with AWS enables seamless access to a vast array of tools and services. Embark on your AI journey confidently, knowing you have the reliability and innovation of AWS powering your endeavors every step of the way.
                    </p>
                  </div>
                  <span className='badge position-absolute integration-badge bg-danger-soft px-3 py-2 text-danger'>
                    Partner
                  </span>
                </Link>
              </div>
              <div className='col-lg-4 col-md-6'>
                <Link
                  to='/integration-single'
                  className='position-relative text-decoration-none connected-app-single bg-white border border-2 bg-white mt-4 mt-lg-0 transition-base rounded-custom d-block overflow-hidden p-5'
                >
                  <div className='position-relative connected-app-content'>
                    <div className='integration-logo bg-white rounded-circle p-2 d-inline-block'>
                      <img
                        src='assets/img/Google-Cloud-logo.png'
                        width='40'
                        alt='integration'
                        className='img-fluid'
                      />
                    </div>
                    <h5>Google Cloud</h5>
                    <p className='mb-0 text-muted'>
                    Google Cloud's cutting-edge infrastructure, scalable computing capabilities, and advanced AI services, our platform empowers users to drive their AI initiatives forward effortlessly. From data storage and analysis to machine learning and predictive analytics, our integration with Google Cloud offers access to a comprehensive suite of tools and services. Embark on your AI journey with confidence, knowing you have the expertise and reliability of Google Cloud backing your endeavors.
                    </p>
                  </div>
                  <span className='badge position-absolute integration-badge bg-danger-soft px-3 py-2 text-danger'>
                    Premium
                  </span>
                </Link>
              </div>
              <div className='col-lg-4 col-md-6'>
                <Link
                  to='/integration-single'
                  className='position-relative text-decoration-none connected-app-single bg-white border border-2 bg-white mt-4 transition-base rounded-custom d-block overflow-hidden p-5'
                >
                  <div className='position-relative connected-app-content'>
                    <div className='integration-logo bg-white rounded-circle p-2 d-inline-block'>
                      <img
                        src='https://upload.wikimedia.org/wikipedia/commons/f/f9/Salesforce.com_logo.svg'
                        width='40'
                        alt='integration'
                        className='img-fluid'
                      />
                    </div>
                    <h5>Salesforce</h5>
                    <p className='mb-0 text-muted'>
                    Salesforce's customer relationship management (CRM) platform and our advanced AI solutions, users can unlock unprecedented insights and efficiencies. From personalized customer interactions to predictive analytics, our integration with Salesforce enables businesses to elevate their operations and drive growth. Experience the power of AI within the familiar Salesforce environment, empowering you to make smarter decisions and cultivate stronger customer relationships.
                    </p>
                  </div>
                  <span className='badge position-absolute integration-badge bg-success-soft px-3 py-2 text-success'>
                    Extended
                  </span>
                </Link>
              </div>
              <div className='col-lg-4 col-md-6'>
                <Link
                  to='/integration-single'
                  className='position-relative text-decoration-none connected-app-single bg-white border border-2 bg-white mt-4 transition-base rounded-custom d-block overflow-hidden p-5'
                >
                  <div className='position-relative connected-app-content'>
                    <div className='integration-logo bg-white rounded-circle p-2 d-inline-block'>
                      <img
                        src='assets/img/logo-appian-rebrand.svg'
                        width='40'
                        alt='integration'
                        className='img-fluid'
                      />
                    </div>
                    <h5>Appian</h5>
                    <p className='mb-0 text-muted'>
                    Appian's low-code automation platform. By merging the agility of low-code development with the intelligence of AI, our integration empowers organizations to streamline workflows, automate tasks, and drive innovation. From intelligent decision-making to predictive analytics, our solution within Appian enables businesses to achieve operational excellence and accelerate digital transformation. Experience the synergy of AI and low-code development, unlocking new levels of efficiency and agility within the powerful Appian platform.
                    </p>
                  </div>
                  <span className='badge position-absolute integration-badge bg-danger-soft px-3 py-2 text-danger'>
                    Premium
                  </span>
                </Link>
              </div>
              <div className='col-lg-4 col-md-6'>
                <Link
                  to='/integration-single'
                  className='position-relative text-decoration-none connected-app-single bg-white border border-2 bg-white mt-4 transition-base rounded-custom d-block overflow-hidden p-5'
                >
                  <div className='position-relative connected-app-content'>
                    <div className='integration-logo bg-white rounded-circle p-2 d-inline-block'>
                      <img
                        src='assets/img/lambda-logo.svg'
                        width='40'
                        alt='integration'
                        className='img-fluid'
                      />
                    </div>
                    <h5>Lambda Cloud</h5>
                    <p className='mb-0 text-muted'>
                    Lambda Cloud's agile infrastructure and advanced cloud services, our AI solution empowers users to scale their projects effortlessly and unleash innovation. From data processing to machine learning model training, our integration with Lambda Cloud offers a streamlined experience for deploying and managing AI applications. Experience the synergy of AI and cloud computing within Lambda Cloud's flexible environment, accelerating your journey towards transformative outcomes.
                    </p>
                  </div>
                  <span className='badge position-absolute integration-badge bg-primary-soft px-3 py-2 text-primary'>
                    New
                  </span>
                </Link>
              </div>
              <div className='col-lg-4 col-md-6'>
                <Link
                  to='/integration-single'
                  className='position-relative text-decoration-none connected-app-single bg-white border border-2 bg-white mt-4 transition-base rounded-custom d-block overflow-hidden p-5'
                >
                  <div className='position-relative connected-app-content'>
                    <div className='integration-logo bg-white rounded-circle p-2 d-inline-block'>
                      <img
                        src='assets/img/pega-logo.svg'
                        width='40'
                        alt='integration'
                        className='img-fluid'
                      />
                    </div>
                    <h5>Pega</h5>
                    <p className='mb-0 text-muted'>
                    Experience the future of AI-driven automation with our platform's seamless integration into Pega's intelligent automation software. By merging the power of Pega's industry-leading capabilities with our advanced AI solutions, organizations can revolutionize their operations and drive unparalleled efficiency. From intelligent decision-making to predictive analytics, our integration with Pega empowers businesses to streamline processes, enhance customer experiences, and achieve transformative results. Embrace the synergy of AI and automation within the robust Pega platform, paving the way for continuous innovation and success.
                    </p>
                  </div>
                </Link>
              </div>
              <div className='col-lg-4 col-md-6'>
                <Link
                  to='/integration-single'
                  className='position-relative text-decoration-none connected-app-single bg-white border border-2 bg-white mt-4 transition-base rounded-custom d-block overflow-hidden p-5'
                >
                  <div className='position-relative connected-app-content'>
                    <div className='integration-logo bg-white rounded-circle p-2 d-inline-block'>
                      <img
                        src='https://upload.wikimedia.org/wikipedia/commons/0/0e/Microsoft_365_%282022%29.svg'
                        width='40'
                        alt='integration'
                        className='img-fluid'
                      />
                    </div>
                    <h5>Microsoft 365</h5>
                    <p className='mb-0 text-muted'>
                    "Unleash the full potential of AI within your Microsoft 365 ecosystem with our seamless integration. By leveraging the power of Microsoft's productivity suite and our advanced AI solutions, users can optimize workflows, enhance collaboration, and drive productivity to new heights. From intelligent document processing to personalized insights, our integration with Microsoft 365 offers a unified experience for unlocking AI-driven innovation. Embrace the synergy of AI and productivity within the familiar Microsoft environment, empowering teams to achieve more and innovate with confidence.
                    </p>
                  </div>
                </Link>
              </div>
              {/* <div className='col-lg-4 col-md-6'>
                <Link
                  to='/integration-single'
                  className='position-relative text-decoration-none connected-app-single bg-white border border-2 bg-white mt-4 transition-base rounded-custom d-block overflow-hidden p-5'
                >
                  <div className='position-relative connected-app-content'>
                    <div className='integration-logo bg-white rounded-circle p-2 d-inline-block'>
                      <img
                        // src='https://en.wikipedia.org/wiki/File:Google_Drive_logo_(2014-2020).svg'
                        scr='assets/img/box-seeklogo.png'
                        // src='https://upload.wikimedia.org/wikipedia/commons/0/0e/Microsoft_365_%282022%29.svg'
                        width='40'
                        alt='integration'
                        className='img-fluid'
                      />
                    </div>
                    <h5>Box</h5>
                    <p className='mb-0 text-muted'>
                      Elevate your content management experience with our AI platform seamlessly integrated into Box. By combining Box's secure and scalable content management platform with our advanced AI solutions, users can unlock powerful insights and streamline workflows like never before. From intelligent content classification to predictive analytics, our integration with Box empowers organizations to drive efficiency and innovation in their digital transformation journey. Embrace the synergy of AI and content management within the trusted Box environment, revolutionizing the way you work with your content.
                    </p>
                  </div>
                  <span className='badge position-absolute integration-badge bg-warning-soft px-3 py-2 text-warning'>
                    Popular
                  </span>
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IntegrationThree;
