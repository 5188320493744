import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import PageMeta from '../../components/common/PageMeta';

import RequestForDemo from '../../components/others/RequestForDemo';
import PromoThree from '../../components/promo/PromoThree';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import FeatureImgEight from '../../components/features/FeatureImgEight';
import FeatureImgContentThree from '../../components/feature-img-content/FeatureImgContentThree';
import FeatureImgContentTwo from '../../components/feature-img-content/FeatureImgContentTwo';
import UseCaseFeatureImgEight from './UseCaseFeatureImgEight';


let PromoThreeData = {
  title: "This is the Title",
  description: "This is the Description",
  amountOne: "150",
  headerOne:"Completed Deployments",
  textOne: "This is a great accomplishment that needs to be fully appreciated",
  amountTwo: "2k",
  headerTwo:"Happy Clients",
  textTwo: "Energistically impact synergistic convergence for leadership..",
  amountThree: "90%",
  headerThree:"Models Running",
  textThree: "This is a great accomplishment that needs to be fully appreciated"
}

let FeatureImgContentThreeData = {
  header: "Easily Change and Manipulate stuff",
  headerParagraph: "Conveniently drive stand-alone total linkage for process-centric content. Enthusiastically administrate robust initiatives quickly unleash collaborative with client-focused",
  iconOne:"fal fa-comments-alt fa-2x text-primary mb-4",
  bulletOne:"Real-time Feedback",
  bulletOneParagraph:"Objectively exceptional via customized intellectual.",
  iconTwo: "fal fa-headset fa-2x text-primary mb-4",
  bulletTwo: "Live Changes Stuff",
  bulletTwoParagraph: "Interactively integrate extensible directed communities.",
  image: "BorrowedImages/technology-icon-768x636.png"
}

let FeatureImgContentTwoData = {
  sectionTitle:"Best, Not Just Next Best",
  sectionDescription: "Certo, with its robust foundational AI and machine learning models, serves as a powerful ally for enterprises seeking to analyze alternatives with precision and efficiency. Through advanced algorithms and data analytics, Certo can evaluate multiple scenarios, providing valuable insights into the potential outcomes and impacts of various alternatives. Here's how Certo enables enterprises to analyze alternatives effectively",
  iconMain: "fal fa-sliders fa-2x text-white",
  //image: "BorrowedImages/dowhy-features.png",
  image: "BorrowedImages/Marketing.svg",
  items: [
    {
      icon:"fal fa fa-sitemap fa-2x  text-primary mb-4",
      bullet: "Scenario Modeling",
      bulletParagraph: "Certo allows enterprises to create and simulate different scenarios, modeling the potential outcomes of various alternatives based on historical data and real-time inputs."
    },
    {
      icon:"fal fa fa-poo-storm fa-2x  text-primary mb-4",
      bullet: "Real-Time Adjustments",
      bulletParagraph: "Certo's adaptive algorithms enable real-time adjustments to alternative analysis, allowing enterprises to factor in changing conditions and make agile decisions as the business landscape evolves."
    },
    {
      icon:"fal fa fa-clipboard fa-2x  text-primary mb-4",
      bullet: "Resource Allocation Optimization",
      bulletParagraph: "By analyzing alternatives, Certo assists in optimizing resource allocation, ensuring that enterprises allocate time, budget, and manpower efficiently for the chosen alternative."
    }
  ]
}


let FeatureImgEightData = {
  sectionTitle:"Success Through Experience",
  sectionDescription: "Solving complex AI/ML problems is difficult without in the trenches experience. Our team has years of real-world experience solving enterprise level problems using the Certo Infrastructure. With end-to-end workflows covering intelligent document processing, data engineering, data extraction, model training, testing and orchestration capabilities all the way to production API endpoint deployment, we provide teams with:",
  iconOne:"fal fa-user fa-2x text-primary",
  bulletOne:"Customer analysis",
  bulletOneParagraph:"Objectively exceptional via customized capital expertise.",
  iconTwo: "fal fa-watch fa-2x text-primary",
  bulletTwo: "Real time metrics Stuff",
  bulletTwoParagraph: "Interactively integrate extensible users resource..",
  image: "dashboard-img-4.png"
}

let dataEight = {
  header: "Customer Intelligence",
  tagLine:"Churn Analysis",
  image1: "BorrowedImages/CausalEditing.mp4",
  image: "BorrowedImages/CausalEditing.mp4",
  image2: "BorrowedImages/CausalEditing.mp4",
  headerText:  "Drive interpretable, real-time analysis of customer-level characteristics.  Deployed at scale on billions of underlying customer data points.",
  iconLeft: "fal fa-user fa-2x text-primary",
  iconRight: "fal fa-watch fa-2x text-primary",
  leftSubHeader:"What-if",
  leftSubText:"luyjw5eaery Objectively exceptional via customized intellectual.",
  rightSubHeader:"Real time updates",
  rightSubText:"Real-time transcription",
  linkText:"Customers need more than simple next-best options",
  linkDescription: "They want to know their opinions are valued and thier options validated. Certo's models are capable of teasing signal from noise in ways that elevate your cutomer intelligence enabling new modes of engagement and opportunities for revenue capture.",

}


const AlternativesUseCase  = () => {
  return (
    <Layout>
      <PageMeta title="Analysis of Alternatives" />
      <Navbar navDark />
      <PageHeader
        title="Analysis of Alternatives"
        desc="Certo's AI and machine learning are instrumental in the analysis of alternatives, providing a sophisticated approach to decision-making processes. These technologies excel in processing vast datasets, identifying patterns, and evaluating multiple variables simultaneously. In scenarios where various alternatives must be considered, AI can analyze historical data, predict potential outcomes, and offer insights to inform decision-makers about the most optimal choices. The ability of machine learning algorithms to adapt and improve over time enhances the accuracy and efficiency of the analysis, providing a dynamic and data-driven foundation for evaluating alternatives across diverse domains such as finance, strategic planning, and resource allocation."
      />
      
      <FeatureImgContentTwo data= {FeatureImgContentTwoData}/>
      <UseCaseFeatureImgEight data={dataEight} paddingTop={"paddingTop"} />
      
      {/*}
      <FeatureImgEight paddingTop data={FeatureImgEightData}/>
      <FeatureImgContentThree data= {FeatureImgContentThreeData}/>
      <PromoThree hasBg data={PromoThreeData}/>
  */}
      <RequestForDemo />
      <FooterOne footerLight />
    </Layout>
  );
};

export default AlternativesUseCase ;



