import React from "react";
import { Link } from "react-router-dom";

const OffCanvasMenu = () => {
  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasWithBackdrop"
      >
        <div className="offcanvas-header d-flex align-items-center mt-4">
          <Link
            to="/"
            className="d-flex align-items-center mb-md-0 text-decoration-none"
          >
            <img
              src="assets/img/BorrowedImages/CertoAILogo.png"
              alt="logo"
              className="certo-logo"
              width={25}
              height={25}
            />

            <span className={"test-dark"} width={113} height={36}>
              Certo<span className="ai">/ai </span>
            </span>
          </Link>
          <button
            type="button"
            className="close-btn text-danger"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <i className="far fa-close"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          <ul className="nav col-12 col-md-auto justify-content-center main-menu">
            <li>
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Platform
              </a>
              <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                <div className="dropdown-grid rounded-custom width-half">
                  <div className="dropdown-grid-item">
                    <h6 className="drop-heading">Foundations</h6>
                    <Link to="/models" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        <i className="far fa-server"></i>
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">Models</div>
                        <p>
                          Underlying <strong>AI Toolkit</strong>
                        </p>
                      </div>
                    </Link>
                    <Link to="/infrastructure" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        <i class="fa-solid fa-gear"></i>
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">Infrastructure</div>
                        <p>
                          Optimized <strong>Infrastructure</strong>
                        </p>
                      </div>
                    </Link>
                    <Link to="/planning" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        <i class="fa-solid fa-code-fork"></i>
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">Process</div>
                        <p>
                          Plan, <strong>Act, </strong> Re-act
                        </p>
                      </div>
                    </Link>
                    <Link to="/augmentation" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        <i class="fa-duotone fa-gears"></i>
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">Human Centric AI</div>
                        <p>
                          <strong>People</strong> Applying Intelligence
                        </p>
                      </div>
                    </Link>
                    {/*
                        <Link to="/home-soft-app" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            <i class="fa-solid fa-square-poll-vertical"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">
                              Statistical
                            </div>
                            <p>
                              Classical <strong>Theoretical Guarantes</strong>
                            </p>
                          </div>
                        </Link>
                        <Link to="/toolkit" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            <i class="fa-solid fa-chart-line"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Time Series</div>
                            <p>
                              <strong>Predictive</strong>&lrm;
                            </p>
                          </div>
                        </Link>
  
                        <Link
                          to="/home-data-analysis"
                          className="dropdown-link"
                        >
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                          <i class="fa-regular fa-chart-bar"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Conformal</div>
                            <p>
                              Cutting Edge &amp; <strong>Uncertainty</strong> Quantization&lrm;
                            </p>
                          </div>
                        </Link>
                        <Link to="/home-app-two" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            <i class="fa-solid fa-diagram-project"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Tree</div>
                            <p>
                              App and <strong>Software</strong> Landing
                            </p>
                          </div>
                        </Link>
                        */}
                    <h6 className="drop-heading">Data</h6>
                    <Link to="/curation" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        <i class="fa-solid fa-pen-to-square"></i>
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">Dataset Curation</div>
                        <p>
                          <strong>Bespoke</strong> Datasets from Your Data
                        </p>
                      </div>
                    </Link>
                    <Link to="/data-programing" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        <i class="fa-solid fa-code"></i>
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">Data Engineering</div>
                        <p>
                          <strong>Algorithims and Hueristics</strong>
                        </p>
                      </div>
                    </Link>
                    <Link to="/privacy" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        <i class="fa-solid fa-user-secret"></i>
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">Privacy</div>
                        <p>
                          <strong>Cryptographically</strong> Secured
                        </p>
                      </div>
                    </Link>
                    <Link to="/mlops" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        <i class="fa-solid fa-screwdriver-wrench"></i>
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">MLOps</div>
                        <p>
                          <strong>Infrastructure</strong> and{" "}
                          <strong>Governance</strong>
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </li>

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Capabilities
              </a>
              <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                <div className="dropdown-grid rounded-custom width-half">
                  <div className="dropdown-grid-item">
                    <h6 className="drop-heading">Applications</h6>
                    <Link to="/documents" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        <i class="fa-solid fa-pen-to-square"></i>
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">Document Intelligence</div>
                        <p>
                          <strong>Information</strong> Extraction
                        </p>
                      </div>
                    </Link>
                    <Link to="/customer" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        <i class="fa-solid fa-pen-to-square"></i>
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">Customer Intelligence</div>
                        <p>
                          <strong>Customized</strong> interaction plans
                        </p>
                      </div>
                    </Link>
                    <Link to="/process-automation" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        <i class="fa-solid fa-user-secret"></i>
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">Process Automation</div>
                        <p>
                          Turn <strong>bottlenecks</strong> into profit centers
                        </p>
                      </div>
                    </Link>
                    <Link to="/voice-to-text" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        <i class="fa-solid fa-user-secret"></i>
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">Visual Understanding</div>
                        <p>
                          Extract <strong>Information</strong> from images
                        </p>
                      </div>
                    </Link>

                    <Link to="/loan-analytics" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        <i class="fa-solid fa-code"></i>
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">Predictive Analytics</div>
                        <p>
                          <strong>Predictive</strong> Simulation and Forecasts
                        </p>
                      </div>
                    </Link>

                    <Link to="/voice-to-text" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        <i class="fa-solid fa-globe"></i>
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">Voice to Text</div>
                        <p>
                          <strong>Entity and Sentiment</strong> analysis
                        </p>
                      </div>
                    </Link>
                    <Link to="/decision-support" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        <i class="fa-solid fa-layer-group"></i>
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">Decision Support</div>
                        <p>
                          <strong>Rigorous</strong> statistical decision support
                        </p>
                      </div>
                    </Link>
                    {/* <Link to="/counterfactual" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        <i class="fa-solid fa-user-secret"></i>
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">What If?</div>
                        <p>
                          <strong>Simulation</strong> based AI Analysis
                        </p>
                      </div>
                    </Link>

                    <Link to="/alternatives" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        <i class="fa-regular fa-lightbulb"></i>
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">
                          Analysis of Alternatives
                        </div>
                        <p>
                          <strong>Quantify</strong> impact of alternatives
                        </p>
                      </div>
                    </Link> */}
                  </div>
                  {/*
                      <div className="dropdown-grid-item radius-left-side bg-light">
                        <h6 className="drop-heading">&nbsp;</h6>
                        <Link to="/financial-services" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            <i class="fa-solid fa-landmark"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Financial Services</div>
                            <p>
                              Banks, Credit Uniions, Fintechs
                            </p>
                          </div>
                        </Link>
                        <Link to="/loan-analytics" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                          <i class="fa-solid fa-landmark-dome"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Government</div>
                            <p>
                              Improving Health <strong>Outcomes</strong>
                            </p>
                          </div>
                        </Link>
                        <Link to="/loan-analytics" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                          <i class="fa-solid fa-user-shield"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Insurance</div>
                            <p>
                              Improving Insurance <strong>Outcomes</strong>
                            </p>
                          </div>
                        </Link>
                        <Link to="/loan-analytics" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                          <i class="fa-solid fa-dna"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Healthcare</div>
                            <p>
                              Improving Health <strong>Outcomes</strong>
                            </p>
                          </div>
                        </Link>
                        <Link to="/loan-analytics" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                          <i class="fa-solid fa-tag"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Retail</div>
                            <p>
                              <strong>Match</strong> concepts for down stream evaluation
                            </p>
                          </div>
                        </Link>
                        <Link to="/loan-analytics" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                          <i class="fa-solid fa-truck-pickup"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Supply Chain</div>
                            <p>
                              <strong>Match</strong> concepts for down stream evaluation
                            </p>
                          </div>
                        </Link>
                  
                      
                  </div>*/}
                </div>
              </div>
            </li>

            <li>
              <Link to="/services" className="nav-link">
                Services
              </Link>
            </li>

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Company
              </a>
              <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                <div className="dropdown-grid rounded-custom width-full">
                  <div className="dropdown-grid-item">
                    {/* <h6 className="drop-heading">Useful Links</h6> */}
                    <Link to="/about-us" className="dropdown-link px-0">
                      <span className="me-2">
                        <i className="far fa-fingerprint"></i>
                      </span>
                      <div className="drop-title">About Us</div>
                    </Link>
                    <Link to="/contact-us" className="dropdown-link px-0">
                      <span className="me-2">
                        <i className="far fa-address-book"></i>
                      </span>
                      <div className="drop-title">Contact Us</div>
                    </Link>

                    {/* <Link to="/blogs" className="dropdown-link">
                      <span className="me-2">
                        <i className="far fa-th-large"></i>
                      </span>
                      <div className="drop-title">Our Latest News</div>
                    </Link> */}

                    {/* <Link to="/career" className="dropdown-link px-0">
                      <span className="me-2">
                        <i className="far fa-graduation-cap"></i>
                      </span>
                      <div className="drop-title">Career</div>
                    </Link> */}

                    <Link to="/integrations" className="dropdown-link">
                      <span className="me-2">
                        <i className="far fa-rocket-launch"></i>
                      </span>
                      <div className="drop-title">Integrations</div>
                    </Link>
                    <Link to="/request-demo" className="dropdown-link">
                      <span className="me-2">
                        <i className="far fa-laptop-code"></i>
                      </span>
                      <div className="drop-title">Request for Demo</div>
                    </Link>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div className="action-btns mt-4 ps-3">
            {/* <Link to="/login" className="btn btn-outline-primary me-2">
              Sign In
            </Link> */}
            <Link to="/request-for-demo" className="btn btn-primary">
              Get Started
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default OffCanvasMenu;
