import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import PageMeta from '../../components/common/PageMeta';
import Navbar from '../../layout/Header/Navbar';
import CtaSubscribe from '../../components/cta/CtaSubscribe';
import IntegrationThree from '../../components/integration/IntegrationThree';
import RequestForDemo from '../../components/others/RequestForDemo';
import Promo from '../../components/promo/Promo';
import FooterOne from '../../layout/Footer/FooterOne';
import Layout from '../../layout/Layout';

const Integrations = () => {
  return (
    <Layout>
      <PageMeta title="Integrations - Software &amp; IT Solutions HTML Templat" />
      <Navbar navDark />
      <PageHeader
        title="Our Featured Integrations"
        desc="Experience the power of seamless integrations within the Certo platform. By connecting with leading cloud services, CRM systems, productivity suites, and content management platforms, our AI solution offers unparalleled versatility and efficiency. Unlock the full potential of your data and workflows as our platform seamlessly integrates with your existing tools and processes. From enhancing customer experiences to optimizing operational efficiency, our integrations pave the way for transformative outcomes. Explore the possibilities and accelerate your AI initiatives with our integrated solution."
      />
      <IntegrationThree />
      {/*<Promo bgWhite />*/}
      <RequestForDemo/>
      <FooterOne footerLight />
    </Layout>
  );
};

export default Integrations;
