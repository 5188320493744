import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../common/SectionTitle';

const FeatureTwo = ({ cardDark, data }) => {
  return (
    <>
      <section
        className={`feature-section ptb-120 ${
          cardDark ? 'bg-dark' : 'bg-light'
        }`}
      >
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-10'>
              {cardDark ? (
                <SectionTitle
                  subtitle={data.sectionSubTitle}
                  title={data.sectionTitle}
                  description={data.sectionDescription}
                  centerAlign
                  dark
                />
              ) : (
                <SectionTitle
                  subtitle={data.sectionSubTitle}
                  title={data.sectionTitle}
                  description={data.sectionDescription}
                  centerAlign
                />
              )}
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='feature-grid'>
                <div
                  className={`shadow-sm highlight-card rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-primary-soft
                      mb-32
                    '
                  >
                    <i className={data.cardOneIcon}></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>{data.cardOneHeader}</h3>
                    <p>
                      {data.cardOneP1}
                    </p>
                    <p>
                      {data.cardOneP2}
                    </p>
                    <h6 className='mt-4'>Includes...</h6>
                    <ul className='list-unstyled mb-0'>
                      <li className='py-1'>
                        <i
                          className={`fad fa-check-circle me-2 ${
                            cardDark ? 'text-warning' : 'text-primary'
                          }`}
                        ></i>
                        {data.cardOneLI1}
                      </li>
                      <li className='py-1'>
                        <i
                          className={`fad fa-check-circle me-2 ${
                            cardDark ? 'text-warning' : 'text-primary'
                          }`}
                        ></i>
                        {data.cardOneLI2}
                      </li>
                      <li className='py-1'>
                        <i
                          className={`fad fa-check-circle me-2 ${
                            cardDark ? 'text-warning' : 'text-primary'
                          }`}
                        ></i>
                        {data.cardOneLI3}
                      </li>

                      <li className='py-1'>
                        <i
                          className={`fad fa-check-circle me-2 ${
                            cardDark ? 'text-warning' : 'text-primary'
                          }`}
                        ></i>
                        {data.cardOneLI4}
                      </li>
                      <li className='py-1'>
                        <i
                          className={`fad fa-check-circle me-2 ${
                            cardDark ? 'text-warning' : 'text-primary'
                          }`}
                        ></i>
                        {data.cardOneLI5}
                      </li>
                    </ul>
                  </div>
                  <Link
                    to={`/${data.cardOneLink}`}
                    className='link-with-icon text-decoration-none mt-3'
                  >
                    {data.cardOneLinkText}<i className='far fa-arrow-right'></i>
                  </Link>
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-success-soft
                      mb-32
                    '
                  >
                  <i className={data.cardTwoIcon}></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>{data.cardTwoHeader}</h3>
                    <p className='mb-0'>
                      {data.cardTwoText}
                    </p>
                  </div>
                  <Link
                    to={`/${data.cardTwoLink}`}
                    className='link-with-icon text-decoration-none mt-3'
                  >
                    {data.cardTwoLinkText} <i className='far fa-arrow-right'></i>
                  </Link>
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-danger-soft
                      mb-32
                    '
                  >
                    <i className={data.cardThreeIcon}></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>{data.cardThreeHeader}</h3>
                    <p className='mb-0'>
                      {data.cardThreeText}
                    </p>
                  </div>
                  <Link
                    to={`/${data.cardThreeLink}`}
                    className='link-with-icon text-decoration-none mt-3'
                  >
                    {data.cardThreeLinkText} <i className='far fa-arrow-right'></i>
                  </Link>
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-dark-soft
                      mb-32
                    '
                  >
                    <i className={data.cardFourIcon}></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>{data.cardFourHeader}</h3>
                    <p className='mb-0'>
                      {data.cardFourText}
                    </p>
                  </div>
                  <Link
                    to={`/${data.cardFourLink}`}
                    className='link-with-icon text-decoration-none mt-3'
                  >
                    {data.cardFourLinkText}  <i className='far fa-arrow-right'></i>
                  </Link>
                </div>
                <div
                  className={`feature-card shadow-sm rounded-custom p-5 ${
                    cardDark
                      ? 'bg-custom-light promo-border-hover border border-2 border-light text-white'
                      : 'bg-white'
                  }`}
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <div
                    className='
                      icon-box
                      d-inline-block
                      rounded-circle
                      bg-warning-soft
                      mb-32
                    '
                  >
                    <i className={data.cardFiveIcon}></i>
                  </div>
                  <div className='feature-content'>
                    <h3 className='h5'>{data.cardFiveHeader}</h3>
                    <p className='mb-0'>
                      {data.cardFiveText}
                    </p>
                  </div>
                  <Link
                    to={`/${data.cardFiveLink}`}
                    className='link-with-icon text-decoration-none mt-3'
                  >
                    {data.cardFiveLinkText}  <i className='far fa-arrow-right'></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureTwo;
