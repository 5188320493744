import React from 'react';
import HeroTitle from '../../components/common/HeroTitle';

const Infrastructure = () => {
  return (
    <>
      <section
        className='hero-section ptb-120 bg-dark text-white'
        style={{
          background:
            "url('assets/img/shape/dot-dot-wave-shape.svg')no-repeat bottom left",
        }}
      >
        <div className='container'>
          <div className='row align-items-center justify-content-lg-between'>
            <div className='col-xl-5 col-lg-5'>
              <div
                className='hero-content-wrap text-center text-xl-start text-lg-start mt-5 mt-lg-0 mt-xl-0'
                data-aos='fade-right'
              >
                <HeroTitle
                  title='Infrastructure'
                  desc='The Certo infrastructure is a sophisticated and well-integrated ecosystem designed to support the development, deployment, and management of artificial intelligence applications.  Our infrastructure not only accelerates the development and deployment of AI applications but also ensures scalability, reliability, and adherence to ethical and governance standards. The integration of these components creates a comprehensive ecosystem that empowers organizations to harness the full potential of artificial intelligence.'
                />
                <div className='hero-subscribe-form-wrap pt-4 position-relative m-auto m-xl-0 d-none d-md-block d-lg-block d-xl-block'>
                  <form
                    id='subscribe-form'
                    name='email-form'
                    className='hero-subscribe-form d-block d-lg-flex d-md-flex'
                  >
                    {/*
                    <input
                      type='submit'
                      value='Details'
                      data-wait='Please wait...'
                      className='btn btn-primary mt-3 mt-lg-0 mt-md-0'
                    />
      */}
                  </form>
                </div>
              </div>
            </div>
            <div className='col-xl-6 col-lg-6 mt-5'>
              <div
                className='hero-img-wrap position-relative'
                data-aos='fade-left'
              >
                <div className='hero-screen-wrap'>
                  <div className='mac-screen'>
                    <img
                      src='assets/img/BorrowedImages/Infrastructure-2.png'
                      alt='hero '
                      className='position-relative img-fluid rounded-custom'
                      height={500}
                      width={800}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Infrastructure;
