import React from 'react';
import HeroTitle from '../../components/common/HeroTitle';
import Lottie from 'react-lottie';
import animationData from '../lotties/Animation2';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};



const Foundational = () => {
  return (
    <>
      <section
        className='hero-section ptb-120 bg-dark text-white'
        style={{
          background:
            "url('assets/img/shape/dot-dot-wave-shape.svg')no-repeat bottom left",
        }}
      >
        <div className='container'>
          <div className='row align-items-center justify-content-lg-between'>
            <div className='col-xl-5 col-lg-5'>
              <div
                className='hero-content-wrap text-center text-xl-start text-lg-start mt-5 mt-lg-0 mt-xl-0'
                data-aos='fade-right'
              >
                <HeroTitle
                  title='Foundational'
                  desc='Enhancing human potential to achieve exceptional results. Foundational models in AI serve as the bedrock of diverse applications, representing comprehensive frameworks that encapsulate fundamental principles and capabilities. Certo pre-trains on extensive datasets, providing a solid starting point for various AI tasks, enabling developers to build upon and fine-tune them for specific applications, fostering efficiency and accelerating the development of advanced AI solutions.'
                />
                

              </div>
            </div>
            <div className='col-xl-6 col-lg-6 mt-5'>
              <div
                className='hero-img-wrap position-relative'
                data-aos='fade-left'
              >
                <div className='hero-screen-wrap'>
                  <div className='mac-screen'>
                  <Lottie 
                options={defaultOptions}
                  height={400}
                  width={400}
                />
                    {/*<img
                      src='assets/img/ai-generated/AI-document-process-removebg-preview.png'
                      alt='hero '
                      className='position-relative img-fluid rounded-custom'
      />*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Foundational;
