import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import PageMeta from '../../components/common/PageMeta';

import RequestForDemo from '../../components/others/RequestForDemo';
import PromoThree from '../../components/promo/PromoThree';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

import FeatureImgContentTwo from '../../components/feature-img-content/FeatureImgContentTwo';
import UseCaseFeatureImgEight from './UseCaseFeatureImgEight';




let FeatureImgContentTwoData = {
  sectionTitle:"Unlocking Insights, Navigating Visual Data with Precision",
  sectionDescription: "Visual understanding transforms the process of extracting insights from visual content, empowering organizations to harness valuable information and navigate complex visual data sources with unparalleled efficiency and precision",
  iconMain: "fal fa-file-text  fa-2x text-white",
  useViedo: false,
  image: "BorrowedImages/VisualUnderstanding.svg",
  
  items: [
    {
      icon:"fal fa fa-building fa-2x  text-primary mb-4",
      bullet: "Enhanced Decision Making",
      bulletParagraph: "Visual understanding on Certo empowers enterprises to swiftly interpret complex data presented visually, enabling more informed decision-making processes."
    },
    {
      icon:"fal fa fa-code fa-2x  text-primary mb-4",
      bullet: "Improved Efficiency",
      bulletParagraph: "By automating the analysis of visual data such as graphs, charts, and documents, Certo accelerates workflows within enterprises, saving time and resources."
    },
    {
      icon:"fal fa fa-thermometer-quarter fa-2x  text-primary mb-4",
      bullet: "Advanced Risk Management",
      bulletParagraph: "Certo's visual understanding capabilities enable proactive identification of anomalies and patterns in data, enhancing risk detection and mitigation strategies for institutions."
    }
  ]
}

let dataEight = {
  header: "Visual Infrormation Extraction",
  tagLine:"Visual Analysis",
  image: "VisualUnderstanding.png",
  image2: "BorrowedImages/CausalEditing.mp4",
  majorIngredients: "Vision Transformer, NER, Generative Guardrails",
  minorIngredients: "NLP, QA",
  useImage: true,
  headerText:  "Drive interpretable, real-time analysis of loan-level characteristics.  Deployed at scale on billions of underlying loan data points.",
  iconLeft: "fal fa-user fa-2x text-primary",
  iconRight: "fal fa-watch fa-2x text-primary",
  leftSubHeader:"What-if",
  leftSubText:"luyjw5eaery Objectively exceptional via customized intellectual.",
  rightSubHeader:"Real time updates",
  rightSubText:"Real-time transcription",
  linkText:"The Power of Visual Document Understanding in AI",
  linkDescription: " AI systems can analyze various types of documents, including images, scanned texts, PDFs, and more, to extract meaningful insights. This capability enables applications such as automated data extraction, content categorization, sentiment analysis, and even handwriting recognition. By bridging the gap between human perception and machine intelligence, visual document understanding empowers businesses and organizations to streamline document management processes, enhance decision-making, and unlock valuable knowledge hidden within unstructured data.",
}



let PromoThreeData = {
  title: "Immediate Gains in Productivity",
  description: "With Visual Understanding, organizations can harness the power of their unstructured data, making informed decisions faster and more efficiently.",
  amountOne: "100X",
  headerOne:"Through put",
  textOne: "In documents reviewed",
  amountTwo: "35% ",
  headerTwo:"Reduction",
  textTwo: "In manual document processing time",
  amountThree: "50-90%",
  headerThree:"Reduction in Error",
  textThree: "After applicaiton of statistical methods"
}


const VisualUnderstandingUseCase  = () => {
  return (
    <Layout>
      <PageMeta title="Visual Understanding Use Case" />
      <Navbar navDark />
      <PageHeader
        title="Visual Understanding"
        desc="Certo leverages visual understanding to streamline operations and enhance decision-making processes. By integrating advanced computer vision algorithms, Certo can analyze vast amounts of data presented in visual formats such as graphs, charts, and documents. This capability enables financial institutions to quickly extract valuable insights from complex visuals, ranging from analyzing market trends to detecting anomalies in financial statements. With Certo's visual understanding capabilities, enterprises can make more informed decisions, mitigate risks, and optimize their strategies with greater efficiency and accuracy."
      />
      <UseCaseFeatureImgEight data={dataEight} paddingTop={"paddingTop"} />
      <FeatureImgContentTwo data= {FeatureImgContentTwoData}/>

      <PromoThree hasBg data={PromoThreeData}/>
      <RequestForDemo />
      <FooterOne footerLight />
    </Layout>
  );
};


export default VisualUnderstandingUseCase;
