import React from "react";
import PageMeta from "../components/common/PageMeta";

import FooterOne from "../layout/Footer/FooterOne";
import Navbar from "../layout/Header/Navbar";
import Layout from "../layout/Layout";






import FeatureImg from '../components/features/FeatureImg';

import FeatureImgThree from '../components/features/FeatureImgThree';
import FeatureImgTwo from '../components/features/FeatureImgTwo';
import FeatureImgFive from '../components/features/FeatureImgFive';
import FeatureImgFour from '../components/features/FeatureImgFour';
import FeatureImgSix from '../components/features/FeatureImgSix';
import FeatureImgSeven from '../components/features/FeatureImgSeven';
import FeatureImgEight from '../components/features/FeatureImgEight';

import FeatureOne from '../components/features/FeatureOne';
import FeatureTwo from '../components/features/FeatureTwo';
import FeatureFour from '../components/features/FeatureFour';
import FeatureFive from '../components/features/FeatureFive';

const FeaturesDemo = () => {
  return (
    <Layout>
      <PageMeta title="Featrues Demo" />
      <Navbar />

        <FeatureOne/>
        <FeatureTwo/>
        <FeatureFour/>
        <FeatureFive/>

      <FeatureImg />
      <FeatureTwo />
      <FeatureImgThree />
      <FeatureImgTwo />
      <FeatureImgFive />
      <FeatureImgFour />
      <FeatureImgSix />
      <FeatureImgSeven />
      <FeatureImgEight />
      <FooterOne footerLight />
    </Layout>
  );
};

export default FeaturesDemo;
