import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import PageMeta from '../../components/common/PageMeta';

import RequestForDemo from '../../components/others/RequestForDemo';
import PromoThree from '../../components/promo/PromoThree';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import FeatureImgEight from '../../components/features/FeatureImgEight';
import FeatureImgContentThree from '../../components/feature-img-content/FeatureImgContentThree';
import FeatureImgContentTwo from '../../components/feature-img-content/FeatureImgContentTwo';

import UseCaseFeatureImgEight from './UseCaseFeatureImgEight';


let PromoThreeData = {
  title: "This is the Title",
  description: "This is the Description",
  amountOne: "Dozens",
  headerOne:"Completed Deployments",
  textOne: "Both on prem, single and multi-cloud",
  amountTwo: "2k",
  headerTwo:"Happy Clients",
  textTwo: "Energistically impact synergistic convergence for leadership..",
  amountThree: "90%",
  headerThree:"Models Running",
  textThree: "This is a great accomplishment that needs to be fully appreciated"
}

let FeatureImgContentThreeData = {
  header: "Easily Change and Manipulate stuff",
  headerParagraph: "Conveniently drive stand-alone total linkage for process-centric content. Enthusiastically administrate robust initiatives quickly unleash collaborative with client-focused",
  iconOne:"fal fa-comments-alt fa-2x text-primary mb-4",
  bulletOne:"Real-time Feedback",
  bulletOneParagraph:"Objectively exceptional via customized intellectual.",
  iconTwo: "fal fa-headset fa-2x text-primary mb-4",
  bulletTwo: "Live Changes Stuff",
  bulletTwoParagraph: "Interactively integrate extensible directed communities.",
  image: "BorrowedImages/technology-icon-768x636.png"
}

let FeatureImgContentTwoData = {
  sectionTitle:"Certo Revolutionizes Enterprise Decision-Making",
  sectionDescription: "Harness the transformative potential of artificial intelligence with Certo, an advanced platform designed to provide unparalleled decision support for enterprises. By leveraging cutting-edge AI algorithms and data analytics, Certo empowers organizations to make strategic, informed choices across diverse domains, optimizing efficiency, mitigating risks, and driving sustainable growth.",
  iconMain: "fal fa-gears fa-2x text-white",
  iconOne:"fad fa-bezier-curve fa-2x text-primary",
  bulletOne:"Strategic Decision Support",
  bulletOneParagraph:"",
  iconTwo: "fad fa-code fa-2x text-primary",
  bulletTwo: "Operational Efficiency Enhancement",
  bulletTwoParagraph: "",
  // image: "BorrowedImages/Marketing.png",
  image: "BorrowedImages/AI4BusinessApp.png",
  items: [
    {
      icon:"fal fa fa-sliders fa-2x  text-primary mb-4",
      bullet: "Strategic Decision Support",
      bulletParagraph: "Certo's AI capabilities offer executives and decision-makers strategic insights, enabling more informed choices for organizational development and market positioning."
    },
    {
      icon:"fal fa fa-gear fa-2x  text-primary mb-4",
      bullet: "Operational Efficiency Enhancement",
      bulletParagraph: "Streamline day-to-day operations by leveraging AI-driven decision support, optimizing workflows, and improving resource allocation for increased efficiency."
    },
    {
      icon:"fal fa fa-book fa-2x  text-primary mb-4",
      bullet: "Data-Driven Innovation",
      bulletParagraph: "Foster a culture of innovation with Certo's data-driven decision support, identifying opportunities for product development, process optimization, and market expansion."
    }
  ]
}

let FeatureImgContentTwoDataWhatIf = {
  sectionTitle:"Advanced Causal AI",
  sectionDescription: "Certo's Causal AI transcends traditional analytics by unveiling the cause-and-effect relationships within complex data sets. This advanced technology empowers organizations to make more informed decisions, enabling them to identify root causes, predict outcomes, optimize processes, and proactively mitigate risks. Causal AI, through platforms like Certo, serves as a transformative force, providing a profound understanding of the intricate connections between variables and fostering strategic, data-driven decision-making for sustained success.",
  iconMain: "fal fa-shield-check fa-2x text-white",
  image: "BorrowedImages/CounterFactualLoan.gif",
  items: [
    {
      icon:"fal fa fa-arrow-up-from-bracket fa-2x  text-primary mb-4",
      bullet: "Root Cause Identification",
      bulletParagraph: "Certo's causal analysis capabilities delve deep into data, uncovering root causes behind complex issues or challenges, providing clarity for targeted solutions."
    },
    {
      icon:"fal fa fa-pen-to-square fa-2x  text-primary mb-4",
      bullet: "Impact Assessment",
      bulletParagraph: "Assess the potential impact of various factors on outcomes, enabling enterprises to anticipate consequences and make decisions with a comprehensive understanding of potential ramifications."
    },
    {
      icon:"fal fa fa-lock fa-2x  text-primary mb-4",
      bullet: "Risk Mitigation Through Causal Understanding",
      bulletParagraph: "Certo aids in proactive risk management by revealing causal links, allowing enterprises to mitigate risks at their source and build resilience against potential challenges."
    }
  ]
}

let FeatureImgContentTwoDataAlternativeAnalysis = {
  sectionTitle:"Best, Not Just Next Best",
  sectionDescription: "Certo, with its robust foundational AI and machine learning models, serves as a powerful ally for enterprises seeking to analyze alternatives with precision and efficiency. Through advanced algorithms and data analytics, Certo can evaluate multiple scenarios, providing valuable insights into the potential outcomes and impacts of various alternatives. Here's how Certo enables enterprises to analyze alternatives effectively",
  iconMain: "fal fa-sliders fa-2x text-white",
  //image: "BorrowedImages/dowhy-features.png",
  image: "BorrowedImages/Marketing.svg",
  items: [
    {
      icon:"fal fa fa-sitemap fa-2x  text-primary mb-4",
      bullet: "Scenario Modeling",
      bulletParagraph: "Certo allows enterprises to create and simulate different scenarios, modeling the potential outcomes of various alternatives based on historical data and real-time inputs."
    },
    {
      icon:"fal fa fa-poo-storm fa-2x  text-primary mb-4",
      bullet: "Real-Time Adjustments",
      bulletParagraph: "Certo's adaptive algorithms enable real-time adjustments to alternative analysis, allowing enterprises to factor in changing conditions and make agile decisions as the business landscape evolves."
    },
    {
      icon:"fal fa fa-clipboard fa-2x  text-primary mb-4",
      bullet: "Resource Allocation Optimization",
      bulletParagraph: "By analyzing alternatives, Certo assists in optimizing resource allocation, ensuring that enterprises allocate time, budget, and manpower efficiently for the chosen alternative."
    }
  ]
}

let FeatureImgEightData = {
  sectionTitle:"Customer-Centric Strategies",
  sectionDescription: "Tailor your approach to customer needs with AI-guided decision support, offering personalized experiences and strengthening customer relationships.",
  iconOne:"fal fa-user fa-2x text-primary",
  bulletOne:"Customer Journey Optimization",
  bulletOneParagraph:"Utilize Certo-driven decision support to analyze and enhance the entire customer journey, identifying touchpoints for improvement and ensuring a seamless and personalized experience from awareness to conversion.",
  iconTwo: "fal fa-watch fa-2x text-primary",
  bulletTwo: "Dynamic Pricing Strategies",
  bulletTwoParagraph: "Implement dynamic and customer-centric pricing models with Certo's decision support, leveraging real-time data insights to adjust pricing strategies based on customer behavior, market conditions, and competitive landscapes.",
  image: "dashboard-img-4.png"
}

let dataEight = {
  header: "Decision Support",
  tagLine:"Decision Support",
  image1: "BorrowedImages/CausalEditing.mp4",
  image: "BorrowedImages/CausalEditing.mp4",
  image2: "BorrowedImages/CausalEditing.mp4",
  headerText:  "Foster a culture of innovation with Certo's data-driven decision support, identifying opportunities for product development, process optimization, and market expansion.",
  iconLeft: "fal fa-user fa-2x text-primary",
  iconRight: "fal fa-watch fa-2x text-primary",
  leftSubHeader:"What-if",
  leftSubText:"luyjw5eaery Objectively exceptional via customized intellectual.",
  rightSubHeader:"Real time updates",
  rightSubText:"Real-time transcription",
  linkText:"Certo's Decision Support reimagines how data fuels the enterprise",
  linkDescription: "Foster a culture of innovation with Certo's data-driven decision support, identifying opportunities for product development, process optimization, and market expansion.",
}



const DecisionSupportUseCase  = () => {
  return (
    <Layout>
      <PageMeta title="DecisionSupportUseCase" />
      <Navbar navDark />
      <PageHeader
        title="Decision Support"
        desc="Certo utilizes advanced algorithms and data analytics to provide valuable insights and recommendations, aiding individuals or organizations in making informed decisions. By processing and analyzing vast datasets, AI systems can identify patterns, trends, and potential outcomes, assisting users in evaluating options and predicting the impact of different choices. This technology enhances decision-making processes across various domains, from business strategy and healthcare to finance, by offering data-driven guidance and facilitating more effective, informed choices."
      />
      <FeatureImgContentTwo data= {FeatureImgContentTwoData}/>
      {/* <FeatureImgContentTwo data= {FeatureImgContentTwoDataWhatIf}/> */}
      <FeatureImgContentTwo data= {FeatureImgContentTwoDataAlternativeAnalysis}/>      
      {/* <UseCaseFeatureImgEight data={dataEight} paddingTop={"paddingTop"} /> */}

      <RequestForDemo />
      <FooterOne footerLight />
    </Layout>
  );
};

export default DecisionSupportUseCase ;
