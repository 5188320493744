import React from 'react';
import BlogGrid from '../../components/blog/BlogGrid';
import PageHeader from '../../components/common/PageHeader';
import PageMeta from '../../components/common/PageMeta';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

const Blogs = () => {
  return (
    <Layout>
      <PageMeta title="Current Research and Resources" />
      <Navbar navDark />
      <PageHeader
        title="Current Research and Resources"
        desc="Welcome to our Current Research and Resources page, where we delve into the latest findings, studies, and valuable resources across various fields. Stay informed and engaged as we explore cutting-edge research, share insightful analyses, and provide access to valuable resources that span from science and technology to health, lifestyle, and beyond. Whether you're a curious mind or a professional seeking the latest advancements, this page is your go-to source for staying abreast of the most current and impactful developments in the world of research. Join us on this journey of knowledge discovery and exploration!"
        blogtags
      />
      <BlogGrid />
      <FooterOne
        style={{
          background:
            "url('assets/img/page-header-bg.svg')no-repeat bottom right",
        }}
      />
    </Layout>
  );
};

export default Blogs;
