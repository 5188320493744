import React from 'react';
import PageMeta from '../../components/common/PageMeta';

import Infrastructure from './Infrastructure';

import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import WorkProcessTwo from '../../components/work-process/WorkProcessTwo';


import animationData from '../lotties/security-research1';
import FeatureImgSixExtended from '../../components/features/FeatureImgSixExtended';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};



let FeatureImgSixData ={
  items: [
    {  
      icon: "fas fa-server text-white",
      bullet: "High-Performance Computing",
      bulletText: "Powerful hardware, often involving GPUs (Graphics Processing Units) or TPUs (Tensor Processing Units), accelerates the training of complex machine learning models.",
    },
    {  
      icon: "fas fa-table text-white",
      bullet: "Data Storage and Management",
      bulletText: "Robust data storage systems are essential for managing vast datasets. Technologies like data lakes and distributed databases facilitate efficient storage, retrieval, and management of diverse data types.",
    },
    {
      icon: "fas fa-microchip text-white",
      bullet: "Data Processing and ETL",
      bulletText: "Tools for preprocessing and transforming raw data into a format suitable for AI model training. This involves cleaning, normalizing, and feature engineering.",
    },
    {
      icon: "fas fa-laptop text-white",
      bullet: "Machine Learning Frameworks",
      bulletText: "Libraries and frameworks such as TensorFlow, PyTorch, and scikit-learn provide a foundation for developing and training machine learning models.",
    },
    {
      icon: "fas fa-layer-group text-white",
      bullet: "Model Serving and Deployment Platforms",
      bulletText: "Infrastructure for deploying trained models into production environments, ensuring scalability, reliability, and efficient inference.",
    },
    {
      icon: "fas fa-box text-white",
      bullet: "Containerization and Orchestration",
      bulletText: "Technologies like Docker and Kubernetes that enable the encapsulation of AI applications into containers for easy deployment, scaling, and management.",
    },
    {
      icon: "fas fa-toolbox text-white",
      bullet: "Automated ML (AutoML) Tools:",
      bulletText: "Solutions that automate aspects of the machine learning pipeline, from hyperparameter tuning to model selection, speeding up the development process.",
    },
    {
      icon: "fas fa-object-group text-white",
      bullet: "Collaboration and Version Control:",
      bulletText: "Platform for collaboration among data scientists and developers, coupled with version control systems, ensure reproducibility and collaboration in AI project development.",
    },
    {
      icon: "fas fa-notes text-white",
      bullet: "Monitoring and Logging Tools:",
      bulletText: "Systems to monitor AI models and infrastructure in real-time, providing insights into performance, errors, and overall health.",
    },
    {
      icon: "fas fa-scale-balanced text-white",
      bullet: "Ethical AI and Governance Tools:",
      bulletText: "Tools and frameworks that address ethical considerations, bias detection, and compliance with regulations to ensure responsible AI development and deployment.",
    },
    {
      icon: "fas fa-shield-check text-white",
      bullet: "Security Measures:",
      bulletText: " Robust security protocols, encryption, and access controls are essential to protect sensitive data and AI models from potential threats.",
    },
    {
      icon: "fas fa-check text-white",
      bullet: "Continuous Integration/Continuous Deployment (CI/CD):",
      bulletText: "Practices and tools that facilitate the seamless integration and deployment of AI models as part of a development pipeline.",
    }

  ],

  sectionTitle:"The Certo Infrastructure",
  sectionDescription:"The Certo infrastructure is a sophisticated and well-integrated ecosystem designed to support the development, deployment, and management of artificial intelligence applications. It comprises several key components, each playing a crucial role in harnessing the power of AI technologies. These components include:",
  imageOld:"feature-hero-img.svg",
  useImage: true,
  image: "BorrowedImages/Infrastructure.png",
  defaultOptions: defaultOptions
}



const InfrastructureStartup  = () => {
  return (
    <Layout>
      <PageMeta title='Foundational Elements' />
      <Navbar navDark />
      
      <Infrastructure /> 
      <FeatureImgSixExtended data={FeatureImgSixData}/>
      {/*<FeatureImgContentFour data={FeatureImgContentFourData}/> */}
      <WorkProcessTwo/>
      <FooterOne footerLight />
      
    </Layout>
  );
};

export default InfrastructureStartup;
