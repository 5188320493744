import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import PageMeta from '../../components/common/PageMeta';

import RequestForDemo from '../../components/others/RequestForDemo';
import PromoThree from '../../components/promo/PromoThree';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';


import FeatureImgContentTwo from '../../components/feature-img-content/FeatureImgContentTwo';
import FeatureImgContentThree from '../../components/feature-img-content/FeatureImgContentThree';
import UseCaseFeatureImgEight from './UseCaseFeatureImgEight';


let PromoThreeData = {
  title: "This is the Title",
  description: "This is the Description",
  amountOne: "150",
  headerOne:"Completed Deployments",
  textOne: "This is a great accomplishment that needs to be fully appreciated",
  amountTwo: "2k",
  headerTwo:"Happy Clients",
  textTwo: "Energistically impact synergistic convergence for leadership..",
  amountThree: "90%",
  headerThree:"Models Running",
  textThree: "This is a great accomplishment that needs to be fully appreciated"
}



let dataEight = {
  header: "Customer Intelligence",
  tagLine:"Churn Analysis",
  image1: "BorrowedImages/CausalEditing.mp4",
  image: "BorrowedImages/CausalEditing.mp4",
  image2: "BorrowedImages/CausalEditing.mp4",
  headerText:  "Drive interpretable, real-time analysis of customer-level characteristics.  Deployed at scale on billions of underlying customer data points.",
  iconLeft: "fal fa-user fa-2x text-primary",
  iconRight: "fal fa-watch fa-2x text-primary",
  leftSubHeader:"What-if",
  leftSubText:"luyjw5eaery Objectively exceptional via customized intellectual.",
  rightSubHeader:"Real time updates",
  rightSubText:"Real-time transcription",
  linkText:"Customers need more than simple next-best options",
  linkDescription: "They want to know their opinions are valued and thier options validated. Certo's models are capable of teasing signal from noise in ways that elevate your cutomer intelligence enabling new modes of engagement and opportunities for revenue capture.",

}

let FeatureImgContentTwoData = {
  sectionTitle:"Advanced Causal AI",
  sectionDescription: "Certo's Causal AI transcends traditional analytics by unveiling the cause-and-effect relationships within complex data sets. This advanced technology empowers organizations to make more informed decisions, enabling them to identify root causes, predict outcomes, optimize processes, and proactively mitigate risks. Causal AI, through platforms like Certo, serves as a transformative force, providing a profound understanding of the intricate connections between variables and fostering strategic, data-driven decision-making for sustained success.",
  iconMain: "fal fa-shield-check fa-2x text-white",
  image: "BorrowedImages/CounterFactualLoan.gif",
  items: [
    {
      icon:"fal fa fa-arrow-up-from-bracket fa-2x  text-primary mb-4",
      bullet: "Root Cause Identification",
      bulletParagraph: "Certo's causal analysis capabilities delve deep into data, uncovering root causes behind complex issues or challenges, providing clarity for targeted solutions."
    },
    {
      icon:"fal fa fa-pen-to-square fa-2x  text-primary mb-4",
      bullet: "Impact Assessment",
      bulletParagraph: "Assess the potential impact of various factors on outcomes, enabling enterprises to anticipate consequences and make decisions with a comprehensive understanding of potential ramifications."
    },
    {
      icon:"fal fa fa-lock fa-2x  text-primary mb-4",
      bullet: "Risk Mitigation Through Causal Understanding",
      bulletParagraph: "Certo aids in proactive risk management by revealing causal links, allowing enterprises to mitigate risks at their source and build resilience against potential challenges."
    }
  ]
}


let FeatureImgContentThreeData = {
  header: "Easily Change and Manipulate stuff",
  headerParagraph: "Conveniently drive stand-alone total linkage for process-centric content. Enthusiastically administrate robust initiatives quickly unleash collaborative with client-focused",
  iconOne:"fal fa-comments-alt fa-2x text-primary mb-4",
  bulletOne:"Real-time Feedback",
  bulletOneParagraph:"Objectively exceptional via customized intellectual.",
  iconTwo: "fal fa-headset fa-2x text-primary mb-4",
  bulletTwo: "Live Changes Stuff",
  bulletTwoParagraph: "Interactively integrate extensible directed communities.",
  image: "BorrowedImages/annotation-1.svg"
}



const CounterfactualUseCase = () => {
  return (
    <Layout>
      <PageMeta title="Causal ML" />
      <Navbar navDark />
      <PageHeader
        title="What-if?"
        desc="Explore a new frontier in decision support with Certo, employing advanced causal analysis to unravel the intricate relationships between variables. By untangling cause-and-effect connections, Certo empowers enterprises to make decisions with a profound understanding of the underlying factors, fostering strategic and effective outcomes."
      />

      <FeatureImgContentTwo data= {FeatureImgContentTwoData}/>

      <UseCaseFeatureImgEight data={dataEight} paddingTop={"paddingTop"} />
      {/*
      <FeatureImgContentThree data= {FeatureImgContentThreeData}/>
      
      <PromoThree data={PromoThreeData}  hasBg />
  */}
      <RequestForDemo />
      <FooterOne footerLight />
    </Layout>
  );
};

export default CounterfactualUseCase;
