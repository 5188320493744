import React from 'react';

const WorkProcessTwo = () => {
  return (
    <>
      <section className='work-process ptb-120 bg-dark text-white'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-10 col-lg-6'>
              <div className='section-heading text-center' data-aos='fade-up'>
                <h2>Certo's Infratructure is Strategic Goals</h2>
                <p>
                The platform is architected by identifying business goals, data requirements, and the intended outcomes of the AI or ML infrastructure to ensure alignment with organizational objectives.
                </p>
              </div>
            </div>
          </div>
          <div className='row d-flex align-items-center'>
            <div className='col-md-6 col-lg-3'>
              <div
                className='
                  process-card-two
                  text-center
                  px-4
                  py-lg-5
                  py-4
                  rounded-custom
                '
                data-aos='fade-up'
                data-aos-delay='50'
              >
                <div
                  className='
                    process-icon
                    border border-light
                    bg-custom-light
                    rounded-custom
                    p-3
                  '
                >
                  <i className='fad fa-folder-tree fa-2x'></i>
                </div>
                <h3 className='h5'>Research and Innovation</h3>
                <p className='mb-0'>
                Conduct thorough research to stay abreast of the latest advancements and breakthroughs in machine learning and artificial intelligence.
                </p>
              </div>
            </div>
            <div className='col-md-6 col-lg-3'>
              <div
                className='
                  process-card-two
                  text-center
                  px-4
                  py-lg-5
                  py-4
                  rounded-custom
                '
                data-aos='fade-up'
                data-aos-delay='100'
              >
                <div
                  className='
                    process-icon
                    border border-light
                    bg-custom-light
                    rounded-custom
                    p-3
                  '
                >
                  <i className='fad fa-bezier-curve fa-2x'></i>
                </div>
                <h3 className='h5'>Data Preparation and Management</h3>
                <p className='mb-0'>
                Rigorously assess, clean, and prepare datasets for training and testing, ensuring the availability of high-quality data. 
                </p>
              </div>
            </div>
            <div className='col-md-6 col-lg-3'>
              <div
                className='
                  process-card-two
                  text-center
                  px-4
                  py-lg-5
                  py-4
                  rounded-custom
                '
                data-aos='fade-up'
                data-aos-delay='150'
              >
                <div
                  className='
                    process-icon
                    border border-light
                    bg-custom-light
                    rounded-custom
                    p-3
                  '
                >
                  <i className='fad fa-layer-group fa-2x'></i>
                </div>
                <h3 className='h5'>Model Development and Optimization</h3>
                <p className='mb-0'>
                  Develop custom machine learning models that suit the defined objectives, utilizing appropriate algorithms and techniques. 
                </p>
              </div>
            </div>
            <div className='col-md-6 col-lg-3'>
              <div
                className='
                  process-card-two
                  text-center
                  px-4
                  py-lg-5
                  py-4
                  rounded-custom
                '
                data-aos='fade-up'
                data-aos-delay='200'
              >
                <div
                  className='
                    process-icon
                    border border-light
                    bg-custom-light
                    rounded-custom
                    p-3
                  '
                >
                  <i className='fad fa-truck fa-2x'></i>
                </div>
                <h3 className='h5'>Deployment and Monitoring</h3>
                <p className='mb-0'>
                  Deploy the trained models into production environments, track performance, detect anomalies, ensure continued effectiveness.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WorkProcessTwo;
