import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import PageMeta from '../../components/common/PageMeta';

import RequestForDemo from '../../components/others/RequestForDemo';
import PromoThree from '../../components/promo/PromoThree';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import FeatureImgEight from '../../components/features/FeatureImgEight';
import FeatureImgContentThree from '../../components/feature-img-content/FeatureImgContentThree';
import FeatureImgContentTwo from '../../components/feature-img-content/FeatureImgContentTwo';
import UseCaseFeatureImgEight from '../usecases/UseCaseFeatureImgEight';



let PromoThreeData = {
  title: "This is the Title",
  description: "This is the Description",
  amountOne: "150",
  headerOne:"Completed Deployments",
  textOne: "This is a great accomplishment that needs to be fully appreciated",
  amountTwo: "2k",
  headerTwo:"Happy Clients",
  textTwo: "Energistically impact synergistic convergence for leadership..",
  amountThree: "90%",
  headerThree:"Models Running",
  textThree: "This is a great accomplishment that needs to be fully appreciated"
}


let FeatureImgContentTwoData = {
  sectionTitle:"Modern Privacy Framewoks",
  sectionDescription: "The Certo team is at the cutting edge of modern encryption protocols.  We work with enterprise clients to establish customized, secure data and communication infrastrutures that meet their often highly regulated requirements.",
  iconMain: "fal fa-shield-check fa-2x text-white",
  iconOne:"fad fa-bezier-curve fa-2x text-primary",
  bulletOne:"Real-time Feedback to other stuff",
  bulletOneParagraph:"An explainable and accurate way to flexibly combine large language models (LLMs), symbolic, and machine learning AI approaches that solve real-world problems with the highest degree of accuracy.",
  iconTwo: "fad fa-code fa-2x text-primary",
  bulletTwo: "Live Changes Stuff",
  bulletTwoParagraph: "Interactively integrate extensible directed communities.",
  image: "dashboard-img-4.png",
  items: [
    {
      icon:"fal fa fa-building fa-2x  text-primary mb-4",
      bullet: "Anonymization and Pseudonymization",
      bulletParagraph: "Anonymization involves removing or altering personally identifiable information (PII) from datasets to protect user privacy, while pseudonymization replaces direct identifiers with pseudonyms, allowing reversible identification for authorized purposes without exposing the original data."
    },
    {
      icon:"fal fa fa-code fa-2x  text-primary mb-4",
      bullet: "Differential Privacy",
      bulletParagraph: "Adds carefully calibrated noise to query results, ensuring that the presence or absence of any individual's data does not significantly impact the overall outcome, thus protecting individual privacy while still providing valuable insights."
    },
    {
      icon:"fal fa fa-thermometer-quarter fa-2x  text-primary mb-4",
      bullet: "Homomorphic Encryption",
      bulletParagraph: "Allows computations to be performed on encrypted data without decrypting it, preserving the privacy of sensitive information and enabling secure data processing in scenarios where privacy is paramount."
    },
    {
      icon:"fal fa fa-thermometer-quarter fa-2x  text-primary mb-4",
      bullet: "Secure Multi-Party Computation (SMPC)",
      bulletParagraph: "Permits multiple parties to jointly compute a function over their inputs while keeping those inputs private. It ensures that no party reveals its individual data during the computation, making it a powerful tool for collaborative analysis without compromising data privacy."
    }
    
  ]
}


let FeatureImgEightData = {
  sectionTitle:"Robust Data Privacy on the Certo AI Platform",
  sectionDescription: "Ensuring robust data privacy on the Certo AI Platform involves a holistic approach, combining technical safeguards, transparent practices, and user-centric controls to uphold the highest standards of privacy and security.",
  iconOne:"fal fa-user fa-2x text-primary",
  bulletOne:"Privacy by Design",
  bulletOneParagraph:"Embed privacy considerations into the core architecture of the Certo AI Platform, ensuring that data privacy is prioritized from the initial design phase. Implement default privacy settings that prioritize user control and consent, creating a foundation for responsible data handling.",
  iconTwo: "fal fa-watch fa-2x text-primary",
  bulletTwo: "Transparent Data Practices",
  bulletTwoParagraph: "Openly communicating to stakeholders how their data is collected, processed, and utilized, fostering trust through clear and comprehensible information about data handling processes on the Certo platform.",
  image: "BorrowedImages/Privacy.png"
}

let FeatureImgContentThreeData = {
  header: "Secure Data Handling",
  headerParagraph: "Prioritize robust security measures to safeguard user data against unauthorized access, breaches, or misuse. Certo employs encryption protocols, secure data storage solutions, and regularly update security protocols to stay ahead of emerging threats",
  iconOne:"fal fa-comments-alt fa-2x text-primary mb-4",
  bulletOne:"Data Minimization Strategies",
  bulletOneParagraph:"Adhere to principles of data minimization by only collecting and retaining data that is essential for the platform's functionalities. Regularly assess and purge unnecessary data, reducing the overall data footprint and mitigating potential privacy risks.",
  iconTwo: "fal fa-headset fa-2x text-primary mb-4",
  bulletTwo: "Continuous Compliance Monitoring",
  bulletTwoParagraph: "Establish a rigorous compliance framework that aligns with global data protection regulations. Implement continuous monitoring mechanisms to ensure ongoing compliance with evolving privacy laws and standards.",
  image: "BorrowedImages/technology-icon-768x636.png"
}

let dataEight = {
  header: "Customer Intelligence",
  tagLine:"Churn Analysis",
  image1: "video/PIIDemo.mp4",
  image: "video/PIIDemo.mp4",
  image2: "video/PIIDemo.mp4",
  headerText:  "Drive interpretable, real-time analysis of customer-level characteristics.  Deployed at scale on billions of underlying customer data points.",
  iconLeft: "fal fa-user fa-2x text-primary",
  iconRight: "fal fa-watch fa-2x text-primary",
  leftSubHeader:"What-if",
  leftSubText:"luyjw5eaery Objectively exceptional via customized intellectual.",
  rightSubHeader:"Real time updates",
  rightSubText:"Real-time transcription",
  linkText:"Safeguarding Data: The Rise of Privacy-Preserving AI Technologies",
  linkDescription: "These advanced algorithms and techniques allow for the analysis and utilization of sensitive data while preserving individual privacy. By employing methods such as federated learning, homomorphic encryption, and differential privacy, AI systems can extract valuable insights without compromising personal information.",
  majorIngredients : "Differential Privacy, Secure Multiparty Computation, Homomorphic Encryption",
}




const Privacy  = () => {
  return (
    <Layout>
      <PageMeta title="Privacy" />
      <Navbar navDark />
      <PageHeader
        title="Privacy"
        desc="Certo employs modern privacy techniques to safeguard sensitive information and protect individual privacy throughout the lifecycle of AI systems. These techniques are crucial in ensuring responsible and ethical use of AI technologies, particularly when dealing with personal or sensitive data. Common privacy techniques in AI include: Anonymization and Pseudonymization, Differential Privacy, Homomorphic Encryption, Secure Multi-Party Computation (SMPC), Model Explainability and Transparency, Policy and Governance, Ethical AI Design.  By integrating these privacy techniques, Certo is able to develop systems that respects user privacy, complies with regulations, and builds trust in the deployment of AI technologies."
      />
      <FeatureImgEight paddingTop data={FeatureImgEightData}/>
      <UseCaseFeatureImgEight data={dataEight} paddingTop={"paddingTop"} />

      <FeatureImgContentThree data= {FeatureImgContentThreeData}/>
      <FeatureImgContentTwo data= {FeatureImgContentTwoData}/>
    
      <RequestForDemo />
      <FooterOne footerLight />
    </Layout>
  );
};

export default Privacy ;
