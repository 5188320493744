import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import CtaTwo from '../../components/cta/CtaTwo';
import FeatureUseCases from '../../components/features/FeatureUseCases';
import TestimonialTwo from '../../components/testimonial/TestimonialTwo';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

const UseCases = () => {
  return (
    <Layout>
      <Navbar navDark />
      <PageHeader
        title="Use Cases"
        desc="AI is transforming the interface between people and systems.  We have only begun to scratch the surface of what is possible."
      />
      <FeatureUseCases />
      <TestimonialTwo bgWhite />
      <CtaTwo />
      <FooterOne footerLight />
    </Layout>
  );
};

export default UseCases;
