import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import PageMeta from '../../components/common/PageMeta';

import RequestForDemo from '../../components/others/RequestForDemo';
import PromoThree from '../../components/promo/PromoThree';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import FeatureImgEight from '../../components/features/FeatureImgEight';
import FeatureImgContentThree from '../../components/feature-img-content/FeatureImgContentThree';
import FeatureImgContentTwo from '../../components/feature-img-content/FeatureImgContentTwo';


let PromoThreeData = {
  title: "Big Data Before It Was Cool",
  description: "Curating large amounts of data on the Certo AI platform is a process of precision, scalability, and scientific rigor. We navigate expansive datasets and ensure meticulous curation to derive actionable insights with optimal accuracy.",
  amountOne: "10B+",
  headerOne:"Curated Data Points",
  textOne: "Certo handles vast datasets, curating billions of data points efficiently and making available for training",
  amountTwo: "2k",
  headerTwo:"Curated Data Sets",
  textTwo: "Certo maintains over two thousand curated datasets that can be filtered, queried and merged for training models",
  amountThree: "95%",
  headerThree:"Accuracy",
  textThree: "The Certo standard is to define 95% prediction intervals when defining statistical boundaries for model outputs"
}

let FeatureImgContentThreeData = {
  header: "The Science of Dataset Curation with Certo",
  headerParagraph: "Certo's data set curation is a scientific endeavor, meticulously crafted to harness the full potential of data science principles, ensuring that the datasets underpinning its AI models are not just curated but intricately refined through a lens of scientific rigor",
  iconOne:"fal fa-comments-alt fa-2x text-primary mb-4",
  bulletOne:"Algorithmic Validation and Enrichment",
  bulletOneParagraph:"Curation on the Certo AI platform involves algorithmic validation, where sophisticated algorithms are applied to authenticate and enrich datasets. This scientific approach ensures that data is not only accurate but is also enhanced for deeper insights, minimizing biases and maximizing the scientific validity of AI-driven analyses.",
  iconTwo: "fal fa-headset fa-2x text-primary mb-4",
  bulletTwo: "Statistical Quality Assurance",
  bulletTwoParagraph: "The curation process includes rigorous statistical quality assurance methodologies, employing statistical techniques to analyze, validate, and maintain the quality of the curated datasets. This scientific scrutiny guarantees that the datasets utilized in AI model training adhere to the highest standards of accuracy and reliability",
  image: "BorrowedImages/technology-icon-768x636.png"
}

let FeatureImgContentTwoData = {
  sectionTitle:"Elevating AI Performance through Precision",
  sectionDescription: "Certo's commitment to excellence extends to its meticulous approach to data set curation, a foundational step in developing powerful AI models. Through a comprehensive process, Certo ensures that curated datasets align precisely with the unique requirements of its AI platform, promoting accuracy, reliability, and optimal performance.",
  iconMain: "fal fa-shield-check fa-2x text-white",
  image: "BorrowedImages/Annotate 1.png",
  items: [
    {
      icon:"fal fa fa-book fa-2x  text-primary mb-4",
      bullet: "Domain-Specific Expertise",
      bulletParagraph: "Certo leverages domain-specific expertise to curate datasets, ensuring that the data is not only relevant but also reflective of the intricacies within specific industries. This approach enhances the platform's ability to address nuanced challenges and deliver targeted solutions."
    },
    {
      icon:"fal fa fa-stream fa-2x  text-primary mb-4",
      bullet: "Dynamic Adaptability",
      bulletParagraph: "Recognizing the dynamic nature of data, Certo's curation process is designed for adaptability. Continuous monitoring and updates to datasets ensure that the AI platform remains responsive to changing trends, evolving user behaviors, and emerging patterns, providing sustained relevance over time."
    },
    {
      icon:"fal fa fa-user-check fa-2x  text-primary mb-4",
      bullet: "Quality Assurance Protocols",
      bulletParagraph: "Recognizing the dynamic nature of data, Certo's curation process is designed for adaptability. Continuous monitoring and updates to datasets ensure that the AI platform remains responsive to changing trends, evolving user behaviors, and emerging patterns, providing sustained relevance over time."
    }
  ]
}


let FeatureImgEightData = {
  sectionTitle:"The Art of Dataset Curation with Certo",
  sectionDescription: "Certo's approach to data set curation is an artful blend of precision and adaptability, ensuring that the foundation of its AI models is not only accurate but also agile in the face of evolving data landscapes.",
  iconOne:"fal fa-user fa-2x text-primary",
  bulletOne:"Strategic Alignment with Business Objectives",
  bulletOneParagraph:"Curation on the Certo AI platform begins with a strategic alignment of datasets with the specific business objectives of its users. This meticulous process ensures that the curated data not only meets industry standards but is also tailored to deliver actionable insights aligned with organizational goals.",
  iconTwo: "fal fa-watch fa-2x text-primary",
  bulletTwo: "Automated Iterative Refinement",
  bulletTwoParagraph: "Certo employs automated tools and iterative refinement processes in data curation, allowing for continuous enhancement of dataset quality. Through this iterative approach, the platform adapts to changing data patterns, improving the precision and reliability of AI models over time.",
  image: "BorrowedImages/DataCuration.png"
}


const Curation  = () => {
  return (
    <Layout>
      <PageMeta title="MLOps" />
      <Navbar navDark />
      <PageHeader
        title="Dataset Curation"
        desc="Certo employs the meticulous process of selecting, cleaning, and organizing raw data to create a high-quality dataset for training and evaluating models. This crucial step ensures that the data used is relevant, accurate, and representative of the problem domain, ultimately influencing the performance and generalization capabilities of machine learning algorithms. Effective data set curation is fundamental for the success of machine learning projects, as the quality of the input data significantly influences the quality and reliability of the model's outputs."
      />
      <FeatureImgContentTwo data= {FeatureImgContentTwoData}/>
      <FeatureImgEight paddingTop data={FeatureImgEightData}/>
      <FeatureImgContentThree data= {FeatureImgContentThreeData}/>
      <PromoThree hasBg data={PromoThreeData}/>
      {/*
      <RequestForDemo />
  */}
      <FooterOne footerLight />
    </Layout>
  );
};

export default Curation ;
