import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import PageMeta from '../../components/common/PageMeta';

import RequestForDemo from '../../components/others/RequestForDemo';
import PromoThree from '../../components/promo/PromoThree';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';


import FeatureOne from '../../components/features/FeatureOne';
import FeatureFour from '../../components/features/FeatureFour';
import FeatureFive from '../../components/features/FeatureFive';

let data = {
    header: "TEST HEADER",
    image: "emerging-llm-stack.webp",
    headerVerbose: "Conveniently drive stand-alone total linkage for process-centric content. Enthusiastically administrate robust initiatives quickly unleash collaborative with client-focused.",
    iconLeft: "fal fa-comments-alt fa-2x text-primary mb-4",
    iconRight: "fal fa-headset fa-2x text-primary mb-4",
    leftSubHeader:"Left Sub-Header",
    leftSubText:"luyjw5eaery Objectively exceptional via customized intellectual.",
    rightSubHeader:"Right Sub-Header",
    rightSubText:"luyjw5eaery Objectively exceptional via customized intellectual."
}

let dataEight = {
    header: "Voice to Text",
    image1: "voice-to-text.jpg",
    image: "video/PromptFlow.mp4",
    image2: "video/LabelingWorkflow.mp4",
    tagLine:"Pipeline",
    headerText:  "Drive interpretable, real-time analysis of loan-level characteristics.  Deployed at scale on billions of underlying loan data points.",
    iconLeft: "fal fa-user fa-2x text-primary",
    iconRight: "fal fa-watch fa-2x text-primary",
    leftSubHeader:"What-if",
    leftSubText:"luyjw5eaery Objectively exceptional via customized intellectual.",
    rightSubHeader:"Real time updates",
    rightSubText:"Real-time transcription"
}

let dataEight2 = {
  header: "Voice to Text",
  tagLine:"GAM Analysis",
  image1: "video/Whisper-demo-1-audio.mp4",
  image: "video/Whisper-demo-1-audio.mp4",
  image2: "video/Whisper-demo-1-audio.mp4",
  headerText:  "Drive interpretable, real-time analysis of loan-level characteristics.  Deployed at scale on billions of underlying loan data points.",
  iconLeft: "fal fa-user fa-2x text-primary",
  iconRight: "fal fa-watch fa-2x text-primary",
  leftSubHeader:"What-if",
  leftSubText:"luyjw5eaery Objectively exceptional via customized intellectual.",
  rightSubHeader:"Real time updates",
  rightSubText:"Real-time transcription"
}

let dataEight3 = {
  header: "Voice to Text",
  tagLine:"GAM Analysis",
  image1: "BorrowedImages/CausalEditing.mp4",
  image: "BorrowedImages/CausalEditing.mp4",
  image2: "BorrowedImages/CausalEditing.mp4",
  headerText:  "Drive interpretable, real-time analysis of loan-level characteristics.  Deployed at scale on billions of underlying loan data points.",
  iconLeft: "fal fa-user fa-2x text-primary",
  iconRight: "fal fa-watch fa-2x text-primary",
  leftSubHeader:"What-if",
  leftSubText:"luyjw5eaery Objectively exceptional via customized intellectual.",
  rightSubHeader:"Real time updates",
  rightSubText:"Real-time transcription"
}




let PromoThreeData = {
  title: "This is the Title",
  description: "This is the Description",
  amountOne: "150",
  headerOne:"Completed Deployments",
  textOne: "This is a great accomplishment that needs to be fully appreciated",
  amountTwo: "2k",
  headerTwo:"Happy Clients",
  textTwo: "Energistically impact synergistic convergence for leadership..",
  amountThree: "90%",
  headerThree:"Models Running",
  textThree: "This is a great accomplishment that needs to be fully appreciated"
}


const VoiceToTextUseCase = () => {
  return (
    <Layout>
      <PageMeta title="Financial Services" />
      <Navbar navDark />
      <PageHeader
        title="Financial Services"
        desc="In the ever-evolving landscape of financial services, the integration of cutting-edge technologies is not just a necessity but a transformative imperative. Enter Certo, an AI platform poised to revolutionize banking through its unparalleled capabilities. Designed to elevate the traditional paradigms of financial operations, Certo promises a seismic shift in how banking institutions operate, providing not just efficiency but a new echelon of insights, security, and personalized experiences. In this era of digital transformation, Certo stands as a beacon, offering a pathway for banks to navigate the complexities of modern finance with agility, precision, and a profound understanding of their customers and markets. Let's delve into the transformative potential that Certo brings to the forefront of financial services, ushering in a new era of banking excellence."
      />
      
      <FeatureFive />
      <FeatureOne/>
      <FeatureFour />
      
      <PromoThree data={PromoThreeData} hasBg />
      <RequestForDemo />
      <FooterOne footerLight />
    </Layout>
  );
};

export default VoiceToTextUseCase;
