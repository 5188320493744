import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OffCanvasMenu from "./OffCanvasMenu";

const Navbar = ({ navDark }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const stickyheader = document.querySelector(".main-header");
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <>
      <header
        className={`main-header ${
          navDark ? "position-absolute " : ""
        } w-100 position-absolute `}
      >
        <nav
          className={`navbar navbar-expand-xl z-10 ${
            navDark ? "navbar-dark" : "navbar-light"
          } sticky-header ${scroll > headerTop ? "affix" : ""}`}
        >
          <div className="container d-flex align-items-center justify-content-lg-between position-relative">
            <Link
              to="/"
              className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none"
            >
              <img
                src="assets/img/BorrowedImages/CertoAILogo.png"
                alt="logo"
                className="certo-logo"
                width={25}
                height={25}
              />

              <span
                className={scroll > headerTop ? "test-dark" : "test-light"}
                width={113}
                height={36}
              >
                Certo<span className="ai">/ai </span>
              </span>

              {/*  <img
                src="assets/img/Certo_logo_New.png"
                alt="logo"
                className="img-fluid logo-white"
                width={113}
                height={36}
              />
              <img
                src="assets/img/Certo_logo_New.png"
                alt="logo"
                className="img-fluid logo-color"
                width={113}
                height={36}
              />
        */}
            </Link>
            <Link
              className="navbar-toggler position-absolute right-0 border-0"
              to="#offcanvasWithBackdrop"
              role="button"
            >
              <span
                className="far fa-bars"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasWithBackdrop"
                aria-controls="offcanvasWithBackdrop"
              ></span>
            </Link>

            <div className="collapse navbar-collapse justify-content-center">
              <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                <li>
                  <Link to="/" className="nav-link">
                    Home
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="/"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Platform
                  </Link>
                  <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                    <div className="dropdown-grid rounded-custom width-full">
                      <div className="dropdown-grid-item">
                        <h6 className="drop-heading">Foundations</h6>
                        <Link to="/models" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            <i className="far fa-calculator"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Models</div>
                            <p>
                              Underlying <strong>AI Toolkit</strong>
                            </p>
                          </div>
                        </Link>
                        <Link to="/infrastructure" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            <i class="fa-solid fa-server"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Infrastructure</div>
                            <p>
                              Optimized <strong>Infrastructure</strong>
                            </p>
                          </div>
                        </Link>

                        <Link to="/planning" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            <i class="fa-duotone fa-gears"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Process</div>
                            <p>
                              Plan, <strong>Act, </strong> Re-act
                            </p>
                          </div>
                        </Link>
                        <Link to="/augmentation" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            {/* <i class="fa-solid fa-person-rays"></i> */}
                            <i class="fa-sharp fa-solid fa-person"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Human Centric AI</div>
                            <p>
                              <strong>People</strong> Applying Intelligence
                            </p>
                          </div>
                        </Link>
                        {/*
                        <Link to="/causal" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            <i class="fa-solid fa-code-fork"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Causal</div>
                            <p>
                              <strong>Capture Causal</strong> Relationships
                            </p>
                          </div>
                        </Link>
                        <Link to="/predictive" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            <i class="fa-solid fa-square-poll-vertical"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">
                              Predictive
                            </div>
                            <p>
                              Classical <strong>Theoretical Guarantes</strong>
                            </p>
                          </div>
                        </Link>
                        
                        <Link to="/toolkit" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            <i class="fa-solid fa-chart-line"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Time Series</div>
                            <p>
                              <strong>Predictive</strong>&lrm;
                            </p>
                          </div>
                        </Link>
                        <Link
                          to="/home-data-analysis"
                          className="dropdown-link"
                        >
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                          <i class="fa-regular fa-chart-bar"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Conformal</div>
                            <p>
                              Cutting Edge &amp; <strong>Uncertainty</strong> Quantization&lrm;
                            </p>
                          </div>
                        </Link>
                        <Link to="/home-app-two" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            <i class="fa-solid fa-diagram-project"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Tree</div>
                            <p>
                              App and <strong>Software</strong> Landing
                            </p>
                          </div>
                        </Link>
                        */}
                      </div>
                      <div className="dropdown-grid-item radius-right-side bg-light">
                        <h6 className="drop-heading">Data</h6>
                        <Link to="/curation" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            <i class="fa-solid fa-database"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Dataset Curation</div>
                            <p>
                              <strong>Bespoke</strong> Datasets from Your Data
                            </p>
                          </div>
                        </Link>
                        <Link to="/data-programing" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            <i class="fa-solid fa-code"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Data Engineering</div>
                            <p>
                              <strong>Algorithims and Hueristics</strong>
                            </p>
                          </div>
                        </Link>
                        <Link to="/privacy" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            <i class="fa-solid fa-shield-check"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">
                              Privacy and Security
                            </div>
                            <p>
                              <strong>Cryptographically</strong> Secured
                            </p>
                          </div>
                        </Link>

                        {/*
                        <Link to="/home-fourteen" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            <i class="fa-regular fa-lightbulb"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Marketing</div>
                            <p>
                              <strong>Tailored</strong> data for personalized outreach
                            </p>
                          </div>
                        </Link>
                      */}
                        <Link to="/mlops" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            <i class="fa-solid fa-screwdriver-wrench"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">MLOps</div>
                            <p>
                              <strong>Infrastructure</strong> and{" "}
                              <strong>Governance</strong>
                            </p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="/"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Capabilities
                  </Link>
                  <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                    <div className="dropdown-grid rounded-custom width-half">
                      <div className="dropdown-grid-item dropdown-info">
                        <h6 className="drop-heading">Applications</h6>
                        <Link to="/documents" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            <i class="fa-solid fa-pen-to-square"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">
                              Document Intelligence
                            </div>
                            <p>
                              <strong>Information</strong> Extraction
                            </p>
                          </div>
                        </Link>
                        <Link to="/customer" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            <i class="fa-solid fa-headset"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">
                              Customer Intelligence
                            </div>
                            <p>
                              <strong>Customized</strong> interaction plans
                            </p>
                          </div>
                        </Link>
                        <Link
                          to="/process-automation"
                          className="dropdown-link"
                        >
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            <i class="fa-solid fa-robot"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Process Automation</div>
                            <p>
                              Turn <strong>bottlenecks</strong> into profit
                              centers
                            </p>
                          </div>
                        </Link>
                        <Link
                          to="/visual-understanding"
                          className="dropdown-link"
                        >
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            <i class="fa-solid fa-binoculars"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">
                              Visual Understanding
                            </div>
                            <p>
                              Extract <strong>Information</strong> from images
                            </p>
                          </div>
                        </Link>

                        <Link
                          to="/predictive-analytics"
                          className="dropdown-link"
                        >
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            <i class="fa-solid fa-chart-mixed"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">
                              Predictive Analytics
                            </div>
                            <p>
                              <strong>Predictive</strong> Simulation and
                              Forecasts
                            </p>
                          </div>
                        </Link>

                        <Link to="/voice-to-text" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            <i class="fa-solid fa-microphone"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Voice to Text</div>
                            <p>
                              <strong>Entity and Sentiment</strong> analysis
                            </p>
                          </div>
                        </Link>
                        <Link to="/decision-support" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            <i class="fa-solid fa-layer-group"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Decision Support</div>
                            <p>
                              <strong>Rigorous</strong> statistical decision
                              support
                            </p>
                          </div>
                        </Link>
                        {/* <Link to="/counterfactual" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            <i class="fa-solid fa-square-question"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">What If?</div>
                            <p>
                              <strong>Simulation</strong> based AI Analysis
                            </p>
                          </div>
                        </Link>

                        <Link to="/alternatives" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            <i class="fa-regular fa-lightbulb"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">
                              Analysis of Alternatives
                            </div>
                            <p>
                              <strong>Quantify</strong> impact of alternatives
                            </p>
                          </div>
                        </Link> */}
                        {/*
                        <Link to="/single-service" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            <i class="fa-solid fa-user-secret"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Federated Learning</div>
                            <p>
                              <strong>Cryptographicly</strong> secure AI
                            </p>
                          </div>
                        </Link>
                        <Link to="/counterfactual" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            <i class="fa-solid fa-screwdriver-wrench"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Relationship Identification</div>
                            <p>
                              <strong>Match</strong> concepts for down stream evaluation
                            </p>
                          </div>
                        </Link>

                    */}
                      </div>

                      {/*
                      <div className="dropdown-grid-item radius-left-side bg-light">
                        <h6 className="drop-heading">Industries</h6>
                        <Link to="/financial-services" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                            <i class="fa-solid fa-landmark"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Financial Services</div>
                            <p>
                              Banks, Credit Uniions, Fintechs
                            </p>
                          </div>
                        </Link>
                        <Link to="/loan-analytics" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                          <i class="fa-solid fa-landmark-dome"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Government</div>
                            <p>
                              Improving Health <strong>Outcomes</strong>
                            </p>
                          </div>
                        </Link>
                        <Link to="/loan-analytics" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                          <i class="fa-solid fa-user-shield"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Insurance</div>
                            <p>
                              Improving Insurance <strong>Outcomes</strong>
                            </p>
                          </div>
                        </Link>
                        <Link to="/loan-analytics" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                          <i class="fa-solid fa-dna"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Healthcare</div>
                            <p>
                              Improving Health <strong>Outcomes</strong>
                            </p>
                          </div>
                        </Link>
                        <Link to="/loan-analytics" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                          <i class="fa-solid fa-tag"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Retail</div>
                            <p>
                              <strong>Match</strong> concepts for down stream evaluation
                            </p>
                          </div>
                        </Link>
                        <Link to="/loan-analytics" className="dropdown-link">
                          <span className="demo-list bg-primary rounded text-white fw-bold">
                          <i class="fa-solid fa-truck-pickup"></i>
                          </span>
                          <div className="dropdown-info">
                            <div className="drop-title">Supply Chain</div>
                            <p>
                              <strong>Match</strong> concepts for down stream evaluation
                            </p>
                          </div>
                        </Link>
                      </div>
                    */}
                    </div>
                  </div>
                </li>
                <li>
                  {/* <Link to="/services" className="nav-link"> */}
                  <Link to="/services" className="nav-link">
                    Services
                  </Link>
                </li>
                {/*
                <li>
                  <Link to="/about-us" className="nav-link">
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/usecases" className="nav-link">
                    Use Cases
                  </Link>
                </li>
                <li>
                  <Link to="/services" className="nav-link">
                    Services
                  </Link>
                </li>
                <li>
                  <Link to="/pricing" className="nav-link">
                    Pricing
                  </Link>
                </li>
      */}

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="/#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Company
                  </a>
                  <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                    <div className="dropdown-grid rounded-custom width-full">
                      <div className="dropdown-grid-item">
                        {/*  <h6 className="drop-heading">Useful Links</h6> */}
                        <Link to="/about-us" className="dropdown-link px-0">
                          <span className="me-2">
                            <i className="far fa-fingerprint"></i>
                          </span>
                          <div className="drop-title">About Us</div>
                        </Link>
                        <Link to="/contact-us" className="dropdown-link px-0">
                          <span className="me-2">
                            <i className="far fa-address-book"></i>
                          </span>
                          <div className="drop-title">Contact Us</div>
                        </Link>
                        {/*
                        <Link
                          to="/single-service"
                          className="dropdown-link px-0"
                        >
                          <span className="me-2">
                            <i className="far fa-server"></i>
                          </span>
                          <div className="drop-title">Services Single</div>
    </Link> */}
                        {/* <Link to="/blogs" className="dropdown-link">
                          <span className="me-2">
                            <i className="far fa-th-large"></i>
                          </span>
                          <div className="drop-title">Insights</div>
                        </Link> */}
                        {/*
                        <Link to="/blog-single" className="dropdown-link">
                          <span className="me-2">
                            <i className="far fa-bars"></i>
                          </span>
                          <div className="drop-title">News Details</div>
                        </Link>
  */}

                        {/*
                        <Link
                          to="/career-single"
                          className="dropdown-link px-0"
                        >
                          <span className="me-2">
                            <i className="far fa-user-graduate"></i>
                          </span>
                          <div className="drop-title">Career Single</div>
                        </Link>
*/}

                        {/*
                        <Link
                          to="/integration-single"
                          className="dropdown-link px-0"
                        >
                          <span className="me-2">
                            <i className="far fa-paper-plane"></i>
                          </span>
                          <div className="drop-title">Integration Single</div>
                        </Link>
*/}
                      </div>
                      <div className="dropdown-grid-item radius-right-side bg-light">
                        {/*                       <h6 className="drop-heading">Utility Pages </h6>

                        <Link to="/help-center" className="dropdown-link">
                          <span className="me-2">
                            <i className="far fa-life-ring"></i>
                          </span>
                          <div className="drop-title">Help Center</div>
                        </Link>
                        <Link
                          to="/help-center-single"
                          className="dropdown-link"
                        >
                          <span className="me-2">
                            <i className="far fa-headset"></i>
                          </span>
                          <div className="drop-title">Help Details</div>
                        </Link>
*/}
                        {/* <Link to="/career" className="dropdown-link px-0">
                          <span className="me-2">
                            <i className="far fa-graduation-cap"></i>
                          </span>
                          <div className="drop-title">Career</div>
                        </Link> */}
                        <Link to="/integrations" className="dropdown-link">
                          <span className="me-2">
                            <i className="far fa-rocket-launch"></i>
                          </span>
                          <div className="drop-title">Integrations</div>
                        </Link>
                        <Link to="/request-for-demo" className="dropdown-link">
                          <span className="me-2">
                            <i className="far fa-laptop-code"></i>
                          </span>
                          <div className="drop-title">Request for Demo</div>
                        </Link>
                        {/*
                        <Link to="/single-product" className="dropdown-link">
                          <span className="me-2">
                            <i className="far fa-sign-in"></i>
                          </span>
                          <div className="drop-title">Single Product</div>
                        </Link>
                        <Link to="/portfolio-single" className="dropdown-link">
                          <span className="me-2">
                            <i className="far fa-user-plus"></i>
                          </span>
                          <div className="drop-title">Portfolio Singe</div>
                        </Link>
                        
                        <Link to="/password-reset" className="dropdown-link">
                          <span className="me-2">
                            <i className="far fa-trash-undo"></i>
                          </span>
                          <div className="drop-title">Recovery Account</div>
                        </Link>
                       
                        <Link to="/features-demo" className="dropdown-link">
                          <span className="me-2">
                            <i className="far fa-exclamation-triangle"></i>
                          </span>
                          <div className="drop-title">Features Demo</div>

                        </Link>
                        <Link to="/work-process-demo" className="dropdown-link">
                          <span className="me-2">
                            <i className="far fa-exclamation-triangle"></i>
                          </span>
                          <div className="drop-title">Work Process Demo</div>

                          
                        </Link>
                        
                        <Link
                          to={process.env.PUBLIC_URL + '/coming-soon'}
                          className="dropdown-link"
                        >
                          <span className="me-2">
                            <i className="far fa-clock"></i>
                          </span>
                          <div className="drop-title">Coming Soon</div>
                        </Link>
*/}
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div className="action-btns text-end me-5 me-lg-0 d-none d-md-block d-lg-block">
              {/*
              <Link
                to="/login"
                className="btn btn-link text-decoration-none me-2"
              >
                Sign In
              </Link>
            */}
              <Link to="/request-for-demo" className="btn btn-primary">
                Get Started
              </Link>
            </div>
            <OffCanvasMenu />
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
