import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import PageHeader from '../../components/common/PageHeader';
import Toolkit from './Toolkit';
import TestimonialTwo from '../../components/testimonial/TestimonialTwo';
import CtaTwo from '../../components/cta/CtaTwo';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import IntegrationTwo from '../../components/integration/IntegrationTwo';
import FeatureImgThree from '../../components/features/FeatureImgThree';
import Customer from '../../components/customer/Customer';
import WorkProcessFour from '../../components/work-process/WorkProcessFour';
import PromoThree from '../../components/promo/PromoThree';
import FeatureImgEight from '../../components/features/FeatureImgEight';
import FeatureTwo from '../../components/features/FeatureTwo';

const ToolkitStartup  = () => {
  return (
    <Layout>
      <PageMeta title='Certo Toolkit' />
      <Navbar navDark />
      <PageHeader
        title="Time Series Analysis"
        desc="Causal analysis is a cutting-edge framework that allows for the analysis of counterfactuals. Ask, `What If?` and get statistically sounds answers."
      />
      {/*<Toolkit /> 
      <Customer gray />
      */}
      
      <FeatureImgThree />
      <FeatureImgEight />
      <FeatureTwo cardDark />
      <WorkProcessFour />
      
      <PromoThree />
      {/*
      <IntegrationTwo />
      <TestimonialTwo bgWhite/>
      <CtaTwo />
    */}
      <FooterOne footerLight />
    </Layout>
  );
};

export default ToolkitStartup;
