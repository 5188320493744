import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import PageHeader from '../../components/common/PageHeader';
import Generative from './Generative';
import TestimonialTwo from '../../components/testimonial/TestimonialTwo';
import CtaTwo from '../../components/cta/CtaTwo';
import FooterOne from '../../layout/Footer/FooterOne';
import WorkProcess from '../../components/work-process/WorkProcess'
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

import FeatureImgThree from '../../components/features/FeatureImgThree';

import WorkProcessFour from '../../components/work-process/WorkProcessFour';
import PromoThree from '../../components/promo/PromoThree';
import FeatureImgEight from '../../components/features/FeatureImgEight';
import FeatureTwo from '../../components/features/FeatureTwo';


import FeatureImg from '../../components/features/FeatureImg';
import FeatureImgSix from '../../components/features/FeatureImgSix';
import FeatureImgSeven from '../../components/features/FeatureImgSeven';
import FeatureImgTwo from '../../components/features/FeatureImgTwo';
import FeatureImgFive from '../../components/features/FeatureImgFive';
import FeatureImgFour from '../../components/features/FeatureImgFour';


import animationData from '../lotties/security-research1';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

let FeatureImgData ={
  header: "Certo's CustomLarge Language Models",
  headerParagraphOne: "Certo empowers you to forge your own path in AI with personalized Generative and Large Language Models, tailored specifically to your data. Unleash the potential of your unique information with Certo's customizable solution.",
  headerParagraphTwo: "Solving real world NLP problems successfully requires human intelligence, domain and process knowledge, and general knowledge.",
  iconOne: "fal fa-fingerprint fa-2x text-white",
  linkText: "Find out more",
  image:"BorrowedImages/emerging-llm-stack.webp"
}

let FeatureImgTwoData ={
  sectionTitle:"Advanced AI Features",
  sectionSubTitle:"Actionable AI to Grow your Business",
  sectionDescription:"Certo empowers you to forge your own path in AI with personalized Generative and Large Language Models, tailored specifically to your data. Unleash the potential of your unique information with Certo's customizable solution.",
  headerOne: "Tailored to Your Data",
  headerOneParagraph: "Certo's platform enables the creation of Large Language Models specifically fine-tuned for your dataset, ensuring relevance and accuracy in generating contextually rich content. Customize the training process to capture domain-specific nuances, making the models adept at understanding and generating content unique to your industry or application.",
  headerTwo: "Seamless Integration",
  headerTwoParagraph: "Integrate your custom models seamlessly into your existing workflows and applications, enhancing their capabilities with AI-generated content. Certo's user-friendly interface makes it accessible for users with varying levels of technical expertise, allowing for easy model customization and deployment.",
  headerThree: "Iterative Improvement",
  headerThreeParagraph: "Benefit from Certo's iterative approach to model development, enabling continuous improvement based on user feedback and evolving data patterns. Stay at the forefront of language generation capabilities by refining your custom models over time, ensuring they adapt to changing requirements and remain on the cutting edge of AI innovation.",
  iconOne: "fas fa-bezier-curve text-white",
  iconTwo: "fas fa-fingerprint text-white",
  linkText: "Find out more",
  imageOld:"dashboard-img.png",
  image: "BorrowedImages/GenerativeLLMStack.png"
  //image:"BorrowedImages/emerging-llm-stack.webp"
}


let FeatureImgThreeData ={
  sectionTitle:"Advanced AI Features",
  sectionSubTitle:"Actionable AI to Grow your Business",
  sectionDescription:"Intrinsicly network transparent services whereas B2C ROI. Globally create installed base quality vectors after innovative.",
  headerOne: "This is the Header",
  headerOneParagraph: "This is lots and lots and lots of header text so that it fills a bunch of stuff up.",
  headerTwo: "This is the Header Two",
  headerTwoParagraph: "Solving real world NLP problems successfully requires human intelligence, domain and process knowledge, and general knowledge.",
  iconOne: "fas fa-bezier-curve text-white",
  iconTwo: "fas fa-fingerprint text-white",
  listIconeOne: "fas fa-check bg-primary text-white rounded-circle",
  listIconOneText: "Fully ISO-2000 Compatable",
  listIconeTwo: "fas fa-check bg-primary text-white rounded-circle",
  listIconTwoText: "SOC II Completed",
  listIconeThree: "fas fa-check bg-primary text-white rounded-circle",
  listIconThreeText: "Bank Grade Security",

  linkText: "Find out more",
  image:"blue-color-waves-particle-and-geometric-abstract-background-design-vector-illustration-2888737634.jpg"
}

let FeatureImgSixData ={
  bulletOne: "Versatility in Natural Language Processing",
  bulletOneText: "Large Language Models excel in a myriad of tasks, including text summarization, sentiment analysis, language translation, and chatbot interactions. Their adaptive nature allows seamless handling of various linguistic intricacies, making them valuable across diverse applications.",
  bulletTwo: "Contextual Understanding and Generation",
  bulletTwoText: "These models exhibit a deep understanding of context, allowing for more coherent and context-aware text generation. The ability to grasp nuanced language patterns enhances their capacity to generate human-like responses in natural language.",
  bulletThree: "Training on Massive Datasets for Enhanced Capabilities",
  bulletThreeText: "Large Language Models leverage extensive training on massive datasets, enabling them to capture intricate linguistic nuances and context from diverse sources. The vast amount of data contributes to their robustness and adaptability, making them formidable tools for complex language-related tasks",

  iconOne: "fas fa-bezier-curve text-white",
  iconTwo: "fas fa-fingerprint text-white",
  iconThree: "fas fa-headset text-white",
  sectionTitle:"Large Language Models",
  sectionDescription:"Large Language Models, such as GPT-4 and Llama2, are powerful AI systems designed for natural language understanding and generation",
  imageOld:"feature-hero-img.svg",
  image: "BorrowedImages/4neuron-view2.gif",
  //image: "ai-generated/data-stack-2.jpeg",
  useImage: true,
  defaultOptions: defaultOptions
}

let FeatureImgEightData = {
  sectionTitle:"Success Through Experience",
  sectionDescription: "Solving complex AI/ML problems is difficult without in the trenches experience. Our team has years of real-world experience solving enterprise level problems using the Certo Infrastructure. With end-to-end workflows covering intelligent document processing, data engineering, data extraction, model training, testing and orchestration capabilities all the way to production API endpoint deployment, we provide teams with:",
  iconOne:"fal fa-user fa-2x text-primary",
  bulletOne:"Customer analysis",
  bulletOneParagraph:"Objectively exceptional via customized capital expertise.",
  iconTwo: "fal fa-watch fa-2x text-primary",
  bulletTwo: "Real time metrics Stuff",
  bulletTwoParagraph: "Interactively integrate extensible users resource..",
  image: "dashboard-img-4.png"
}


let FeatureTwoData = {
  sectionTitle:"Advanced AI Features",
  sectionSubTitle:"Actionable AI to Grow your Business",
  sectionDescription:"Intrinsicly network transparent services whereas B2C ROI. Globally create installed base quality vectors after innovative.",
  cardOneIcon: "fal fa-analytics icon-sm text-primary",
  cardOneHeader: "This is the Header Advanced analytics",
  cardOneText: "This is lots and lots and lots of header text so that it fills a bunch of stuff up.",
  cardOneP1: "Synergistically pursue accurate initiatives without economically sound imperatives.",
  cardOneP2: "Professionally architect unique process improvements via optimal.",
  cardOneLI1: "List Item One",
  cardOneLI2: "List Item 2",
  cardOneLI3: "List Item 3",
  cardOneLI4: "List Item 4",
  cardOneLI5: "List Item 5",
  cardOneLink: "View Details",

  cardTwoIcon: "fal fa-file-chart-line icon-sm text-success",
  cardTwoHeader: "Automated Reports",
  cardTwoText: "Synergistically pursue accurate initiatives without economically imperatives.",
  cardTwoLink: "View Details",

  cardThreeIcon: "fal fa-user-friends icon-sm text-danger",
  cardThreeHeader: "Automated Reports",
  cardThreeText: "Synergistically pursue accurate initiatives without economically imperatives.",
  cardThreeLink: "View Details",

  cardFourIcon: "fal fa-spell-check icon-sm text-dark",
  cardFourHeader: "Automated Reports",
  cardFourText: "Synergistically pursue accurate initiatives without economically imperatives.",
  cardFourLink: "View Details",

  cardFiveIcon: "fal fa-cog icon-sm text-warning",
  cardFiveHeader: "Automated Reports",
  cardFiveText: "Synergistically pursue accurate initiatives without economically imperatives.",
  cardFiveLink: "View Details",

  linkText: "Find out more",
  image:"AI-document-process-removebg-prev.png"
}

let PromoThreeData = {
  title: "This is the Title",
  description: "This is the Description",
  amountOne: "150",
  headerOne:"Completed Deployments",
  textOne: "This is a great accomplishment that needs to be fully appreciated",
  amountTwo: "2k",
  headerTwo:"Happy Clients",
  textTwo: "Energistically impact synergistic convergence for leadership..",
  amountThree: "90%",
  headerThree:"Models Running",
  textThree: "This is a great accomplishment that needs to be fully appreciated"
}


let WorkProcessFourData ={
  header: "This is the Header",
  headerParagraph: "This is lots and lots and lots of header text so that it fills a bunch of stuff up.",
  stepOneIcon: "h2 mb-0 text-primary fw-bold",
  stepOne:"Listen",
  stepOneDescription:"Intrinsicly network transparent services.",
  stepTwo:"Learn",
  stepTwoDescription:"Intrinsicly network transparent services.",
  stepThree:"Build",
  stepThreeDescription:"Intrinsicly network transparent services.",
  stepFour:"Deploy",
  stepFourDescription:"Intrinsicly network transparent services.",
}

const GenerativeStartup  = () => {
  return (
    <Layout>
      <PageMeta title='Generative AI' />
      <Navbar navDark />

      {/*<Generative /> 
      <Customer gray />
      */}
      <Generative /> 
      <FeatureImgSix data={FeatureImgSixData}/>
      
      <FeatureImgTwo data={FeatureImgTwoData}/>
     
       {/*
        <FeatureImg data={FeatureImgData}/>
      <WorkProcess/>
      <FeatureImgThree data={FeatureImgThreeData}/>

      
 
      <FeatureImgSeven />
    
      <FeatureImgFour/>
      <FeatureImgFive />

      <FeatureImgEight data={FeatureImgEightData}/>
      <FeatureTwo cardDark data={FeatureTwoData}/>
      <WorkProcessFour  data={WorkProcessFourData} />
      
      <PromoThree data={ PromoThreeData}/>
     
      <IntegrationTwo />
      <TestimonialTwo bgWhite/>
      <CtaTwo />
    */}
      <FooterOne footerLight />
    </Layout>
  );
};

export default GenerativeStartup;
