import React from 'react';
import PageMeta from '../../components/common/PageMeta';

import Models from './Models';

import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';


import FeatureImgEight2 from '../../components/features/FeatureImgEight2';
import FeatureTwo from '../../components/features/FeatureTwo';

import FeatureImgSix from '../../components/features/FeatureImgSix';


import animationData from '../lotties/security-research1';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};


let FeatureImgSixData ={
  bulletOne: "Tailored to Specific Requirements",
  bulletOneText: "Customized AI models are designed with precision to address the unique needs and challenges of a particular business or application, ensuring relevance and accuracy.",
  bulletTwo: "Optimized Performance",
  bulletTwoText: "These models undergo meticulous fine-tuning to achieve optimal performance, maximizing efficiency in tasks such as data analysis, prediction, or recommendation within the defined scope.",
  bulletThree: "Adaptability and Scalability",
  bulletThreeText: "Customized AI models are built to adapt to evolving data patterns and business requirements, providing the flexibility to scale seamlessly as needs grow or change over time.",

  iconOne: "fas fa-tools text-white",
  iconTwo: "fas fa-sync text-white",
  iconThree: "fas fa-sitemap text-white",
  sectionTitle:"Customized AI Models",
  sectionDescription:"Tailored solutions designed to address your specific business needs, leveraging machine learning algorithms fine-tuned for a particular dataset or use case. These models offer a personalized approach, ensuring optimal performance and relevance by aligning with the unique requirements and intricacies of the task at hand.",
  imageOld:"feature-hero-img.svg",
  useImage: true,
  // /image: "ai-generated/data-stack-2.jpeg",
  //image: "BorrowedImages/AI-Landscape-ecosystem.png",
  image: "BorrowedImages/Foundations.png",

  defaultOptions: defaultOptions
}



let FeatureImgEightData = {
  sectionTitle:"Collaborative Experience",
  sectionDescription: "Success in developing custom AI models requires a deep understanding of the specific business context and challenges, as well as expertise in machine learning techniques. A collaborative approach, involving domain experts and data scientists, is essential to ensure the models are fine-tuned to perfection, delivering accurate and impactful solutions tailored to the unique requirements of the application.",
  iconOne:"fal fa-graduation-cap fa-2x text-primary",
  bulletOne:"Domain Expertise",
  bulletOneParagraph:"Successful development of custom AI models necessitates a profound understanding of the industry or business domain to accurately identify and address specific challenges and opportunities",
  iconTwo: "fal fa-users fa-2x text-primary",
  bulletTwo: "Collaborative Team Dynamics",
  bulletTwoParagraph: "Effective collaboration between domain experts and data scientists is crucial for leveraging both industry insights and technical expertise, ensuring the customization of AI models aligns seamlessly with the unique requirements of the application.",

  //image: "BorrowedImages/CollaborativeExperience.png"
  image: "BorrowedImages/AI-Team.png"

}


let FeatureTwoData = {
  sectionTitle:"Optimized Models and Modeling Infrastructure",
  sectionSubTitle:"Customizable AI to Grow your Business",
  sectionDescription:"The Certo modeling infrastructure encompasses a comprehensive ecosystem supporting an end-to-end machine learning lifecycle, incorporating advanced technologies and frameworks to streamline the entire process, from data preparation to model deployment. Together, optimized models and a robust modeling infrastructure empower organizations to achieve superior performance, adaptability, and value from their machine learning initiatives.",
  cardOneIcon: "fal fa-analytics icon-sm text-primary",
  cardOneHeader: "Generative",
  cardOneText: "Empowers machines to autonomously create novel content, ranging from realistic images and text to innovative solutions across various domains. By harnessing advanced algorithms, Generative AI exhibits a remarkable ability to synthesize information, fostering creativity and expanding the boundaries of what machines can achieve.",
  cardOneP1: "Empowers machines to autonomously create novel content, ranging from realistic images and text to innovative solutions across various domains. ",
  cardOneP2: "Generative AI exhibits a remarkable ability to synthesize information, fostering creativity and expanding the boundaries of what machines can achieve.",
  cardOneLI1: "Natural Language Processing",
  cardOneLI2: "Creative Content Synthesis",
  cardOneLI3: "Realistic Image Generation",
  cardOneLI4: "Anomaly Detection",
  cardOneLI5: "Personalized Content",
  cardOneLink: "generative",
  cardOneLinkText: "View Details",

  cardTwoIcon: "fal fa-file-chart-line icon-sm text-success",
  cardTwoHeader: "Predictive",
  cardTwoText: "Leverages historical data and statistical algorithms to forecast future outcomes, enabling organizations to make informed decisions and gain a strategic edge.",
  //cardTwoLink: "predictive",
  cardTwoLink: "predictive-analytics",
  cardTwoLinkText: "View Details",

  cardThreeIcon: "fal fa-user-friends icon-sm text-danger",
  cardThreeHeader: "Causal ML",
  cardThreeText: "Focuses on understanding and modeling cause-and-effect relationships within data, allowing for a deeper comprehension of the underlying mechanisms that influence outcomes.",
  //cardThreeLink: "causal",
  cardThreeLink: "counterfactual",
  cardThreeLinkText: "View Details",

  cardFourIcon: "fal fa-spell-check icon-sm text-dark",
  cardFourHeader: "Planning",
  cardFourText: "Developing strategic frameworks and algorithms that enable autonomous decision-making, guiding the sequence of actions an intelligent system should take to achieve specific goals.",
  //cardFourLink: "planning",
  cardFourLink: "decision-support",
  cardFourLinkText: "View Details",

  cardFiveIcon: "fal fa-cog icon-sm text-warning",
  cardFiveHeader: "What If?",
  cardFiveText: " Explores hypothetical scenarios by adjusting input variables, providing insights into how changes could impact outcomes and enhancing decision-making processes.",
  // /cardFiveLink: "what-if",
  cardFiveLink: "counterfactual",
  cardFiveLinkText: "View Details",

  linkText: "Find out more",
  image:"AI-document-process-removebg-prev.png"
}



const ModelsStartup  = () => {
  return (
    <Layout>
      <PageMeta title='Foundational Elements' />
      <Navbar navDark />
      
      <Models /> 
    
      <FeatureImgSix data={FeatureImgSixData}/>
      <FeatureImgEight2 data={FeatureImgEightData}/>
      <FeatureTwo data={FeatureTwoData} />
      <FooterOne footerLight />
    </Layout>
  );
};

export default ModelsStartup;
