import React from 'react';
import HeroTitle from '../../components/common/HeroTitle';

const Generative = () => {
  return (
    <>
      <section
        className='hero-section ptb-120 bg-dark text-white'
        style={{
          background:
            "url('assets/img/shape/dot-dot-wave-shape.svg')no-repeat bottom left",
        }}
      >
        <div className='container'>
          <div className='row align-items-center justify-content-lg-between'>
            <div className='col-xl-5 col-lg-5'>
              <div
                className='hero-content-wrap text-center text-xl-start text-lg-start mt-5 mt-lg-0 mt-xl-0'
                data-aos='fade-right'
              >
                <HeroTitle
                  title=' Generative AI'
                  desc='A subset of artificial intelligence that excels in producing original and contextually relevant content by learning patterns from vast datasets. Employing advanced algorithms, Certo has the ability to autonomously create diverse outputs, ranging from creative writing and art to realistic simulations, showcasing their versatility in generating novel and meaningful information.'
                />
                {/* <div className='hero-subscribe-form-wrap pt-4 position-relative m-auto m-xl-0 d-none d-md-block d-lg-block d-xl-block'>
                  <form
                    id='subscribe-form'
                    name='email-form'
                    className='hero-subscribe-form d-block d-lg-flex d-md-flex'
                  >
                    <input
                      type='submit'
                      value='Details'
                      data-wait='Please wait...'
                      className='btn btn-primary mt-3 mt-lg-0 mt-md-0'
                    />
                  </form>
                </div> */}
              </div>
            </div>
            <div className='col-xl-6 col-lg-6 mt-5'>
              <div
                className='hero-img-wrap position-relative'
                data-aos='fade-left'
              >
                <div className='hero-screen-wrap'>
                  <div className='mac-screen'>
                    <img
                      src='assets/img/ai-generated/generative-removebg-preview.png'
                      alt='hero '
                      className='position-relative img-fluid rounded-custom'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Generative;
