import React from 'react';
import SectionTitle from '../common/SectionTitle';


const FeatureImgEight = ({ paddingTop, data }) => {
  return (
    <>
      <section
        className={`feature-section ${
          paddingTop ? 'ptb-120' : 'pt-60 pb-120'
        } `}
      >
        <div className='container'>
          <div className='row align-items-lg-center justify-content-between'>
            <div className='col-lg-5 order-lg-2 mb-7 mb-lg-0'>
              <div className='mb-4' data-aos='fade-up'>
                <SectionTitle
                  title={data.sectionTitle}
                  description={data.sectionDescription}
                />
              </div>
              <ul
                className='list-unstyled d-flex flex-wrap list-two-col mt-5'
                data-aos='fade-up'
                data-aos-delay='50'
              >
                <li>
                  <span className='d-block mb-4'>
                    <i className={data.iconOne}></i>
                  </span>
                  <h3 className='h5'>{data.bulletOne}</h3>
                  <p>
                  {data.bulletOneParagraph}
                  </p>
                </li>
                <li>
                  <span className='d-block mb-4'>
                    <i className={data.iconTwo}></i>
                  </span>
                  <h3 className='h5'>{data.bulletTwo}</h3>
                  <p>{data.bulletTwoParagraph} </p>
                </li>
              </ul>
            </div>
            <div className='col-lg-6 order-lg-1'>
              <div className='pr-lg-4 mt-md-4 position-relative'>
              <img
                    src={`assets/img/${data.image}`}
                    alt=''
                    className='img-fluid rounded-custom shadow-sm'

                  />
                {/* <div
                  className='bg-light text-center rounded-custom overflow-hidden p-5 mx-lg-auto'
                  data-aos='fade-up'
                  data-aos-delay='50'
                ></div>
                */}
                  
                  {/* <div className='position-absolute bg-secondary-dark z--1 dot-mask dm-size-16 dm-wh-350 top--40 left--40 top-left'></div>
                  */}
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgEight;
