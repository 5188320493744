import React from 'react';
import SectionTitle from '../common/SectionTitle';
import CustomNodeFlow from '../flows/CustomNodeFlow';

const renderListOfItems = (items) => {
  return items.map(item =>  <li className='d-flex align-items-start mb-4'>
  <div className='icon-box bg-primary rounded me-4'>
    <i className={item.icon}></i>
  </div>
  <div className='icon-content'>
    <h3 className='h5'>{item.bullet}</h3>
    <p>
    {item.bulletText}
    </p>
  </div>
</li>)
}

const FeatureImgSixExtended = ({data}) => {

  return (
    <>
      <section className='feature-section-two ptb-120'>
        <div className='container'>
          <div className='row  justify-content-lg-between'>
            <div className='col-lg-6 col-md-12'>
              <div className='feature-content-wrap'>
                <SectionTitle
                  title={data.sectionTitle}
                  description={data.sectionDescription}
                  leftAlign
                />
                <ul className='list-unstyled mb-0'>
                  {renderListOfItems(data.items)}
                </ul>
              </div>
            </div>
            <div className='col-lg-6 col-md-7' style={{marginTop: 210}}>
              {/* <CustomNodeFlow/> */}
              <img src={`assets/img/BorrowedImages/ReactFlow.png`} alt='feature' className='img-fluid rounded-custom' />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgSixExtended;
