import React from 'react';
import PageMeta from '../../components/common/PageMeta';

import Planning from './Planning';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import WorkProcessExtended from '../../components/work-process/WorkProcessExtended';
import FeatureImgSix from '../../components/features/FeatureImgSix';


import animationData from '../lotties/security-research1';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};




let FeatureImgSixData ={
  bulletOne: "Strategic Needs Assessment",
  bulletOneText: "Begin with a comprehensive assessment of the enterprise's specific challenges, opportunities, and strategic objectives, ensuring a clear understanding of the business context that will guide the customization of AI models.",
  bulletTwo: "Collaborative Modeling Workshops",
  bulletTwoText: "Conduct collaborative workshops involving domain experts, data scientists, and key stakeholders to gather insights, define requirements, and outline the optimal architecture for the custom AI models, fostering a collective vision for success.",
  bulletThree: "Iterative Prototyping and Testing",
  bulletThreeText: "Implement an iterative development approach, creating prototypes that are continuously refined and tested. This iterative cycle allows for regular feedback loops, ensuring the evolving models align precisely with the enterprise's evolving needs and expectations.",

  iconOne: "fas fa-bezier-curve text-white",
  iconTwo: "fas fa-fingerprint text-white",
  iconThree: "fas fa-headset text-white",
  sectionTitle:"Crafting Tailored Solutions: Building Custom AI Models for Enterprise Excellence",
  sectionDescription:"Embarking on the journey of building custom AI models for enterprise clients involves a meticulous process, integrating business acumen with cutting-edge technology to deliver solutions that precisely meet the unique needs of each organization.",
  imageOld:"feature-hero-img.svg",
  useImage: true,
  //image: "ai-generated/data-stack-2-removebg-preview.png",
  image: "BorrowedImages/AI-Team.png",
  defaultOptions: defaultOptions
}

let FeatureImgTwoData ={
  sectionTitle:"AI Collaboration",
  sectionSubTitle:"Considered Methodology for Deploying AI Solutions",
  sectionDescription:"Throughout each stage, effective communication, collaboration, and a deep understanding of the client's industry and business landscape are fundamental to the success of a world-class AI consulting firm's process. The iterative nature of the process allows for adaptability and continuous improvement, ensuring that the AI solutions evolve with the changing needs of the client.",
  headerOne: "This is the Header",
  headerOneParagraph: "This is lots and lots and lots of header text so that it fills a bunch of stuff up.",
  headerTwo: "This is the Header Two",
  headerTwoParagraph: "Solving real world NLP problems successfully requires human intelligence, domain and process knowledge, and general knowledge.",
  iconOne: "fas fa-bezier-curve text-white",
  iconTwo: "fas fa-fingerprint text-white",
  linkText: "Find out more",
  imageOld:"dashboard-img.png",
  //image: "ai-generated/algorithms.jpeg"
  image: "BorrowedImages/CollaborativeExperience.png"
}


let workProcessData ={
  sectionTitle:"A Client-Centric Approach",
  sectionSubTitle:"Collaborative AI Model Development",
  sectionDescription:"Certo fosters a collaborative process with clients, involving iterative feedback and transparent communication, ensuring a customized and highly effective AI model that aligns precisely with the client's unique requirements and business objectives.",
  items: [
    {
      icon: "far fa-folder-tree fa-2x",
      step: "Step 1.",
      bullet: "Discovery and Assessment",
      bulletText: "Certo begins by understanding the client's business objectives, challenges, and opportunities. This phase involves close collaboration with key stakeholders to identify areas where AI can add significant value.",
    },
    {
      icon: "far fa-bezier-curve fa-2x",
      step: "Step 2.",
      bullet: "Data Assessment and Preparation",
      bulletText: "A thorough evaluation of existing data assets is conducted. This includes assessing data quality, availability, and relevance. Certo works closely with the client to prepare and structure data for AI applications.",
    },
    {
      icon: "far fa-list fa-2x",
      step: "Step 3.",
      bullet: "Problem Definition and Solution Design",
      bulletText: "Clear problem definitions are established, and the consulting team collaborates with domain experts to design AI solutions that align with business goals. This involves selecting appropriate algorithms, models, and methodologies.",
    },
    {
      icon: "far fa-gear fa-2x",
      step: "Step 4.",
      bullet: "Prototyping and Development",
      bulletText: "Prototypes or proof-of-concept models are developed to validate the feasibility and effectiveness of the proposed AI solutions. This iterative process allows for quick adjustments and optimizations based on initial results.",
    },
    {
      icon: "far fa-dumbbell fa-2x",
      step: "Step 5.",
      bullet: "Model Training and Validation",
      bulletText: "Using advanced machine learning techniques, Certo trains models on relevant datasets. Rigorous validation processes are employed to ensure the models are accurate, unbiased, and aligned with business objectives.",
    },
    {
      icon: "far fa-rocket fa-2x",
      step: "Step 6.",
      bullet: "Integration and Deployment",
      bulletText: "The AI models are integrated into the client's existing infrastructure, and deployment strategies are implemented. This phase also involves thorough testing to ensure seamless integration and minimal disruption to existing operations.",
    },
    {
      icon: "far fa-bell fa-2x",
      step: "Step 7.",
      bullet: "Monitoring and Optimization",
      bulletText: "Continuous monitoring of AI models in real-world scenarios is crucial. Certo establishes robust monitoring systems to detect performance issues or changes in data distribution. Ongoing optimization efforts ensure sustained effectiveness.",
    },
    {
      icon: "far fa-brain fa-2x",
      step: "Step 8.",
      bullet: "Knowledge Transfer and Training",
      bulletText: "Knowledge transfer is a key aspect of our process, with Certo providing training and support to the client's team. This empowers the client to understand, maintain, and potentially expand AI applications independently.",
    },
    {
      icon: "far fa-file fa-2x",
      step: "Step 9.",
      bullet: "Documentation and Reporting",
      bulletText: "Comprehensive documentation is provided to ensure transparency and facilitate future enhancements or modifications. Regular reporting mechanisms are established to update stakeholders on the performance and impact of AI solutions.",
    },
    {
      icon: "far fa-scale-balanced fa-2x",
      step: "Step 10.",
      bullet: "Compliance and Ethical Considerations",
      bulletText: "Certo places a strong emphasis on ethical AI practices. We ensure that AI solutions comply with legal and ethical standards, and that potential biases are identified and addressed.",
    },
    {
      icon: "far fa-file-medical fa-2x",
      step: "Step 11.",
      bullet: "Post-Implementation Support",
      bulletText: "Certo offers ongoing support to address any issues, implement updates, and provide strategic guidance for the continued success of AI applications.",
    }
  ],
  useImage: true,
  //image: "BorrowedImages/em-access-data-w1200-1.png"
  image: "BorrowedImages/OurProcess.png"
}

const PlannignStartup  = () => {
  return (
    <Layout>
      <PageMeta title='The Certo Process' />
      <Navbar navDark />
      
      <Planning /> 
      <WorkProcessExtended data = {workProcessData}/>
      <FeatureImgSix data={FeatureImgSixData}/>

      <FooterOne footerLight />
    </Layout>
  );
};

export default PlannignStartup;
