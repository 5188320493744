import React from 'react';
import { Link } from 'react-router-dom';

const Team = () => {
  return (
    <>
      <section id='our-team' className='team-section pt-40 pb-40'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-12'>
              <div className='section-heading text-center'>
                <h5 className='h6 text-primary'>Our Team</h5>
                <h2>The People Behind Certo</h2>
                <p>
                  The Certo platform is built and supported by a dedicated team committed to pushing the boundaries of innovation. Certo's mission is to revolutionize the way businesses harness the power of artificial intelligence. 
                  Our success is rooted in the unparalleled expertise and cohesion of our team. 
                  With over 15 years of working together, 
                  our seasoned professionals bring decades of direct experience across multiple disciplines, 
                  including Machine Learning, AI, Data Science, Statistics and distributed and cloud computing.
                </p>
              </div>
            </div>
          </div>
          {/* <div className='row'>
            <div className='col-lg-3 col-md-6'>
              <div className='team-single-wrap mb-5'>
                <div className='team-img rounded-custom'>
                  <img
                    src='assets/img/team/Colclough-Cartoon.png'
                    alt='team'
                    className='img-fluid position-relative'
                    width={600}
                    height={600}
                  />
                  <ul className='list-unstyled team-social-list d-flex flex-column mb-0'>
                    <li className='list-inline-item'>
                    <a href="https://www.linkedin.com/in/george-colclough-845a52/">
                        <i className='fab fa-linkedin-in'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <Link to='#'>
                        <i className='fab fa-twitter'></i>
                      </Link>
                    </li>
                    <li className='list-inline-item'>
                      <Link to='#'>
                        <i className='fab fa-github'></i>
                      </Link>
                    </li>
                    
                  </ul>
                </div>
                <div className='team-info mt-4 text-center'>
                  <h5 className='h6 mb-1'>George Colclough</h5>
                  <p className='text-muted small mb-0'>AI/ML Engineer</p>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='team-single-wrap mb-5'>
                <div className='team-img rounded-custom'>
                  <img
                    src='assets/img/team/HikawaOld-Cartoon.jpg'
                    alt='team'
                    className='img-fluid position-relative'
                    width={600}
                    height={600}
                  />
                  <ul className='list-unstyled team-social-list d-flex flex-column mb-0'>
                    <li className='list-inline-item'>
                    <a href="https://www.linkedin.com/in/hiro-hikawa-2aaa8b5/">
                     
                        <i className='fab fa-linkedin-in'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <Link to='#'>
                        <i className='fab fa-twitter'></i>
                      </Link>
                    </li>
                    <li className='list-inline-item'>
                      <Link to='#'>
                        <i className='fab fa-github'></i>
                      </Link>
                    </li>
                    
                  </ul>
                </div>
                <div className='team-info mt-4 text-center'>
                  <h5 className='h6 mb-1'>Hiro Hikawa</h5>
                  <p className='text-muted small mb-0'>AI/ML Engineer</p>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='team-single-wrap mb-5'>
                <div className='team-img rounded-custom'>
                  <img
                    src='assets/img/team/Sekerke-Cartoon.jpg'
                    alt='team'
                    className='img-fluid position-relative'
                    width={600}
                    height={600}
                  />
                  <ul className='list-unstyled team-social-list d-flex flex-column mb-0'>
                    <li className='list-inline-item'>
                    <a href="https://www.linkedin.com/in/matt-sekerke-a8a3021/">
                        <i className='fab fa-linkedin-in'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <Link to='#'>
                        <i className='fab fa-twitter'></i>
                      </Link>
                    </li>
                    <li className='list-inline-item'>
                      <Link to='#'>
                        <i className='fab fa-github'></i>
                      </Link>
                    </li>
                    
                  </ul>
                </div>
                <div className='team-info mt-4 text-center'>
                  <h5 className='h6 mb-1'>Matt Sekerke</h5>
                  <p className='text-muted small mb-0'>AI/ML Researcher</p>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='team-single-wrap mb-5'>
                <div className='team-img rounded-custom'>
                  <img
                    src='assets/img/team/Swanzey-No-background-Cartoon.jpg'
                    alt='team'
                    className='img-fluid position-relative'
                    width={600}
                    height={600}
                  />
                  <ul className='list-unstyled team-social-list d-flex flex-column mb-0'>
                    <li className='list-inline-item'>
                    <a href="https://www.linkedin.com/in/gene-swanzey-5499383/">
                        <i className='fab fa-linkedin-in'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <Link to='#'>
                        <i className='fab fa-twitter'></i>
                      </Link>
                    </li>
                    <li className='list-inline-item'>
                      <Link to='#'>
                        <i className='fab fa-github'></i>
                      </Link>
                    </li>
                    
                  </ul>
                </div>
                <div className='team-info mt-4 text-center'>
                  <h5 className='h6 mb-1'>Gene Swanzey</h5>
                  <p className='text-muted small mb-0'>Fintech Subject Matter Expert</p>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='team-single-wrap mb-5'>
                <div className='team-img rounded-custom'>
                  <img
                    src='assets/img/team/Yano-Cartoon.jpg'
                    alt='team'
                    className='img-fluid position-relative'
                    width={600}
                    height={600}
                  />
                  <ul className='list-unstyled team-social-list d-flex flex-column mb-0'>
                    <li className='list-inline-item'>
                    <a href="https://www.linkedin.com/in/will-yano-1865649/">
                        <i className='fab fa-linkedin-in'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <Link to='#'>
                        <i className='fab fa-twitter'></i>
                      </Link>
                    </li>
                    <li className='list-inline-item'>
                      <Link to='#'>
                        <i className='fab fa-github'></i>
                      </Link>
                    </li>
                    
                  </ul>
                </div>
                <div className='team-info mt-4 text-center'>
                  <h5 className='h6 mb-1'>Will Yano</h5>
                  <p className='text-muted small mb-0'>Data Engineer</p>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='team-single-wrap mb-5'>
                <div className='team-img rounded-custom'>
                  <img
                    src='assets/img/team/Davis-Cartoon.jpg'
                    alt='team'
                    className='img-fluid position-relative'
                    width={600}
                    height={600}
                  />
                  <ul className='list-unstyled team-social-list d-flex flex-column mb-0'>
                    <li className='list-inline-item'>
                    <a href="https://www.linkedin.com/in/brad-davis-1b70a08/">
                        <i className='fab fa-linkedin-in'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <Link to='#'>
                        <i className='fab fa-twitter'></i>
                      </Link>
                    </li>
                    <li className='list-inline-item'>
                      <Link to='#'>
                        <i className='fab fa-github'></i>
                      </Link>
                    </li>
                    
                  </ul>
                </div>
                <div className='team-info mt-4 text-center'>
                  <h5 className='h6 mb-1'>Brad Davis</h5>
                  <p className='text-muted small mb-0'>Capital Markets SME</p>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='team-single-wrap mb-5'>
                <div className='team-img rounded-custom'>
                  <img
                    src='assets/img/team/Serge-Actual-removebg-preview-modified-removebg-preview.png'
                    alt='team'
                    className='img-fluid position-relative'
                  />
                  <ul className='list-unstyled team-social-list d-flex flex-column mb-0'>
                    <li className='list-inline-item'>
                    <a href="https://www.linkedin.com/in/sergeruiz/">
                        <i className='fab fa-linkedin-in'></i>
                      </a>
                    </li>
                    <li className='list-inline-item'>
                      <Link to='#'>
                        <i className='fab fa-twitter'></i>
                      </Link>
                    </li>
                    <li className='list-inline-item'>
                      <Link to='#'>
                        <i className='fab fa-github'></i>
                      </Link>
                    </li>
                    
                  </ul>
                </div>
                <div className='team-info mt-4 text-center'>
                  <h5 className='h6 mb-1'>Serge Ruiz</h5>
                  <p className='text-muted small mb-0'>Full-stack Developer</p>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='team-single-wrap mb-5'>
                <div className='team-img rounded-custom'>
                  <img
                    src='assets/img/team/Sullivan-Cartoon.jpg'
                    alt='team'
                    className='img-fluid position-relative'
                  />
                  <ul className='list-unstyled team-social-list d-flex flex-column mb-0'>
                    <li className='list-inline-item'>
                      <Link to='#'>
                        <i className='fab fa-linkedin-in'></i>
                      </Link>
                    </li>
                    <li className='list-inline-item'>
                      <Link to='#'>
                        <i className='fab fa-twitter'></i>
                      </Link>
                    </li>
                    <li className='list-inline-item'>
                      <Link to='#'>
                        <i className='fab fa-github'></i>
                      </Link>
                    </li>
                    
                  </ul>
                </div>
                <div className='team-info mt-4 text-center'>
                  <h5 className='h6 mb-1'>John Sullivan</h5>
                  <p className='text-muted small mb-0'>MLOps and Infrastructure</p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default Team;
