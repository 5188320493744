import React from 'react';
import SectionTitle from '../common/SectionTitle';

const PromoThree = ({hasBg, data}) => {
  return (
    <>
      <section className={`promo-section ptb-120 ${hasBg?'bg-light':''}`}>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-md-10 col-lg-6'>
              <SectionTitle
                title={data.title}
                description={data.description}
                  centerAlign
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6 col-lg-4'>
              <div
                className='promo-card-wrap promo-border-hover border border-2 bg-white rounded-custom p-5 mb-4 mb-lg-0 mb-md-4'
                data-aos='fade-up'
                data-aos-delay='50'
              >
                <div className='promo-card-info'>
                  <h3 className='display-5 fw-medium mb-4'>{data.amountOne}</h3>
                  <h2 className='h5'>{data.headerOne}</h2>
                  <p className='mb-0'>
                  {data.textOne}.{' '}
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-4'>
              <div
                className='promo-card-wrap promo-border-hover border border-2 bg-white rounded-custom p-5 mb-4 mb-lg-0 mb-md-4'
                data-aos='fade-up'
                data-aos-delay='100'
              >
                <div className='promo-card-info'>
                  <h3 className='display-5 fw-medium mb-4'>{data.amountTwo}</h3>
                  <h2 className='h5'>{data.headerTwo}</h2>
                  <p className='mb-0'>
                  {data.textTwo}.{' '}
                  </p>
                </div>
              </div>
            </div>
            <div className='col-md-6 col-lg-4'>
              <div
                className='promo-card-wrap promo-border-hover border border-2 bg-white rounded-custom p-5 mb-4 mb-lg-0 mb-md-0'
                data-aos='fade-up'
                data-aos-delay='150'
              >
                <div className='promo-card-info'>
                  <h3 className='display-5 fw-medium mb-4'>{data.amountThree}</h3>
                  <h2 className='h5'>{data.headerThree}</h2>
                  <p className='mb-0'>
                  {data.textThree}.{' '}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PromoThree;
