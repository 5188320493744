import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import PageMeta from '../../components/common/PageMeta';

import RequestForDemo from '../../components/others/RequestForDemo';
import PromoThree from '../../components/promo/PromoThree';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import FeatureImgEight from '../../components/features/FeatureImgEight';
import FeatureImgContentThree from '../../components/feature-img-content/FeatureImgContentThree';
import FeatureImgContentTwo from '../../components/feature-img-content/FeatureImgContentTwo';


let PromoThreeData = {
  title: "This is the Title",
  description: "This is the Description",
  amountOne: "150",
  headerOne:"Completed Deployments",
  textOne: "This is a great accomplishment that needs to be fully appreciated",
  amountTwo: "2k",
  headerTwo:"Happy Clients",
  textTwo: "Energistically impact synergistic convergence for leadership..",
  amountThree: "90%",
  headerThree:"Models Running",
  textThree: "This is a great accomplishment that needs to be fully appreciated"
}

let FeatureImgContentThreeData = {
  header: "Catalyst for AI Success ",
  headerParagraph: "Data engineering stands as a pivotal cornerstone on the Certo AI platform, underscoring its importance in driving successful AI initiatives. Through meticulous data preparation and engineering processes, Certo ensures a robust foundation, accelerating the development and enhancing the performance of AI and machine learning models.",
  iconOne:"fal fa-comments-alt fa-2x text-primary mb-4",
  bulletOne:"Optimized Model Training",
  bulletOneParagraph:"Data engineering enables the Certo platform to optimizes the model training process. This approach contributes to superior model accuracy and efficiency.",
  iconTwo: "fal fa-headset fa-2x text-primary mb-4",
  bulletTwo: "Strategic Resource Utilization",
  bulletTwoParagraph: "Recognizing the resource-intensive nature of manual data labeling, Certo's emphasis on programmatic techniques accelerates the training process and strategically allocates resources",
  image: "BorrowedImages/Data-Centric-AI.png"
}

let FeatureImgContentTwoData = {
  sectionTitle:"Strategic Deployment of Data Engineering for AI Excellence",
  sectionDescription: "Efficient data engineering is seamlessly integrated into the deployment process on the Certo AI platform, laying the groundwork for impactful AI and machine learning solutions. The platform's approach involves a strategic combination of programmatic techniques, precision, and adaptability, ensuring optimal outcomes in the deployment of advanced models.",
  iconMain: "fal fa-shield-check fa-2x text-white",
  iconOne:"fad fa-bezier-curve fa-2x text-primary",
  bulletOne:"Real-time Feedback to other stuff",
  bulletOneParagraph:"An explainable and accurate way to flexibly combine large language models (LLMs), symbolic, and machine learning AI approaches that solve real-world problems with the highest degree of accuracy.",
  iconTwo: "fad fa-code fa-2x text-primary",
  bulletTwo: "Live Changes Stuff",
  bulletTwoParagraph: "Interactively integrate extensible directed communities.",
  image: "dashboard-img-4.png",
  items: [
    {
      icon:"fal fa fa-check-square fa-2x  text-primary mb-4",
      bullet: "Programmatic Data Labeling Techniques",
      bulletParagraph: "Certo employs programmatic techniques to automate the generation of labeled training data, expediting the data engineering process and ensuring that models are trained on high-quality datasets."
    },
    {
      icon:"fal fa fa-search-plus fa-2x  text-primary mb-4",
      bullet: "Precision in Data Preparation",
      bulletParagraph: "Through meticulous data preparation, Certo ensures that datasets are curated with precision, addressing specific business needs and challenges. This precision is essential for developing models that yield accurate and reliable results."
    },
    {
      icon:"fal fa fa-sync fa-2x  text-primary mb-4",
      bullet: "Adaptive Deployment Strategies",
      bulletParagraph: "Certo's data engineering extends beyond model training, encompassing adaptive deployment strategies. The platform's approach ensures that models are deployed efficiently, and ongoing monitoring and refinement processes are in place to adapt to changing data landscapes."
    }
  ]
}


let FeatureImgEightData = {
  sectionTitle:"Efficiency Amplified",
  sectionDescription: "Certo employs programmatic techniques to automatically generate labeled training data, streamlining the process of preparing datasets for AI and machine learning model training.",
  iconOne:"fal fa-user fa-2x text-primary",
  bulletOne:"Accelerated Training and Enhanced Performance",
  bulletOneParagraph:"By programmatically labeling data, Certo accelerates the training process, significantly reducing the time required for model development. This not only enhances efficiency but also contributes to improved model performance through the use of labeled training data.",
  iconTwo: "fal fa-watch fa-2x text-primary",
  bulletTwo: "Time and Cost Efficiency",
  bulletTwoParagraph: "The programmatic approach on the Certo AI platform proves particularly valuable in scenarios where manual labeling of large datasets is time-consuming or costly, enabling a more efficient and economical data engineering process.",
  image: "BorrowedImages/Model-Centric-AI.png"
}


const DataPrograming  = () => {
  return (
    <Layout>
      <PageMeta title="Data Engineering" />
      <Navbar navDark />
      <PageHeader
        title="Data Engineering"
        desc="Certo uses programmatic techniques to generate labeled training data for use in its AI and machine learning models. This often involves creating programs to automatically generate labeled training data, which is crucial for training supervised machine learning models. This approach is particularly valuable when manual labeling of large datasets is time-consuming or expensive. By programmatically labeling data, Certo is able to accelerate the training process and improve model performance."
      />
      <FeatureImgEight paddingTop data={FeatureImgEightData}/>
      <FeatureImgContentThree data= {FeatureImgContentThreeData}/>
      <FeatureImgContentTwo data= {FeatureImgContentTwoData}/>
      {/*
      <PromoThree hasBg data={PromoThreeData}/>
      <RequestForDemo />
  */}
      <FooterOne footerLight />
    </Layout>
  );
};

export default DataPrograming ;
