import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import PageMeta from '../../components/common/PageMeta';

import RequestForDemo from '../../components/others/RequestForDemo';
import PromoThree from '../../components/promo/PromoThree';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

import FeatureImgContentTwo from '../../components/feature-img-content/FeatureImgContentTwo';
import UseCaseFeatureImgEight from './UseCaseFeatureImgEight';


let PromoThreeData = {
  title: "This is the Title",
  description: "This is the Description",
  amountOne: "150",
  headerOne:"Completed Deployments",
  textOne: "This is a great accomplishment that needs to be fully appreciated",
  amountTwo: "2k",
  headerTwo:"Happy Clients",
  textTwo: "Energistically impact synergistic convergence for leadership..",
  amountThree: "90%",
  headerThree:"Models Running",
  textThree: "This is a great accomplishment that needs to be fully appreciated"
}

let dataEight = {
  header: "Predictive Analytics",
  tagLine:"Predictive Analytics",
  image1: "BorrowedImages/CausalEditing.mp4",
  image: "BorrowedImages/CausalEditing.mp4",
  image2: "BorrowedImages/CausalEditing.mp4",

  majorIngredients: "GAM, Garch, VAR, VECM ",
  minorIngredients: "CatBoost, Conformal Prediction",

  headerText:  "Drive interpretable, real-time analysis of customer-level characteristics.  Deployed at scale on billions of underlying customer data points.",
  
  iconLeft: "fal fa-chart-line fa-2x text-primary",
  iconRight: "fal fa-watch fa-2x text-primary",
  leftSubHeader:"What-if",
  leftSubText:"luyjw5eaery Objectively exceptional via customized intellectual.",
  rightSubHeader:"Real time updates",
  rightSubText:"Real-time transcription",
  linkText:"Empowering Decisions: The Role of AI in Advanced Decision Support",
  linkDescription: "AI systems can identify patterns, trends, and correlations that humans might overlook. These insights empower decision-makers to make more informed and effective choices across diverse domains, ranging from healthcare and finance to business operations and customer service. With AI-enabled decision support, organizations can optimize strategies, mitigate risks, and capitalize on opportunities in an increasingly complex and dynamic environment. They want to know their opinions are valued and thier options validated. Certo's models are capable of teasing signal from noise in ways that elevate your cutomer intelligence enabling new modes of engagement and opportunities for revenue capture.",

}



let FeatureImgContentTwoData = {
  sectionTitle:"Anticipate Tomorrow, Optimize Today – Transforming Enterprises with Predictive Analytics",
  sectionDescription: "Certo empowers enterprises to navigate the future with confidence, leveraging predictive analytics to make informed decisions, mitigate risks, and stay ahead in today's dynamic business landscape.",
  iconMain: "fal fa-chart-line fa-2x text-white",

  image: "BorrowedImages/Loan Analytics.png",
  items: [
    {
      icon:"fal fa fa-building fa-2x  text-primary mb-4",
      bullet: "Forecast Trends",
      bulletParagraph: "Anticipate market trends and customer behaviors by analyzing historical data patterns, enabling proactive business strategies."
    },
    {
      icon:"fal fa fa-code fa-2x  text-primary mb-4",
      bullet: "Optimize Resource Allocation",
      bulletParagraph: " Certo assists in efficient resource management by predicting demand fluctuations, ensuring optimal allocation of resources, and reducing unnecessary costs."
    },
    {
      icon:"fal fa fa-thermometer-quarter fa-2x  text-primary mb-4",
      bullet: "Increase Sales Effectiveness",
      bulletParagraph: "Certo aids in sales forecasting, helping enterprises predict sales trends and adjust strategies to meet or exceed targets."
    }
  ]
}




const PredictiveAnalyticsUseCase  = () => {
  return (
    <Layout>
      <PageMeta title="Predictive Analytics Use Case" />
      <Navbar navDark />
      <PageHeader
        title="Predictive Analytics"
        desc="Certo's advanced AI platform excels in solving predictive analytics challenges for enterprises by harnessing the power of sophisticated algorithms and data-driven insights. With its robust predictive analytics capabilities, Certo enables businesses to anticipate future trends, identify potential risks, and optimize decision-making processes. By analyzing historical data, Certo uncovers patterns and correlations that human analysis might overlook, providing a more accurate basis for forecasting."
      />

      <UseCaseFeatureImgEight data={dataEight} paddingTop={"paddingTop"} />
      <FeatureImgContentTwo data= {FeatureImgContentTwoData}/>

      <RequestForDemo />
      <FooterOne footerLight />
    </Layout>
  );
};

export default PredictiveAnalyticsUseCase ;
