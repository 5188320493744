import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import PageMeta from '../../components/common/PageMeta';

import RequestForDemo from '../../components/others/RequestForDemo';
import PromoThree from '../../components/promo/PromoThree';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

import FeatureImgContentTwo from '../../components/feature-img-content/FeatureImgContentTwo';
import UseCaseFeatureImgEight from './UseCaseFeatureImgEight';




let FeatureImgContentTwoData = {
  sectionTitle:"Supporting the Full Document Lifecycle",
  sectionDescription: "AI revolutionizes the process of information extraction from documents, enabling organizations to unlock valuable insights and navigate vast textual sources with unprecedented efficiency and accuracy.",
  iconMain: "fal fa-file-text  fa-2x text-white",
  useViedo: false,
  //image: "BorrowedImages/CausalEditing.mp4",
  //image: "BorrowedImages/automate-pdf-forms-processing.png",
  //image: "BorrowedImages/Document-Intelligence-Graphic.png",
  image: "BorrowedImages/Doc Info Extraction.svg",

  //image: "BorrowedImages/DocumentIntelligence2.png",

  
  items: [
    {
      icon:"fal fa fa-building fa-2x  text-primary mb-4",
      bullet: "Natural Language Processing (NLP) ",
      bulletParagraph: "Certo utilizes sophisticated NLP techniques to comprehend and interpret the nuances of language within documents, enhancing the extraction of meaningful information."
    },
    {
      icon:"fal fa fa-code fa-2x  text-primary mb-4",
      bullet: "Pattern Recognition for Contextual Understanding",
      bulletParagraph: "Certo's advanced algorithms facilitate pattern recognition, allowing the system to understand and contextualize information within documents, improving the accuracy of extraction."
    },
    {
      icon:"fal fa fa-thermometer-quarter fa-2x  text-primary mb-4",
      bullet: "Entity Recognition and Relationship Mapping",
      bulletParagraph: "Certo excels in recognizing entities and mapping relationships within documents, providing a deeper understanding of the content and enabling comprehensive information extraction."
    }
  ]
}

let dataEight = {
  header: "Document Infrormation Extraction",
  tagLine:"Document Analysis",
  image: "DocumentIntelligence2.png",
  image2: "BorrowedImages/CausalEditing.mp4",

  majorIngredients: "NER, NLP, Generative",
  minorIngredients: "classification, conformal prediction",
  useImage: true,
  headerText:  "Drive interpretable, real-time analysis of loan-level characteristics.  Deployed at scale on billions of underlying loan data points.",
  iconLeft: "fal fa-user fa-2x text-primary",
  iconRight: "fal fa-watch fa-2x text-primary",
  leftSubHeader:"What-if",
  leftSubText:"luyjw5eaery Objectively exceptional via customized intellectual.",
  rightSubHeader:"Real time updates",
  rightSubText:"Real-time transcription",
  linkText:"What exactly are you looking for?",
  linkDescription: "Information extraction is far more than identifying and labeling entities in a block of text.  There are ambiguities, references, implication and more.  Certo is trained to identify and utilize these and more. ",
}



let PromoThreeData = {
  title: "Immediate Gains in Productivity",
  description: "This is the Description",
  amountOne: "10X",
  headerOne:"Speed-up in ",
  textOne: "This is a great accomplishment that needs to be fully appreciated",
  amountTwo: "90%+",
  headerTwo:"Accuracy",
  textTwo: "Energistically impact synergistic convergence for leadership..",
  amountThree: "50-90%",
  headerThree:"Reduction in Burnout",
  textThree: "This is a great accomplishment that needs to be fully appreciated"
}


const DocumentsUseCase  = () => {
  return (
    <Layout>
      <PageMeta title="Information Extraction Use Case" />
      <Navbar navDark />
      <PageHeader
        title="Document Intelligence"
        desc="Certo deploys advanced algorithms to analyze and interpret the content within a variety of textual sources. Certo employs techniques such as natural language processing (NLP) and machine learning to recognize patterns, entities, and relationships within the documents. By automating this extraction, Certo enhances efficiency, accuracy, and scalability, allowing organizations to swiftly gather valuable insights from a vast array of documents, including contracts, reports, and other unstructured data sources."
      />

      <UseCaseFeatureImgEight data={dataEight} paddingTop={"paddingTop"} />
      <FeatureImgContentTwo data= {FeatureImgContentTwoData}/>
     {/*
      
      <PromoThree hasBg data={PromoThreeData}/>
      <RequestForDemo />
  */}
      <FooterOne footerLight />
    </Layout>
  );
};

export default DocumentsUseCase ;
