import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import PageHeader from '../../components/common/PageHeader';
import Causal from './Causal';
import TestimonialTwo from '../../components/testimonial/TestimonialTwo';
import CtaTwo from '../../components/cta/CtaTwo';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';

import FeatureImgThree from '../../components/features/FeatureImgThree';

import WorkProcessFour from '../../components/work-process/WorkProcessFour';
import PromoThree from '../../components/promo/PromoThree';
import FeatureImgEight from '../../components/features/FeatureImgEight';
import FeatureTwo from '../../components/features/FeatureTwo';


import FeatureImg from '../../components/features/FeatureImg';
import FeatureImgSix from '../../components/features/FeatureImgSix';
import FeatureImgSeven from '../../components/features/FeatureImgSeven';
import FeatureImgTwo from '../../components/features/FeatureImgTwo';
import FeatureImgFive from '../../components/features/FeatureImgFive';
import FeatureImgFour from '../../components/features/FeatureImgFour';


import animationData from '../lotties/security-research1';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

let FeatureImgData ={
  header: "This is the Header",
  headerParagraphOne: "This is lots and lots and lots of header text so that it fills a bunch of stuff up.",
  headerParagraphTwo: "Solving real world NLP problems successfully requires human intelligence, domain and process knowledge, and general knowledge.",
  iconOne: "fal fa-fingerprint fa-2x text-white",
  linkText: "Find out more",
  image:"document-info-extraction.png"
}

let FeatureImgTwoData ={
  sectionTitle:"Advanced AI Features",
  sectionSubTitle:"Actionable AI to Grow your Business",
  sectionDescription:"Intrinsicly network transparent services whereas B2C ROI. Globally create installed base quality vectors after innovative.",
  headerOne: "This is the Header",
  headerOneParagraph: "This is lots and lots and lots of header text so that it fills a bunch of stuff up.",
  headerTwo: "This is the Header Two",
  headerTwoParagraph: "Solving real world NLP problems successfully requires human intelligence, domain and process knowledge, and general knowledge.",
  iconOne: "fas fa-bezier-curve text-white",
  iconTwo: "fas fa-fingerprint text-white",
  linkText: "Find out more",
  imageOld:"dashboard-img.png",
  image: "ai-generated/algorithms.jpeg"
}


let FeatureImgThreeData ={
  sectionTitle:"Advanced AI Features",
  sectionSubTitle:"Actionable AI to Grow your Business",
  sectionDescription:"Intrinsicly network transparent services whereas B2C ROI. Globally create installed base quality vectors after innovative.",
  headerOne: "This is the Header",
  headerOneParagraph: "This is lots and lots and lots of header text so that it fills a bunch of stuff up.",
  headerTwo: "This is the Header Two",
  headerTwoParagraph: "Solving real world NLP problems successfully requires human intelligence, domain and process knowledge, and general knowledge.",
  iconOne: "fas fa-bezier-curve text-white",
  iconTwo: "fas fa-fingerprint text-white",
  listIconeOne: "fas fa-check bg-primary text-white rounded-circle",
  listIconOneText: "Fully ISO-2000 Compatable",
  listIconeTwo: "fas fa-check bg-primary text-white rounded-circle",
  listIconTwoText: "SOC II Completed",
  listIconeThree: "fas fa-check bg-primary text-white rounded-circle",
  listIconThreeText: "Bank Grade Security",

  linkText: "Find out more",
  image:"blue-color-waves-particle-and-geometric-abstract-background-design-vector-illustration-2888737634.jpg"
}

let FeatureImgSixData ={
  bulletOne: "This is a header",
  bulletOneText: "This is lots and lots and lots of header text so that it fills a bunch of stuff up.",
  bulletTwo: "This is a header",
  bulletTwoText: "Dramatically administrate progressive metrics withouterror-free globally simplify standardized plagiarize  sound.",
  bulletThree: "This is a header",
  bulletThreeText: "Dramatically administrate progressive metrics withouterror-free globally simplify standardized plagiarize  sound.",

  iconOne: "fas fa-bezier-curve text-white",
  iconTwo: "fas fa-fingerprint text-white",
  iconThree: "fas fa-headset text-white",
  sectionTitle:"We are experts at AI",
  sectionDescription:"Solving real world NLP problems successfully requires human intelligence, domain and process knowledge, and general knowledge.",
  imageOld:"feature-hero-img.svg",
  image: "ai-generated/data-stack-2.jpeg",
  defaultOptions: defaultOptions
}

let FeatureImgEightData = {
  sectionTitle:"Success Through Experience",
  sectionDescription: "Solving complex AI/ML problems is difficult without in the trenches experience. Our team has years of real-world experience solving enterprise level problems using the Certo Infrastructure. With end-to-end workflows covering intelligent document processing, data engineering, data extraction, model training, testing and orchestration capabilities all the way to production API endpoint deployment, we provide teams with:",
  iconOne:"fal fa-user fa-2x text-primary",
  bulletOne:"Customer analysis",
  bulletOneParagraph:"Objectively exceptional via customized capital expertise.",
  iconTwo: "fal fa-watch fa-2x text-primary",
  bulletTwo: "Real time metrics Stuff",
  bulletTwoParagraph: "Interactively integrate extensible users resource..",
  image: "dashboard-img-4.png"
}


let FeatureTwoData = {
  sectionTitle:"Advanced AI Features",
  sectionSubTitle:"Actionable AI to Grow your Business",
  sectionDescription:"Intrinsicly network transparent services whereas B2C ROI. Globally create installed base quality vectors after innovative.",
  cardOneIcon: "fal fa-analytics icon-sm text-primary",
  cardOneHeader: "This is the Header Advanced analytics",
  cardOneText: "This is lots and lots and lots of header text so that it fills a bunch of stuff up.",
  cardOneP1: "Synergistically pursue accurate initiatives without economically sound imperatives.",
  cardOneP2: "Professionally architect unique process improvements via optimal.",
  cardOneLI1: "List Item One",
  cardOneLI2: "List Item 2",
  cardOneLI3: "List Item 3",
  cardOneLI4: "List Item 4",
  cardOneLI5: "List Item 5",
  cardOneLink: "View Details",

  cardTwoIcon: "fal fa-file-chart-line icon-sm text-success",
  cardTwoHeader: "Automated Reports",
  cardTwoText: "Synergistically pursue accurate initiatives without economically imperatives.",
  cardTwoLink: "View Details",

  cardThreeIcon: "fal fa-user-friends icon-sm text-danger",
  cardThreeHeader: "Automated Reports",
  cardThreeText: "Synergistically pursue accurate initiatives without economically imperatives.",
  cardThreeLink: "View Details",

  cardFourIcon: "fal fa-spell-check icon-sm text-dark",
  cardFourHeader: "Automated Reports",
  cardFourText: "Synergistically pursue accurate initiatives without economically imperatives.",
  cardFourLink: "View Details",

  cardFiveIcon: "fal fa-cog icon-sm text-warning",
  cardFiveHeader: "Automated Reports",
  cardFiveText: "Synergistically pursue accurate initiatives without economically imperatives.",
  cardFiveLink: "View Details",

  linkText: "Find out more",
  image:"AI-document-process-removebg-prev.png"
}

let PromoThreeData = {
  title: "This is the Title",
  description: "This is the Description",
  amountOne: "150",
  headerOne:"Completed Deployments",
  textOne: "This is a great accomplishment that needs to be fully appreciated",
  amountTwo: "2k",
  headerTwo:"Happy Clients",
  textTwo: "Energistically impact synergistic convergence for leadership..",
  amountThree: "90%",
  headerThree:"Models Running",
  textThree: "This is a great accomplishment that needs to be fully appreciated"
}


let WorkProcessFourData ={
  header: "This is the Header",
  headerParagraph: "This is lots and lots and lots of header text so that it fills a bunch of stuff up.",
  stepOneIcon: "h2 mb-0 text-primary fw-bold",
  stepOne:"Listen",
  stepOneDescription:"Intrinsicly network transparent services.",
  stepTwo:"Learn",
  stepTwoDescription:"Intrinsicly network transparent services.",
  stepThree:"Build",
  stepThreeDescription:"Intrinsicly network transparent services.",
  stepFour:"Deploy",
  stepFourDescription:"Intrinsicly network transparent services.",
}

const CausalStartup  = () => {
  return (
    <Layout>
      <PageMeta title='Generative AI' />
      <Navbar navDark />
      <Causal /> 
      <FeatureImgSix data={FeatureImgSixData}/>
      <FeatureImgThree data={FeatureImgThreeData}/>

      <FeatureImg data={FeatureImgData}/>
 
      <FeatureImgSeven />
      <FeatureImgTwo data={FeatureImgTwoData}/>
      <FeatureImgFour/>
      <FeatureImgFive />

      <FeatureImgEight data={FeatureImgEightData}/>
      <FeatureTwo cardDark data={FeatureTwoData}/>
      <WorkProcessFour  data={WorkProcessFourData} />
      
      <PromoThree data={ PromoThreeData}/>

      <FooterOne footerLight />
    </Layout>
  );
};

export default CausalStartup;
