import React from 'react';

const FeatureImgContentThree = ({data}) => {
  return (
    <>
      <section className='feature-section pt-60 pb-120'>
        <div className='container'>
          <div className='row align-items-lg-center justify-content-between'>
            <div className='col-lg-5 order-lg-1 mb-7 mb-lg-0'>
              <div className='mb-4'>
                <h2>{data.header}</h2>
                <p>
                {data.headerParagraph}.{' '}
                </p>
              </div>
              <ul className='list-unstyled d-flex flex-wrap list-two-col mt-5'>
                <li>
                  <span>
                    <i className={data.iconOne}></i>
                  </span>
                  <h3 className='h5'>{data.bulletOne}</h3>
                  <p>{data.bulletOneParagraph}</p>
                </li>
                <li>
                  <span>
                    <i className={data.iconTwo}></i>
                  </span>
                  <h3 className='h5'>{data.bulletTwo}</h3>
                  <p>
                  {data.bulletTwoParagraph}.{' '}
                  </p>
                </li>
              </ul>
            </div>
            <div className='col-lg-6 order-lg-2'>
              <div className='pr-lg-4 position-relative'>
                
                  <img
                    src={`assets/img/${data.image}`}
                    alt=''
                    className='img-fluid rounded-custom shadow-sm'
                  />

                
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgContentThree;
