import React from 'react';
import { Link } from 'react-router-dom';
const FeatureImgContentFour = ({data}) => {
  return (
    <>
      <section className='why-choose-us ptb-120'>
        <div className='container'>
          <div className='row justify-content-lg-between align-items-center'>
            <div className='col-lg-5 col-12'>
              <div className='why-choose-content'>
                <div className='icon-box rounded-custom bg-primary shadow-sm d-inline-block'>
                  <i className='fal fa-shield-check fa-2x text-white'></i>
                </div>
                <h2>{data.header}</h2>
                <p>
                  {data.headerVerbose}
                </p>
                
                
                <ul className='list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4'>
                  <li className='py-1'>
                    <i className={data.iconOne}></i>
                    {data.bulletOne}
                  </li>
                  <li className='py-1'>
                    <i className={data.iconOne}></i>
                    {data.bulletTwo}
                  </li>
                  <li className='py-1'>
                    <i className={data.iconOne}></i>
                    {data.bulletThree}
                  </li>
                  <li className='py-1'>
                    <i className={data.iconOne}></i>
                    {data.bulletFour}
                  </li>
                  <li className='py-1'>
                    <i className={data.iconOne}></i>
                    {data.bulletFive}
                  </li>
                  <li className='py-1'>
                    <i className={data.iconOne}></i>
                    {data.bulletSix}
                  </li>
                </ul>
                <Link
                  to='/about-us'
                  className='read-more-link text-decoration-none'
                >
                  Know More About Us <i className='far fa-arrow-right ms-2'></i>
                </Link>
              </div>
            </div>
            <div className='col-lg-6 col-12'>
              <div className='feature-img-holder mt-4 mt-lg-0 mt-xl-0'>
                <img
                  src={`assets/img/screen/${data.image}`}
                  className='img-fluid'
                  alt='feature-img'
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgContentFour;
