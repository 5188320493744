import React from 'react';
import PageHeader from '../../components/common/PageHeader';
import PageMeta from '../../components/common/PageMeta';

import RequestForDemo from '../../components/others/RequestForDemo';
import PromoThree from '../../components/promo/PromoThree';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import FeatureImgEight from '../../components/features/FeatureImgEight';
import FeatureImgContentThree from '../../components/feature-img-content/FeatureImgContentThree';
import FeatureImgContentTwo from '../../components/feature-img-content/FeatureImgContentTwo';


let PromoThreeData = {
  title: "This is the Title",
  description: "This is the Description",
  amountOne: "150",
  headerOne:"Completed Deployments",
  textOne: "This is a great accomplishment that needs to be fully appreciated",
  amountTwo: "2k",
  headerTwo:"Happy Clients",
  textTwo: "Energistically impact synergistic convergence for leadership..",
  amountThree: "90%",
  headerThree:"Models Running",
  textThree: "This is a great accomplishment that needs to be fully appreciated"
}

let FeatureImgContentThreeData = {
  header: "Maximizing Efficiency",
  headerParagraph: "Harnessing the Power of AI in Process Automation",
  iconOne:"fal fa-comments-alt fa-2x text-primary mb-4",
  bulletOne:"Streamlining Operations",
  bulletOneParagraph:"AI-driven process automation optimizes workflows, reduces manual tasks, and enhances productivity.",
  iconTwo: "fal fa-wand-magic-sparkles fa-2x text-primary mb-4",
  bulletTwo: "Predictive Insights",
  bulletTwoParagraph: "AI algorithms analyze data to provide predictive insights, enabling proactive decision-making and improved resource allocation",
  //image: "BorrowedImages/overview-ml-and-analytics.jpg"
  image: "BorrowedImages/ProcessAutomation2.png"
}

let FeatureImgContentTwoData = {
  sectionTitle:"Success Through Experience",
  sectionDescription: "Solving complex AI/ML problems is difficult without in the trenches experience. Our team has years of real-world experience solving enterprise level problems using the Certo Infrastructure. With end-to-end workflows covering intelligent document processing, data engineering, data extraction, model training, testing and orchestration capabilities all the way to production API endpoint deployment, we provide teams with:",
  iconMain: "fal fa-shield-check fa-2x text-white",
  iconOne:"fad fa-bezier-curve fa-2x text-primary",
  bulletOne:"Real-time Feedback to other stuff",
  bulletOneParagraph:"An explainable and accurate way to flexibly combine large language models (LLMs), symbolic, and machine learning AI approaches that solve real-world problems with the highest degree of accuracy.",
  iconTwo: "fad fa-code fa-2x text-primary",
  bulletTwo: "Live Changes Stuff",
  bulletTwoParagraph: "Interactively integrate extensible directed communities.",
  image: "dashboard-img-4.png",
  items: [
    {
      icon:"fal fa fa-building fa-2x  text-primary mb-4",
      bullet: "Purpose Built",
      bulletParagraph: "Meticulously designed and tailored to address the specific needs of your domain."
    },
    {
      icon:"fal fa fa-code fa-2x  text-primary mb-4",
      bullet: "Optimized Performance",
      bulletParagraph: "Integrating high-performance computing, efficient data storage, and streamlined workflows, ensuring peak performance and scalability for your AI and machine learning tasks."
    },
    {
      icon:"fal fa fa-thermometer-quarter fa-2x  text-primary mb-4",
      bullet: "Measurable Results",
      bulletParagraph: "Through improved accuracy, faster processing times, enhanced scalability, and ultimately, the ability to derive actionable insights that contribute to business success."
    }
  ]
}


let FeatureImgEightData = {
  sectionTitle:"Success Through Experience",
  sectionDescription: "Solving complex AI/ML problems is difficult without in the trenches experience. Our team has years of real-world experience solving enterprise level problems using the Certo Infrastructure. With end-to-end workflows covering intelligent document processing, data engineering, data extraction, model training, testing and orchestration capabilities all the way to production API endpoint deployment, we provide teams with:",
  iconOne:"fal fa-user fa-2x text-primary",
  bulletOne:"Customer analysis",
  bulletOneParagraph:"Holistically analyze customer's process workflow",
  iconTwo: "fal fa-watch fa-2x text-primary",
  bulletTwo: "Real time performance",
  bulletTwoParagraph: "Closely monitor performance in real time",
  // /image: "BorrowedImages/RAG.webp"
  image: "BorrowedImages/ProcessAutomation.png"
}


const ProcessAutomationUseCase  = () => {
  return (
    <Layout>
      <PageMeta title="Process Automation Use Case" />
      <Navbar navDark />
      <PageHeader
        title="Process Automation"
        desc="Certo's AI plays a pivotal role in process automation by employing advanced algorithms and machine learning to streamline and enhance various operational workflows. From routine, rule-based tasks to complex decision-making processes, AI in process automation optimizes efficiency, reduces manual labor, and minimizes errors. Organizations leverage this technology to automate repetitive business processes, enhance productivity, and allocate human resources to more strategic and value-added activities, ultimately fostering agility and innovation within their operations."
      />
      <FeatureImgEight paddingTop data={FeatureImgEightData}/>
      <FeatureImgContentThree data= {FeatureImgContentThreeData}/>

      <RequestForDemo />
      <FooterOne footerLight />
    </Layout>
  );
};

export default ProcessAutomationUseCase ;