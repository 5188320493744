import React from 'react';
import { Link } from 'react-router-dom';

import SectionTitle from '../common/SectionTitle';

const FeatureImgContent = () => {
  return (
    <>
      <section className='why-choose-us pt-60 pb-120'>
        <div className='container'>
          <div className='row justify-content-lg-between align-items-center'>
            <div className='col-lg-6 col-12'>
              <div className='feature-img-holder my-4 my-lg-0 my-xl-0'>
                <img
                //https://ai-infrastructure.org/why-we-started-the-aiia-and-what-it-means-for-the-rapid-evolution-of-the-canonical-stack-of-machine-learning/
                  src='assets/img/BorrowedImages/Certo_ai Offerings.svg'
                  className='img-fluid'
                  alt='feature'
                />
              </div>
            </div>
            <div className='col-lg-5 col-12'>
              <div className='why-choose-content'>
                <div className='icon-box rounded-custom bg-primary shadow-sm d-inline-block'>
                  <i className='fal fa-shield-check fa-2x text-white'></i>
                </div>
                <SectionTitle
                  title='Cutting-edge Infrastructure'
                  description='The Certo platform is architected from first principles to scale AI and ML models from data curation, to model developmenet through deployment and monitoring.'
                />
                <ul className='list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4'>
                  <li className='py-1'>
                    <i className='fas fa-check-circle me-2 text-primary'></i>
                    Thought leadership
                  </li>
                  <li className='py-1'>
                    <i className='fas fa-check-circle me-2 text-primary'></i>
                    Bespoke Models
                  </li>
                  <li className='py-1'>
                    <i className='fas fa-check-circle me-2 text-primary'></i>
                    Curated Data
                  </li>
                  <li className='py-1'>
                    <i className='fas fa-check-circle me-2 text-primary'></i>
                    Scale
                  </li>
                  <li className='py-1'>
                    <i className='fas fa-check-circle me-2 text-primary'></i>
                    Built-in Privacy
                  </li>
                  <li className='py-1'>
                    <i className='fas fa-check-circle me-2 text-primary'></i>
                    Focus on Governance
                  </li>
                </ul>
                <Link
                  to='/about-us'
                  className='read-more-link text-decoration-none'
                >
                  Know More About Us <i className='far fa-arrow-right ms-2'></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureImgContent;
